import Footer from '@components/Footer/Footer';
import Navbar from '@components/Navbar/Navbar';
import changelog from '@consts/changelog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import commonStyles from '@styles/common.module.css';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import { getTheme } from '../../Theme.js';
import styles from './ChangelogPage.module.css';

const ChangelogPage = () => {
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const theme = getTheme(isDarkMode);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>Changelog | EdenInc</title>
      </Helmet>
      <Box className={commonStyles.pageLayout}>
        <Navbar />
        <Box className={styles.pageContainer}>
          <Box className={styles.pageTitleContainer}>
            <Typography variant="h2" sx={{ mb: '1rem' }}>
              Changelog
            </Typography>
          </Box>
          <Box className={styles.pageContentContainer}>
            {changelog.map((item, index) => (
              <Accordion
                key={item.version}
                defaultExpanded={index === 0}
                className={styles.accordion}
                sx={{
                  backgroundColor: theme.palette.background.default,
                  transition: isMounted
                    ? 'background-color 1s ease, color 1s ease'
                    : 'none',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    minHeight: '0 !important',
                    '&.Mui-expanded': {
                      minHeight: '0 !important',
                    },
                    '& .MuiAccordionSummary-content': {
                      margin: '12px 0 !important',
                    },
                    '& .MuiAccordionSummary-content.Mui-expanded': {
                      margin: '12px 0 !important',
                    },
                  }}
                >
                  <Typography variant="h4">
                    {item.date}: {item.version}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1">
                    <ReactMarkdown>{item.changes}</ReactMarkdown>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default ChangelogPage;
