import ThemeToggle from '@components/ThemeToggle/ThemeToggle';
import { MobileContext } from '@contexts/MobileContext';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { useContext } from 'react';

import styles from '../Navbar.module.css';

const SidebarOverlay = ({
  isDarkMode,
  isClosing,
  handleClose,
  isActive,
  handleClick,
}) => {
  const isMobile = useContext(MobileContext);
  const navItems = [
    { label: 'Blog', path: '/blog' },
    { label: 'Gallery', path: '/gallery' },
    { label: 'About', path: '/about' },
  ];

  return (
    <>
      {!isMobile && (
        <Box
          className={`${styles.overlay} ${isClosing ? styles.closingOverlay : ''}`}
          onClick={handleClose}
        />
      )}

      <Box
        className={`${isMobile ? styles.dropdownMenuMobile : styles.dropdownMenu} ${isClosing ? (isMobile ? styles.closingDropdownMobile : styles.closingDropdown) : ''}`}
        sx={{
          backgroundColor: isDarkMode ? '#212121' : 'white',
          transition: 'background-color 1s ease',
        }}
      >
        <Box>
          <Typography
            className={styles.navbarSidebarTitle}
            variant="h4"
            sx={{ marginTop: '2.5vh', marginBottom: '2.5vh' }}
          >
            a blog by eden west
          </Typography>
        </Box>

        {navItems.map((item) => (
          <Button
            key={item.label}
            onClick={() => handleClick(item.path)}
            className={styles.navbarItem}
            disabled={isActive(item.path)}
            sx={{ width: '80%', marginBottom: '2vh' }}
          >
            <Typography
              variant="h6"
              className={`${styles.navbarText} ${
                isActive(item.path) ? styles.navbarTextActive : ''
              }`}
            >
              {item.label}
            </Typography>
          </Button>
        ))}

        <ThemeToggle />

        <Box className={styles.navbarIconButtons}>
          <IconButton
            className={styles.navbarIconButton}
            color="inherit"
            aria-label="instagram"
            onClick={() =>
              window.open('https://instagram.com/eden.y.wang', '_blank')
            }
          >
            <InstagramIcon />
          </IconButton>

          <IconButton
            className={styles.navbarIconButton}
            color="inherit"
            aria-label="spotify"
            onClick={() =>
              window.open(
                'https://open.spotify.com/user/goldensword469',
                '_blank'
              )
            }
          >
            <MusicNoteIcon />
          </IconButton>

          <IconButton
            className={styles.navbarIconButton}
            color="inherit"
            aria-label="linkedin"
            onClick={() =>
              window.open('https://linkedin.com/in/eyw520', '_blank')
            }
          >
            <LinkedInIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default SidebarOverlay;
