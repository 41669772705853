import { Box } from '@mui/material';
import React, { useState } from 'react';

import styles from '../GalleryPage.module.css';
import InPageCarousel from './InPageCarousel';
import ModalCarousel from './ModalCarousel';

const GalleryCarousel = ({ title = null, description = null, images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleModalCarouselClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setIsActive(false);
    }, 500);
  };

  return (
    <Box className={styles.galleryCarousel}>
      {isActive && (
        <ModalCarousel
          title={title}
          description={description}
          images={images}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          isClosing={isClosing}
          onClose={handleModalCarouselClose}
        />
      )}
      <InPageCarousel
        title={title}
        description={description}
        images={images}
        setIsActive={setIsActive}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </Box>
  );
};

export default GalleryCarousel;
