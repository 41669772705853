import { Box, Button, Typography } from '@mui/material';
import commonStyles from '@styles/common.module.css';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import styles from './FallbackPage.module.css';

const FallbackPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>404 | EdenInc</title>
      </Helmet>
      <Box className={commonStyles.pageLayout}>
        <Box className={styles.pageContainer}>
          <Box className={styles.pageErrorCode}>
            <Typography variant="h2">404</Typography>
          </Box>
          <Typography variant="h1" sx={{ marginBottom: '2rem' }}>
            Page not found
          </Typography>
          <Typography variant="h3" sx={{ mb: '2rem' }}>
            The page you are looking for does not exist.
          </Typography>
          <Button variant="mono" onClick={() => navigate('/')}>
            Return Home
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FallbackPage;
