import { useMediaQuery, useTheme } from '@mui/material';
import React, { createContext } from 'react';

export const MobileContext = createContext(false);

export const MobileProvider = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>
  );
};
