export default {
  "metadata": {
    "num_words": 609
  },
  "content": [
    {
      "text": "Ode to Summer ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Ode to Summer ",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "I sit beside my window and lean towards the fine mesh screen. A breeze whistles through the distant tree branches. A stray crow's call stirs the still summer night. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I sit beside my window and lean towards the fine mesh screen. A breeze whistles through the distant tree branches. A stray crow's call stirs the still summer night. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I fill my lungs with the cool air. It is 57 degrees, abnormally low for summers on Long Island. June lows hover around seventy, and the past two weeks have been rather warm, high seventies to mid-eighties. In this moment, however, I savor the cold. A part of me wonders if I\u2019ll remember the stirrings of goosebumps and the chill of a cool breeze come July, August. Probably not. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I fill my lungs with the cool air. It is 57 degrees, abnormally low for summers on Long Island. June lows hover around seventy, and the past two weeks have been rather warm, high seventies to mid-eighties. In this moment, however, I savor the cold. A part of me wonders if I\u2019ll remember the stirrings of goosebumps and the chill of a cool breeze come July, August. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Probably not",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "New York weather adheres to the seasons. The winters are cold and harsh. The nights dip below freezing and the days hover in the thirties. Occasionally, there comes a winter storm that tears across the coast, leaving in its wake a foot of snow. The days following such storms are always filled with magic. Walking down the sidewalk, the snowbanks on either side are piled high. As the week goes on, the snowdrifts turn dark from dirt and soot. Patches of dirty browns show up from gaps on the lawn. The magic of snowfall vanishes until the next storm paints the scene anew. Nobody dreams of dirty snow. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "New York weather adheres to the seasons. The winters are cold and harsh. The nights dip below freezing and the days hover in the thirties. Occasionally, there comes a winter storm that tears across the coast, leaving in its wake a foot of snow. The days following such storms are always filled with magic. Walking down the sidewalk, the snowbanks on either side are piled high. As the week goes on, the snowdrifts turn dark from dirt and soot. Patches of dirty browns show up from gaps on the lawn. The magic of snowfall vanishes until the next storm paints the scene anew. Nobody dreams of dirty snow. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "New York summers are the opposite. The temperate days of April and May are soon forgotten as June brings the summer sun. Come July and August, the days grow hot, pushing into the eighties and nineties. The humidity does us no favor. Eighty can feel like ninety when the air is humid, and ninety can feel like a hundred. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "New York summers are the opposite. The temperate days of April and May are soon forgotten as June brings the summer sun. Come July and August, the days grow hot, pushing into the eighties and nineties. The humidity does us no favor. Eighty can feel like ninety when the air is humid, and ninety can feel like a hundred. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "And, of course, there are the summer storms. Over the course of a week, the skies darken, the winds still, and the world waits with bated breath. Then, when we can wait no longer, the skies tear at their seams and the rains fall heavy to the ground. There is lightning that lights the sky, thunder that shakes the earth. But then, it stops, just as suddenly as it began. The clouds collapse back into blue, and the cycle begins anew.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "And, of course, there are the summer storms. Over the course of a week, the skies darken, the winds still, and the world waits with bated breath. Then, when we can wait no longer, the skies tear at their seams and the rains fall heavy to the ground. There is lightning that lights the sky, thunder that shakes the earth. But then, it stops, just as suddenly as it began. The clouds collapse back into blue, and the cycle begins anew.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I have found California to be far more capricious. I have observed that there are not four seasons to a year, but four seasons to a day. The mornings are spring and pleasant. The afternoons swell into golden summer. The evenings fade to fall. And, of course, the nights are winter. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I have found California to be far more capricious. I have observed that there are not four seasons to a year, but four seasons to a day. The mornings are spring and pleasant. The afternoons swell into golden summer. The evenings fade to fall. And, of course, the nights are winter. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There is little weather for much of the year. During my first fall at Stanford, I saw eight weeks of blue skies, without so much as a drop of rain. It was only over Thanksgiving break, after campus had emptied out, that I finally heard the first drops of rain against my window. When rain did come, it came all at once. It did not stop raining the entire week of Thanksgiving.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There is little weather for much of the year. During my first fall at Stanford, I saw eight weeks of blue skies, without so much as a drop of rain. It was only over Thanksgiving break, after campus had emptied out, that I finally heard the first drops of rain against my window. When rain did come, it came all at once. It did not stop raining the entire week of Thanksgiving.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Steinbeck wrote of Salinas fondly in East of Eden, describing the cycle of wet years and dry years, with each cycle spanning nearly two decades. Are we in the midst of a dry spell, or are these the wet years? I suppose I wouldn\u2019t know any different. Four years at Stanford is too soon to tell. The Salinas Valley isn\u2019t too far out from Palo Alto. Perhaps an hour drive down, just past San Jose. I\u2019d like to take that trip someday, when the world regains a semblance of normalcy. Steinbeck wrote of lupins and poppies and sunflowers. I wonder if I\u2019ll still find them today.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Steinbeck wrote of Salinas fondly in ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "East of Eden",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", describing the cycle of wet years and dry years, with each cycle spanning nearly two decades. Are we in the midst of a dry spell, or are these the wet years? I suppose I wouldn\u2019t know any different. Four years at Stanford is too soon to tell. The Salinas Valley isn\u2019t too far out from Palo Alto. Perhaps an hour drive down, just past San Jose. I\u2019d like to take that trip someday, when the world regains a semblance of normalcy. Steinbeck wrote of lupins and poppies and sunflowers. I wonder if I\u2019ll still find them today.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Will I remember the fairy lights, the forever nights when I do leave Stanford? Already, my brief time spent on campus has begun to blur. Probably not, but I'll certainly try. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Will I remember the fairy lights, the forever nights when I do leave Stanford? Already, my brief time spent on campus has begun to blur. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Probably not, but I'll certainly try",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}