import metadata from '@content/blog/metadata';

const searchContent = (query) => {
  if (!query) return [];

  let results = [];
  const lowerQuery = query.toLowerCase();
  const cleanQuery = lowerQuery.replace(/[^a-zA-Z0-9 ]/g, '');

  Object.entries(metadata).forEach(([id, post]) => {
    if (post.type === 'post' && post.file) {
      try {
        const module = require(`@content/blog/posts/${post.file}`);
        const content = module.default.content;

        const addedSnippets = new Set();

        content.forEach((block, blockIndex) => {
          if (
            block.type === 'title' &&
            (post.title.toLowerCase().includes(lowerQuery) ||
              post.title.toLowerCase().includes(cleanQuery))
          ) {
            const snippet = post.excerpt;
            if (!addedSnippets.has(snippet)) {
              results.push({
                id,
                title: post.title,
                context: [
                  {
                    location: 'title',
                    snippet,
                  },
                ],
                date: post.date,
              });
              addedSnippets.add(snippet);
            }
          } else if (
            post.excerpt &&
            (post.excerpt.toLowerCase().includes(lowerQuery) ||
              post.excerpt.toLowerCase().includes(cleanQuery))
          ) {
            const snippet = post.excerpt;
            if (!addedSnippets.has(snippet)) {
              results.push({
                id,
                title: post.title,
                context: [
                  {
                    location: 'excerpt',
                    snippet,
                  },
                ],
                date: post.date,
              });
              addedSnippets.add(snippet);
            }
          } else if (
            block.text &&
            (block.text.toLowerCase().includes(lowerQuery) ||
              block.text.toLowerCase().includes(cleanQuery))
          ) {
            const CONTEXT_WORDS = 20;
            const currentBlockWords = content[blockIndex].text.split(' ');

            const matchIndices = [];
            currentBlockWords.forEach((word, index) => {
              if (
                word.toLowerCase().includes(lowerQuery) ||
                word.toLowerCase().includes(cleanQuery)
              ) {
                matchIndices.push(index);
              }
            });

            const matchIndex = matchIndices.reduce((longest, current) => {
              const currentPhrase = currentBlockWords
                .slice(current, current + 3)
                .join(' ');
              const longestPhrase = currentBlockWords
                .slice(longest, longest + 3)
                .join(' ');
              return currentPhrase.length > longestPhrase.length
                ? current
                : longest;
            }, matchIndices[0]);

            let beforeWords = currentBlockWords.slice(
              Math.max(0, matchIndex - CONTEXT_WORDS),
              matchIndex
            );

            const PHRASE_LOOKAHEAD = 3;
            let afterWords = currentBlockWords.slice(
              matchIndex + 1,
              matchIndex + CONTEXT_WORDS + PHRASE_LOOKAHEAD
            );

            let beforeIndex = blockIndex - 1;
            while (beforeWords.length < CONTEXT_WORDS && beforeIndex >= 0) {
              const words = content[beforeIndex].text.split(' ');
              const needed = CONTEXT_WORDS - beforeWords.length;
              const sliceStart = Math.max(0, words.length - needed);
              const chunk = words.slice(sliceStart);

              beforeWords = [...chunk, ...beforeWords];
              beforeIndex--;
            }

            let afterIndex = blockIndex + 1;
            while (
              afterWords.length < CONTEXT_WORDS &&
              afterIndex < content.length
            ) {
              const words = content[afterIndex].text.split(' ');
              const needed = CONTEXT_WORDS - afterWords.length;
              const chunk = words.slice(0, needed);

              afterWords = [...afterWords, ...chunk];
              afterIndex++;
            }

            beforeWords = beforeWords.slice(-CONTEXT_WORDS);
            afterWords = afterWords.slice(0, CONTEXT_WORDS);

            beforeWords = beforeWords.map((word) =>
              /^[^a-zA-Z0-9]+$/.test(word) ? '...' : word
            );
            afterWords = afterWords.map((word) =>
              /^[^a-zA-Z0-9]+$/.test(word) ? '...' : word
            );
            const matchPhrase = currentBlockWords
              .slice(matchIndex, matchIndex + PHRASE_LOOKAHEAD)
              .join(' ');

            const snippet = [
              '...',
              ...beforeWords,
              matchPhrase,
              ...afterWords,
              '...',
            ].join(' ');

            if (!addedSnippets.has(snippet)) {
              results.push({
                id,
                title: post.title,
                context: [
                  {
                    location: 'block',
                    snippet,
                  },
                ],
                date: post.date,
              });
              addedSnippets.add(snippet);
            }
          }
        });
      } catch (error) {
        console.error(`Error loading content for ${post.file}:`, error);
      }
    }
  });

  const groupedResults = results.reduce((acc, current) => {
    const existingResult = acc.find((item) => item.id === current.id);
    if (existingResult) {
      current.context.forEach((newContext) => {
        if (
          !existingResult.context.some(
            (ctx) => ctx.snippet === newContext.snippet
          )
        ) {
          existingResult.context.push(newContext);
        }
      });
      return acc;
    }
    return [...acc, current];
  }, []);

  return groupedResults;
};

export { searchContent };
