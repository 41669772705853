const shiftCharacter = (char, n) => {
  if (!/[a-zA-Z]/.test(char)) return char;
  const isUpperCase = char === char.toUpperCase();
  const base = isUpperCase ? 'A'.charCodeAt(0) : 'a'.charCodeAt(0);
  const shifted = ((char.toLowerCase().charCodeAt(0) - base + n) % 26) + base;
  return String.fromCharCode(shifted)[
    isUpperCase ? 'toUpperCase' : 'toLowerCase'
  ]();
};

export { shiftCharacter };
