export default {
  "metadata": {
    "num_words": 231
  },
  "content": [
    {
      "text": "Beneath the London Eye",
      "alignment": null,
      "style": "Normal",
      "indentation_left": 36.0,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Beneath the London Eye",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "It was mid-October and I was alone in London, with nothing but my thoughts to keep me company. Where you had been, a grave remained, and I had yet to pave it over with new memories. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It was mid-October and I was alone in London, with nothing but my thoughts to keep me company. Where you had been, a grave remained, and I had yet to pave it over with new memories. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It was overcast, that day, but I had done rather well for myself. I strolled the Palace in the morning and visited the Gallery at midday. I savored jambon and Padr\u00f2n peppers in the silver hours of the afternoon.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It was overcast, that day, but I had done rather well for myself. I strolled the Palace in the morning and visited the Gallery at midday. I savored jambon and Padr\u00f2n peppers in the silver hours of the afternoon.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "But as I walked the bridge by the Big Ben, thoughts of you flooded me, and it was all I could do to simply keep walking, placing one foot over the next, staggering along the Thames.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "But as I walked the bridge by the Big Ben, thoughts of you flooded me, and it was all I could do to simply keep walking, placing one foot over the next, staggering along the Thames.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It was at a lonely bench where I collapsed, the grief too heavy to carry. The Eye rose above me on the opposite bank, its spokes carving gentle arcs in the sky as it turned.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It was at a lonely bench where I collapsed, the grief too heavy to carry. The Eye rose above me on the opposite bank, its spokes carving gentle arcs in the sky as it turned.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I sat there, for some time, feeling the weight of your absence. I watched as the sky turned the dark indigo of eventide, as the orange streetlamps flickered to life, as the Eye glowed its signature red.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I sat there, for some time, feeling the weight of your absence. I watched as the sky turned the dark indigo of eventide, as the orange streetlamps flickered to life, as the Eye glowed its signature red.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It would be another hour until I found the strength to leave, but when I did, I left a piece of me behind. The piece of me that wished so dearly you were there by my side.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It would be another hour until I found the strength to leave, but when I did, I left a piece of me behind. The piece of me that wished so dearly you were there by my side.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Because somehow, in that moment, you were.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Because somehow, in that moment, you were.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}