import { MobileContext } from '@contexts/MobileContext';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from '../Navbar.module.css';

const NavbarMain = ({
  isMobile,
  toggleSidebar,
  toggleSearch,
  sidebarOpen,
  sidebarClosing,
  searchOpen,
  searchClosing,
}) => {
  const navigate = useNavigate();

  return (
    <Box className={styles.navbarInner}>
      <Box className={styles.navbarLeft}>
        <IconButton color="inherit" aria-label="menu" onClick={toggleSidebar}>
          {sidebarOpen && !sidebarClosing ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </Box>

      {!isMobile && (
        <Box className={styles.navbarCenter} onClick={() => navigate('/')}>
          <Typography variant="h4" className={styles.navbarTitleLeft}>
            edenincorporated
          </Typography>
          <Typography
            variant="h4"
            className={styles.navbarTitleRight}
            sx={{ color: '#7d7d7d' }}
          >
            by e. west
          </Typography>
        </Box>
      )}

      <Box className={styles.navbarRight}>
        <IconButton color="inherit" aria-label="search" onClick={toggleSearch}>
          {searchOpen && !searchClosing ? <CloseIcon /> : <SearchIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default NavbarMain;
