import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const TypewriterText = ({ isVisible, duration, text }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!isVisible) {
      setDisplayText('');
      setCurrentIndex(0);
      return;
    }

    if (currentIndex >= text.length) return;

    const intervalTime = duration / text.length;
    const timer = setInterval(() => {
      setDisplayText((prev) => prev + text[currentIndex]);
      setCurrentIndex((prev) => prev + 1);
    }, intervalTime);

    return () => clearInterval(timer);
  }, [isVisible, currentIndex, text, duration]);

  if (!isVisible) return null;

  return (
    <Typography variant="body1" sx={{ height: '150px' }}>
      {displayText}
    </Typography>
  );
};

export default TypewriterText;
