import { MobileContext } from '@contexts/MobileContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material';
import React, { useContext, useRef, useState } from 'react';

import styles from '../GalleryPage.module.css';

const InPageCarousel = ({
  title,
  description,
  images,
  setIsActive,
  currentIndex,
  setCurrentIndex,
}) => {
  const isMobile = useContext(MobileContext);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const thumbnailContainerRef = useRef(null);

  const handleScroll = () => {
    if (thumbnailContainerRef.current) {
      setIsAtBottom(
        thumbnailContainerRef.current.scrollTop +
          thumbnailContainerRef.current.clientHeight >=
          thumbnailContainerRef.current.scrollHeight
      );
    }
  };

  return (
    <Box className={styles.carouselContainer}>
      {title || description ? (
        <Box className={styles.carouselTitleContainer}>
          {title && (
            <Typography variant="h5" sx={{ textAlign: 'center', mb: 1 }}>
              {title}
            </Typography>
          )}
          {description && (
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              {description}
            </Typography>
          )}
        </Box>
      ) : null}

      <Box
        className={
          isMobile
            ? styles.mobileCarouselContentContainer
            : styles.carouselContentContainer
        }
      >
        <Box
          className={styles.carouselImageContainer}
          sx={{
            width: isMobile ? '100%' : '60%',
            height: isMobile ? '500px' : '100%',
            ...(isMobile && {
              minHeight: '500px',
            }),
          }}
        >
          <img
            src={require(
              `@content/gallery/sunsets/${images[currentIndex].image}`
            )}
            alt={images[currentIndex].description}
            onClick={() => setIsActive(true)}
            className={styles.carouselImage}
          />
          <Box className={styles.carouselImageInfoContainer}>
            <Typography variant="body2" sx={{ textAlign: 'center', mb: 1 }}>
              {images[currentIndex].description}
            </Typography>
            <Typography variant="caption" sx={{ textAlign: 'center' }}>
              {images[currentIndex].date}
            </Typography>
          </Box>
        </Box>
        <Box
          className={styles.carouselThumbnailContainer}
          sx={{ width: isMobile ? '100%' : '40%' }}
        >
          <Box
            className={styles.carouselThumbnailContent}
            ref={thumbnailContainerRef}
            onScroll={handleScroll}
          >
            <Box className={styles.carouselThumbnailSpacer} />
            {images.map((image, index) => (
              <Box
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={styles.carouselThumbnail}
                sx={{
                  opacity: index === currentIndex ? 1 : 0.7,
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <img
                  src={require(`@content/gallery/sunsets/${image.image}`)}
                  alt={image.description}
                  className={styles.carouselThumbnailImage}
                  style={{
                    border: index === currentIndex ? `3px solid grey` : 'none',
                  }}
                />
              </Box>
            ))}
          </Box>
          <Box
            className={styles.carouselThumbnailScrollButton}
            sx={{
              opacity: isAtBottom ? 0.5 : 1,
              animation: isAtBottom ? 'none' : 'glow 1.5s ease-in-out infinite',
              '@keyframes glow': {
                '0%': { opacity: 0.5 },
                '50%': { opacity: 1 },
                '100%': { opacity: 0.5 },
              },
            }}
          >
            <ExpandMoreIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InPageCarousel;
