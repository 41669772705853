export default {
  "metadata": {
    "num_words": 545
  },
  "content": [
    {
      "text": "An Afternoon Away",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "An Afternoon Away",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "After turning in my final exam of Fall Quarter, I park my bike at Memorial Church and pretend that I am a tourist on this sprawling campus I call home. I wander under carved sandstone arches, stroll past trellises draped with wisteria, and gawk at passing students the same way my mother did during our first visit to Stanford campus years ago. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "After turning in my final exam of Fall Quarter, I park my bike at Memorial Church and pretend that I am a tourist on this sprawling campus I call home. I wander under carved sandstone arches, stroll past trellises draped with wisteria, and gawk at passing students the same way my mother did during our first visit to Stanford campus years ago. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "On bike, our sprawling complex of sandstone halls and low adobe dorms can be spanned in just twenty minutes, and the time-space between destinations melts into a blur. But on foot, five minute bike rides become twenty minute walks. Campus returns to human scale, and I am able to experience it in its entirety.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "On bike, our sprawling complex of sandstone halls and low adobe dorms can be spanned in just twenty minutes, and the time-space between destinations melts into a blur. But on foot, five minute bike rides become twenty minute walks. Campus returns to human scale, and I am able to experience it in its entirety.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I peer through windows upon offices stocked with books and halls plastered with flyers. I count the class tiles in the arcades until I reach the empty spaces where our tile \u2014 for the class of 2023 \u2014 will eventually be laid. I breathe in details that normally escape my gaze: the last few flowering trees of Fall, the dense ivy that climbs the sandstone walls, the towering palms that first caught my imagination so many years ago. I become an observer of this world I normally take part in. I join the tourists I normally bike past without a second thought.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I peer through windows upon offices stocked with books and halls plastered with flyers. I count the class tiles in the arcades until I reach the empty spaces where our tile \u2014 for the class of 2023 \u2014 will eventually be laid. I breathe in details that normally escape my gaze: the last few flowering trees of Fall, the dense ivy that climbs the sandstone walls, the towering palms that first caught my imagination so many years ago. I become an observer of this world I normally take part in. I join the tourists I normally bike past without a second thought.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I imagined a Stanford of palm trees and daydreams and never-setting sun. I imagined that the Quad, the Oval, and Palm Drive would form the core of my experience. What I didn\u2019t expect was that these landmarks would so quickly be supplanted by smaller, more intimate spaces. I grew to love a Stanford defined by hours spent creating at Roble Arts Gym, walking along Lake Lag, and dancing in the parking garage. I hold dear a Stanford defined by the friendships and memories forged through late-night laughter. A Stanford so unlike the one I imagined, but so much richer than anything I dreamed of.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I imagined a Stanford of palm trees and daydreams and never-setting sun. I imagined that the Quad, the Oval, and Palm Drive would form the core of my experience. What I didn\u2019t expect was that these landmarks would so quickly be supplanted by smaller, more intimate spaces. I grew to love a Stanford defined by hours spent creating at Roble Arts Gym, walking along Lake Lag, and dancing in the parking garage. I hold dear a Stanford defined by the friendships and memories forged through late-night laughter. A Stanford so unlike the one I imagined, but so much richer than anything I dreamed of.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I write this, sitting on a bench that normally escapes my notice. The benches across campus are invisible to tunnel-visioned students during our shared ten-week marathon. But as I slow down, I notice them now, scattered all throughout campus, inviting passersby to sit and meditate in gentle shade. I do exactly that, at a bench just beside the arcades. I trace the soft shadows cast by the palm fronds. I admire the way the sandstone catches the golden afternoon light. I notice the splashes of color against the more muted hues of a winter soon to come. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I write this, sitting on a bench that normally escapes my notice. The benches across campus are invisible to tunnel-visioned students during our shared ten-week marathon. But as I slow down, I notice them now, scattered all throughout campus, inviting passersby to sit and meditate in gentle shade. I do exactly that, at a bench just beside the arcades. I trace the soft shadows cast by the palm fronds. I admire the way the sandstone catches the golden afternoon light. I notice the splashes of color against the more muted hues of a winter soon to come. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I am still a student, and I will be for many more quarters. But I wonder what will become of my Stanford when I eventually leave, when I conclude this chapter of my life. As the years melt away, what will become of this experience? A professor once remarked to me how her peers slowly stopped wearing their college sweaters, how trophies from their campus exploits eventually vanished from their walls. Will that happen to me, as well? Life beyond Stanford is still too far away to imagine. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I am still a student, and I will be for many more quarters. But I wonder what will become of my Stanford when I eventually leave, when I conclude this chapter of my life. As the years melt away, what will become of this experience? A professor once remarked to me how her peers slowly stopped wearing their college sweaters, how trophies from their campus exploits eventually vanished from their walls. Will that happen to me, as well? Life beyond Stanford is still too far away to imagine. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "December at Stanford can\u2019t be seen, but it can certainly be felt. The afternoon warmth fades and the shadows grow long. I pick up my bag, hop on my bike, and return to my world.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "December at Stanford can\u2019t be seen, but it can certainly be felt. The afternoon warmth fades and the shadows grow long. I pick up my bag, hop on my bike, and return to my world.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}