export default {
  "metadata": {
    "num_words": 456
  },
  "content": [
    {
      "text": "Intermezzo",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Intermezzo",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "I am sifting my memories, the way men pan the dirt under a barroom floor for the bits of gold dust that fall between the cracks. It's small mining \u2014 small mining. You're too young a man to be panning memories, Adam. You should be getting yourself some new ones, so that the mining will be richer when you come to age. \u2014 John Steinbeck, East of Eden.\u00a0",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I am sifting my memories, the way men pan the dirt under a barroom floor for the bits of gold dust that fall between the cracks. It's small mining \u2014 small mining. You're too young a man to be panning memories, Adam. You should be getting yourself some new ones, so that the mining will be richer when you come to age. \u2014 ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "John Steinbeck",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", East of Eden.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u00a0",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It is Coterm Spring, my final quarter at Stanford, and campus is in full bloom. Gone are the mid-afternoon sunsets and the biting winter nights, replaced by verdant summergrass, wildflowers, and days so warm you could sleep right through them if you so wished. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It is Coterm Spring, my final quarter at Stanford, and campus is in full bloom. Gone are the mid-afternoon sunsets and the biting winter nights, replaced by verdant summergrass, wildflowers, and days so warm you could sleep right through them if you so wished. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The days are giddy with darties and fountain hoppers; the nights are alive with barbecue smoke and partygoers. The end of the quarter is in sight: classes are wrapping up, and the Quad is filled with students posing for grad photos, each clad in white struck by a bolt of bright red. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The days are giddy with darties and fountain hoppers; the nights are alive with barbecue smoke and partygoers. The end of the quarter is in sight: classes are wrapping up, and the Quad is filled with students posing for grad photos, each clad in white struck by a bolt of bright red. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The prospect of graduation has yet to fully sink in. But already, the lasts are flying by faster than we can hope to catch. Our last Frost Festival and Bay to Breakers took place last week. Our last class formal flew by this past Thursday. Our last dance showcase was just a few days ago. It is a growing list of lasts, and a new one is added with each passing day. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The prospect of graduation has yet to fully sink in. But already, the ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "lasts",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " are flying by faster than we can hope to catch. Our last Frost Festival and Bay to Breakers took place last week. Our last class formal flew by this past Thursday. Our last dance showcase was just a few days ago. It is a growing list of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "lasts",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", and a new one is added with each passing day. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I have been at Stanford for five years. It has been five years of immense change. And in light of change, growth, sometimes painful, marred by setbacks, yet always deeply rewarding. I came into Stanford hoping to live and learn deeply, truly. I am proud to say that I achieved exactly that. Stanford \u2014 and by extension, the Bay \u2014 has become a second home, one that I will surely return to in the years to come. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I have been at Stanford for five years. It has been five years of immense change. And in light of change, growth, sometimes painful, marred by setbacks, yet always deeply rewarding. I came into Stanford hoping to live and learn ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "deeply",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "truly",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". I am proud to say that I achieved exactly that. Stanford \u2014 and by extension, the Bay \u2014 has become a second home, one that I will surely return to in the years to come. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There are twenty days to graduation and I must make the most of each and every one. I walk along paths tread a hundred times over and bathe in the memories that emerge from them. I sort through the photos, videos, and journals collected over the years and find peace through their chaos. It is a panning made rich by time well spent, relationships well nurtured, and moments well savored.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There are twenty days to graduation and I must make the most of each and every one. I walk along paths tread a hundred times over and bathe in the memories that emerge from them. I sort through the photos, videos, and journals collected over the years and find peace through their chaos. It is a panning made rich by time well spent, relationships well nurtured, and moments well savored.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The path ahead is uncertain, but I am reminded that uncertainty is a privilege. Terrifying as it may be, uncertainty is abundance. With the power of choice comes the privilege to design a life worth living. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The path ahead is uncertain, but I am reminded that uncertainty is a privilege. Terrifying as it may be, ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "uncertainty is abundance",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". With the power of choice comes the privilege to design a life worth living. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Where one journey comes to an end, another begins. Thank you, Stanford, for five years within your adobe halls, for a lifetime of memories forged under California sun. I am excited for the life ahead. E.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Where one journey comes to an end, another begins. Thank you, Stanford, for five years within your adobe halls, for a lifetime of memories forged under California sun. I am excited for the life ahead. E.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}