export default {
  "metadata": {
    "num_words": 250
  },
  "content": [
    {
      "text": "Atop Mount Inari",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Atop Mount Inari",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "We arrived late in the afternoon to the gates of Fushimi Inari, after a day well spent exploring the city of shrines. The sky was heavy with clouds, lit silver by the dying light.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We arrived late in the afternoon to the gates of Fushimi Inari, after a day well spent exploring the city of shrines. The sky was heavy with clouds, lit silver by the dying light.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We hiked beneath crimson torii and marveled at the stone foxes that kept careful vigil. As we walked, the clouds blossomed into sheets of satin rain. The magic of the moment made us giddy with excitement.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We hiked beneath crimson ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "torii",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " and marveled at the stone foxes that kept careful vigil. As we walked, the clouds blossomed into sheets of satin rain. The magic of the moment made us giddy with excitement.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We came upon a junction; two paths diverged at a fork in the road. One led up the mountain, shrouded in dark summer greens, the other, back to the temple complex below. We took the path less traveled.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We came upon a junction; two paths diverged at a fork in the road. One led up the mountain, shrouded in dark summer greens, the other, back to the temple complex below. We took the path less traveled.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It had been a hot day, but the rain was cool against our skin. The earthy scents of the mountain and a forest untamed was balanced by the crisp tones of a world after rain. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It had been a hot day, but the rain was cool against our skin. The earthy scents of the mountain and a forest untamed was balanced by the crisp tones of a world after rain. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "You had been tired after a day sightseeing, but there was a new spring in your step as we climbed the mossy path, up past a thousand candlelit shrines. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "You had been tired after a day sightseeing, but there was a new spring in your step as we climbed the mossy path, up past a thousand candlelit shrines. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We reached the summit shortly after dusk to look out at a city aglow. We were drenched and exhausted, but the city was brilliant before us, awash in electric flame, and so it had all been worth it. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We reached the summit shortly after dusk to look out at a city aglow. We were drenched and exhausted, but the city was brilliant before us, awash in electric flame, and so it had all been worth it. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I looked at you, in that moment, as you breathed the view in. There, in your eyes, was the light I'd grown to love so dearly. I knew, then, that time could never steal away this memory.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I looked at you, in that moment, as you breathed the view in. There, in your eyes, was the light I'd grown to love so dearly. I knew, then, that time could never steal away this memory.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}