export default {
  "metadata": {
    "num_words": 284
  },
  "content": [
    {
      "text": "Nagano for an Evening",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Nagano for an Evening",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "It had been grey in Tokyo, but as the train rolled into Nagano station, I looked out upon a gentle green valley under clear blue sky. We were grateful to be out of the storm at last.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It had been grey in Tokyo, but as the train rolled into Nagano station, I looked out upon a gentle green valley under clear blue sky. We were grateful to be out of the storm at last.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We rode a shuttle to our onsen hotel and its ornate facade took our breath away. It loomed grand above us, its roof draped with glowing lanterns, its curtained balconies gold from the flickering flames within.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We rode a shuttle to our ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "onsen",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " hotel and its ornate facade took our breath away. It loomed grand above us, its roof draped with glowing lanterns, its curtained balconies gold from the flickering flames within.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We changed into bathhouse garb and wore wooden slippers out into the street. Our steps rang against the paved stone tiles as we wandered down the narrow alleys and up the hillside shrines.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We changed into bathhouse garb and wore wooden slippers out into the street. Our steps rang against the paved stone tiles as we wandered down the narrow alleys and up the hillside shrines.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We watched as the sun dipped beneath the grey mountains that cradled the valley. Our shadows grew long under the waning sun, your face rosy in the soft afternoon light.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We watched as the sun dipped beneath the grey mountains that cradled the valley. Our shadows grew long under the waning sun, your face rosy in the soft afternoon light.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "But it was that evening when the town truly came alive.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "But it was that evening when the town truly came alive.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We lost ourselves in the halls of the hotel, exploring its winding corridors, our shadows dancing under the swaying oil lanterns. All around us was the bubbling of hot springs and the lingering scent of sulfur.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We lost ourselves in the halls of the hotel, exploring its winding corridors, our shadows dancing under the swaying oil lanterns. All around us was the bubbling of hot springs and the lingering scent of sulfur.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We ate kaiseki on a thatched mat, the flavors of the valley bursting upon our tongue. We chased it down with sweet apricot wine that sang of summer.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We ate ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "kaiseki",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " on a thatched mat, the flavors of the valley bursting upon our tongue. We chased it down with sweet apricot wine that sang of summer.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We ventured out and celebrated the night with local bartenders. We sipped Suntory in a neon speakeasy under scenes of island fish swimming on an old TV set. We retired late, hearts full from a day well spent. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We ventured out and celebrated the night with local bartenders. We sipped ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Suntory",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " in a neon speakeasy under scenes of island fish swimming on an old TV set. We retired late, hearts full from a day well spent. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We left town early the next morning, taking the morning bullet train back to Tokyo. I\u2019ll never forget watching as the valley retreated, back into the dream from where it came.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We left town early the next morning, taking the morning bullet train back to Tokyo. I\u2019ll never forget watching as the valley retreated, back into the dream from where it came.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}