import { Box, Typography } from '@mui/material';

import styles from '../PostPage.module.css';

const PostBookend = () => {
  return (
    <Box className={styles.postBookend}>
      <Typography sx={{ fontStyle: 'italic' }}>~ Fin. ~</Typography>
    </Box>
  );
};

export default PostBookend;
