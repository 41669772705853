import Footer from '@components/Footer/Footer';
import Navbar from '@components/Navbar/Navbar';
import { Box, Button, TextField, Typography } from '@mui/material';
import commonStyles from '@styles/common.module.css';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import styles from './NewsletterPage.module.css';

const NewsletterPage = () => {
  const [email, setEmail] = useState('');

  const handleSignup = () => {
    // TODO: Use a Serverless Function to Add Email to List
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSignup();
    }
  };

  return (
    <>
      <Helmet>
        <title>Timshel | EdenInc</title>
      </Helmet>
      <Box className={commonStyles.pageLayout}>
        <Navbar />
        <Box className={styles.pageContainer}>
          <Box className={styles.pageContent}>
            <Typography variant="h2" sx={{ marginBottom: '1rem' }}>
              Timshel
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '2rem' }}>
              Thoughts and musings and odd updates. <i>lovefrom, eden</i>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                gap: '1rem',
              }}
            >
              <TextField
                value={email}
                variant="standard"
                placeholder="Enter your email"
                className={styles.emailInput}
                error={
                  email !== '' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
                }
                InputProps={{
                  style: {
                    fontSize: '1rem',
                    textAlign: 'center',
                  },
                }}
                inputProps={{
                  style: {
                    textAlign: 'center',
                  },
                }}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Button
                variant="text"
                disabled={!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)}
                onClick={handleSignup}
                sx={{
                  fontSize: '1rem',
                  minWidth: 'fit-content',
                  color: 'text.secondary',
                }}
              >
                Subscribe
              </Button>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default NewsletterPage;
