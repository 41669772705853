export default {
  "metadata": {
    "num_words": 1069
  },
  "content": [
    {
      "text": "An Epoch\u2019s Soul: Life and Fate by Vasily Grossman",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "An Epoch\u2019s Soul: ",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Life and Fate",
          "bold": true,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " by Vasily Grossman",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "We learn, through history, that the mighty German advance was halted at Stalingrad in 1942. That the city itself, though of significant strategical value, also carried great symbolic weight in both Hitler and Stalin\u2019s eyes. That after months of difficult, close-quarters fighting, the stalemate would be broken by bold Soviet counter-offensives. That after the surrender of Field Marshall Paulus, the encircled, exhausted, and emaciated German 6th army would be dissolved and marched into Soviet prison camps. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We learn, through history, that the mighty German advance was halted at Stalingrad in 1942. That the city itself, though of significant strategical value, also carried great symbolic weight in both Hitler and Stalin\u2019s eyes. That after months of difficult, close-quarters fighting, the stalemate would be broken by bold Soviet counter-offensives. That after the surrender of Field Marshall Paulus, the encircled, exhausted, and emaciated German 6th army would be dissolved and marched into Soviet prison camps. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "What we fail to grasp, however, are the atomic elements that comprise this history. What ran in the minds of Soviet leadership as the Germans threatened the last Russian footholds in Stalingrad? What ran in the mind of a young radio-operator forced onto the front lines in an encircled rubble fortress? What ran in the mind of a Jewish physicist as news of the offensive slowly trickled north and east? These are the atoms that comprise the greater narrative, whose interactions sum to the triumphs and tragedies of our history.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "What we fail to grasp, however, are the atomic elements that comprise this history. What ran in the minds of Soviet leadership as the Germans threatened the last Russian footholds in Stalingrad? What ran in the mind of a young radio-operator forced onto the front lines in an encircled rubble fortress? What ran in the mind of a Jewish physicist as news of the offensive slowly trickled north and east? These are the atoms that comprise the greater narrative, whose interactions sum to the triumphs and tragedies of our history.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Vasily Grossman\u2019s Life and Fate fills in these stories. Grossman, a Russian Jew, had been a scientist and engineer prior to the war. Grossman had attempted \u2014 and failed \u2014 to enlist in military service when the conflict began, and instead served as a correspondent for the Red Star. As a war correspondent, Grossman worked along the Soviet front lines for three of the four years of the conflict. His wartime notebooks included hundreds of individual biographies alongside his personal insights into the war. These notes would form the basis for his first novel on the war, Stalingrad, later renamed For a Just Cause. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Vasily Grossman\u2019s ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Life and Fate",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " fills in these stories. Grossman, a Russian Jew, had been a scientist and engineer prior to the war. Grossman had attempted \u2014 and failed \u2014 to enlist in military service when the conflict began, and instead served as a correspondent for the ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Red Star",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". As a war correspondent, Grossman worked along the Soviet front lines for three of the four years of the conflict. His wartime notebooks included hundreds of individual biographies alongside his personal insights into the war. These notes would form the basis for his first novel on the war, ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Stalingrad",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", later renamed ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "For a Just Cause",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Life and Fate is the successor to Stalingrad and considered by many to be the greater of the two works. Grossman had worked on the novel during the 1950s following the publication of Stalingrad. When the manuscript was completed and sent for review, the KGB raided his apartment and seized all materials related to the work. When Grossman appealed the seizure of his manuscript, the politburo ideology chief Mikhail Suslov responded that there could be no question of the novel being published \"for another two to three hundred years\" \u2014\u00a0a striking remark, undoubtedly a reflection of just how significant and incendiary his work was perceived to be. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Life and Fate",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " is the successor to ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Stalingrad",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " and considered by many to be the greater of the two works. Grossman had worked on the novel during the 1950s following the publication of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Stalingrad",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". When the manuscript was completed and sent for review, the KGB raided his apartment and seized all materials related to the work. When Grossman appealed the seizure of his manuscript, the politburo ideology chief Mikhail Suslov responded that there could be no question of the novel being published \"for another two to three hundred years\" \u2014\u00a0a striking remark, undoubtedly a reflection of just how significant and incendiary his work was perceived to be. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Grossman died in 1964 with no knowledge that his work would ever be published. A copy of the manuscript was smuggled out in 1974 and the novel was published in the West in 1980. Only after Gorbachev\u2019s policy of glasnost was instated did the novel finally come to light in Russia. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Grossman died in 1964 with no knowledge that his work would ever be published. A copy of the manuscript was smuggled out in 1974 and the novel was published in the West in 1980. Only after Gorbachev\u2019s policy of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "glasnost",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " was instated did the novel finally come to light in Russia. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The novel is split into three parts: the stalemate in the city, preparations for a Soviet counter-offensive, and the successful encirclement of the Germans and its fallout. We follow a host of characters, some real, most fictional, but the core of the novel is centered on the Shaposhnikov family. The narrative is woven from a myriad of distant threads. For several chapters, we may follow Viktor Shtrum, a physicist of a State institute, and Lyudmila Shaposhnikova, his wife who mourns the death of her son; then, we find ourselves in the mind of Stalin as he orders the counter-offensive to finally begin, of Hitler as he\u2019s faced with the encirclement of the 6th army, of a Jewish doctor as she dies in the gas chamber, holding a little boy who was alone on the train.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The novel is split into three parts: the stalemate in the city, preparations for a Soviet counter-offensive, and the successful encirclement of the Germans and its fallout. We follow a host of characters, some real, most fictional, but the core of the novel is centered on the Shaposhnikov family. The narrative is woven from a myriad of distant threads. For several chapters, we may follow Viktor Shtrum, a physicist of a State institute, and Lyudmila Shaposhnikova, his wife who mourns the death of her son; then, we find ourselves in the mind of Stalin as he orders the counter-offensive to finally begin, of Hitler as he\u2019s faced with the encirclement of the 6th army, of a Jewish doctor as she dies in the gas chamber, holding a little boy who was alone on the train.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Grossman is acutely aware of Stalingrad\u2019s significance beyond just its physical setting. Stalingrad carried with it the hopes of the Soviet state, the fears of Stalin, the demands of Hitler. But Stalingrad itself stands as a greater symbol of the war against fascism. Every epoch has its own capital city, a city that embodies its will and soul, reflects Grossman, [t]he soul of wartime Stalingrad was freedom.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Grossman is acutely aware of Stalingrad\u2019s significance beyond just its physical setting. Stalingrad carried with it the hopes of the Soviet state, the fears of Stalin, the demands of Hitler. But Stalingrad itself stands as a greater symbol of the war against fascism. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Every epoch has its own capital city, a city that embodies its will and soul",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", reflects Grossman, ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "[t]he soul of wartime Stalingrad was freedom",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ".",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "What defines Grossman\u2019s work is his intimate knowledge of the Stalinist state and ideology. Though most of the characters and conversations within are fictional, the characters are not untypical of the era. There is the successful Party functionary who nimbly treads Party lines and rises through the ranks. There is the Russian physicist who sacrifices his own values to survive the State's bureaucracy. There is the Party loyalist condemned to the labor camps who believes that he has been wrongfully convicted. It is through these characters, their strengths and flaws, their defiance and cowardice, their joys and sorrows, that Grossman paints his portrait of the Stalinist state.   ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "What defines Grossman\u2019s work is his intimate knowledge of the Stalinist state and ideology. Though most of the characters and conversations within are fictional, the characters are not untypical of the era. There is the successful Party functionary who nimbly treads Party lines and rises through the ranks. There is the Russian physicist who sacrifices his own values to survive the State's bureaucracy. There is the Party loyalist condemned to the labor camps who believes that he has been wrongfully convicted. It is through these characters, their strengths and flaws, their defiance and cowardice, their joys and sorrows, that Grossman paints his portrait of the Stalinist state.   ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Grossman himself is a vocal participant in the narrative. There are long passages where he mulls over the Revolution, the Party, the Germans, Stalinism, fascism. Grossman makes an active effort to understand all the perspectives at hand; his insights are often expressed through the thoughts and choices of a wide cast of characters. There is a conversation between a German interrogator and an old Russian ideologist where the ties between the two totalitarian states are laid bare. There is an entire chapter where Grossman admonishes the Stalinist bureaucracy for their callous endangerment of troops. Moments where the State appears most fragile are contrasted against moments where the State stands strongest, moments where the Germans are cruel and inhuman are shown alongside moments where the Russians conduct horrible atrocities out of hatred. Grossman loves the Russian people, but he is no Russian apologist. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Grossman himself is a vocal participant in the narrative. There are long passages where he mulls over the Revolution, the Party, the Germans, Stalinism, fascism. Grossman makes an active effort to understand all the perspectives at hand; his insights are often expressed through the thoughts and choices of a wide cast of characters. There is a conversation between a German interrogator and an old Russian ideologist where the ties between the two totalitarian states are laid bare. There is an entire chapter where Grossman admonishes the Stalinist bureaucracy for their callous endangerment of troops. Moments where the State appears most fragile are contrasted against moments where the State stands strongest, moments where the Germans are cruel and inhuman are shown alongside moments where the Russians conduct horrible atrocities out of hatred. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Grossman loves the Russian people, but he is no Russian apologist. ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Life and Fate is not a novel celebrating the virtues of the Stalinist state or the strength of the Russian spirit. Rather, it is a novel about freedom, kindness, and humanity \u2014 their fragility and their strength, their universality. It is a novel about hope, that, against the overwhelming evils of the world, humanity still persists. Perhaps that is why Grossman never truly condemns the Germans nor depicts the war simply as one for good or evil. Perhaps that is why even Hitler, whose role in the Jewish extermination is not easily lost upon Grossman, is painted as human in defeat, almost pitiful. Perhaps that is why Grossman depicts in equal measure the triumph of the exhausted Russians and the tragedy of the encircled Germans.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Life and Fate",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " is not a novel celebrating the virtues of the Stalinist state or the strength of the Russian spirit. Rather, it is a novel about freedom, kindness, and humanity \u2014 their fragility and their strength, their universality. It is a novel about hope, that, against the overwhelming evils of the world, humanity still persists. Perhaps that is why Grossman never truly condemns the Germans nor depicts the war simply as one for good or evil. Perhaps that is why even Hitler, whose role in the Jewish extermination is not easily lost upon Grossman, is painted as human in defeat, almost pitiful. Perhaps that is why Grossman depicts in equal measure the triumph of the exhausted Russians and the tragedy of the encircled Germans.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Human history is not the battle of good struggling to overcome evil. It is a battle fought by a great evil struggling to crush a small kernel of human kindness. But if what is human in human beings has not been destroyed even now, then evil will never conquer. \u2013 Vasily Grossman, Life and Fate",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Human history is not the battle of good struggling to overcome evil. It is a battle fought by a great evil struggling to crush a small kernel of human kindness. But if what is human in human beings has not been destroyed even now, then evil will never conquer. \u2013 Vasily Grossman, Life and Fate",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}