import metadata from '@content/blog/metadata';
import { Box, Typography } from '@mui/material';
import { compareDate } from '@utils/dateUtils';
import React, { useEffect, useRef, useState } from 'react';

import styles from '../BlogPage.module.css';
import BlogPost from './BlogPost';

const ROTATION_INTERVAL = 5000;

const FeaturedPosts = ({ isResizing, handleTagClick, activeTags }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const timerRef = useRef(null);

  const sortedPosts = Object.entries(metadata).sort(compareDate);
  const featuredPosts = sortedPosts.filter((post) => post[1].featured === true);

  const startRotationTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setActiveIndex((prev) => (prev + 1) % featuredPosts.length);
        setIsTransitioning(false);
      }, 300);
    }, ROTATION_INTERVAL);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsInitialLoad(false);
    }, 100);

    startRotationTimer();
  }, [featuredPosts.length]);

  useEffect(() => {
    if (!isResizing) {
      startRotationTimer();
    }
  }, [isResizing]);

  const handleBreadcrumbClick = (index) => {
    if (index === activeIndex) return;
    const idx = (index + featuredPosts.length) % featuredPosts.length;
    startRotationTimer();
    setIsTransitioning(true);
    setTimeout(() => {
      setActiveIndex(idx);
      setIsTransitioning(false);
      startRotationTimer();
    }, 300);
  };

  if (featuredPosts.length === 0 || activeTags.length > 0) return null;

  const currentPost = featuredPosts[activeIndex];
  const [slug, post] = currentPost;

  return (
    <Box
      className={styles.featuredContainer}
      sx={{ opacity: isResizing || isInitialLoad ? 0 : 1 }}
    >
      <Typography variant="h3" sx={{ mb: 1 }}>
        ~ Featured ~
      </Typography>
      <Typography variant="h6" sx={{ mb: 4 }}>
        ~ Highlights, Curated from the Hourglass ~
      </Typography>

      <BlogPost
        shouldRender={true}
        isFeatured={true}
        slug={slug}
        post={post}
        index={activeIndex}
        handleTagClick={handleTagClick}
        handleBreadcrumbClick={handleBreadcrumbClick}
        isTransitioning={isTransitioning}
      />

      <Box className={styles.breadcrumbContainer}>
        {featuredPosts.map((_, index) => (
          <Box
            key={index}
            onClick={() => handleBreadcrumbClick(index)}
            className={styles.breadcrumb}
            sx={{
              backgroundColor: index === activeIndex ? '#ce0000' : '#ccc',
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FeaturedPosts;
