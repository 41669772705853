import { createTheme } from '@mui/material/styles';

export const getTheme = (isDarkMode) =>
  createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: '#B12AFFFF',
        contrastText: '#fff',
      },
      background: {
        default: isDarkMode ? '#212121' : '#ffffff',
      },
      text: {
        primary: isDarkMode ? '#fff' : '#000',
      },
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
      h1: {
        fontSize: 'clamp(2rem, 5vw, 3rem)',
        fontWeight: 800,
        color: isDarkMode ? '#fff' : '#000',
      },
      h2: {
        fontSize: 'clamp(1.5rem, 4vw, 2rem)',
        fontWeight: 600,
        color: isDarkMode ? '#fff' : '#000',
      },
      h3: {
        fontSize: 'clamp(1.25rem, 3vw, 1.5rem)',
        fontWeight: 500,
        color: isDarkMode ? '#fff' : '#000',
      },
      h4: {
        fontSize: '1.125rem',
        fontWeight: 500,
        color: isDarkMode ? '#fff' : '#000',
      },
      h5: {
        fontSize: '1rem',
        fontWeight: 500,
        color: isDarkMode ? '#fff' : '#000',
      },
      h6: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: isDarkMode ? '#fff' : '#000',
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
        color: isDarkMode ? '#fff' : '#000',
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        color: isDarkMode ? '#fff' : '#000',
      },
      body3: {
        fontSize: '0.75rem',
        fontWeight: 400,
        color: isDarkMode ? '#fff' : '#000',
      },

      button: {
        textTransform: 'none',
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'primary' },
            style: {
              backgroundColor: '#bd55fa',
              color: isDarkMode ? '#000' : '#fff',
              '&:hover': {
                backgroundColor: '#cf7dff',
              },
              '&.Mui-disabled': {
                backgroundColor: '#d3d3d3',
                color: '#9e9e9e',
              },
            },
          },
          {
            props: { variant: 'mono' },
            style: {
              backgroundColor: isDarkMode ? 'white' : 'black',
              color: isDarkMode ? 'black' : 'white',
              '&:hover': {
                backgroundColor: '#8a8a8a',
              },
            },
          },
        ],
      },
    },
  });
