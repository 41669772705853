export default {
  "metadata": {
    "num_words": 557
  },
  "content": [
    {
      "text": "First Snow",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "First Snow",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "It is a beautiful thing, the first morning after a big winter storm. The streets are quiet, save for the occasional rumbling of a distant plow and the faraway shouts of excited children. Trees and houses are draped in white and adorned with dripping icicles. The sidewalks are blanketed in soft white sheets, and the earth itself rests in peaceful slumber. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It is a beautiful thing, the first morning after a big winter storm. The streets are quiet, save for the occasional rumbling of a distant plow and the faraway shouts of excited children. Trees and houses are draped in white and adorned with dripping icicles. The sidewalks are blanketed in soft white sheets, and the earth itself rests in peaceful slumber. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The morning after a storm is always brighter than any other. The sky sheds its grey shroud and dons a brilliant blue, and the sun hangs high and bright. Stepping outside, one is quickly blinded by the dazzling sights. The snowbanks, formed of a billion tiny diamonds, cast the entire street in radiant light. It is a scene unlike any other.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The morning after a storm is always brighter than any other. The sky sheds its grey shroud and dons a brilliant blue, and the sun hangs high and bright. Stepping outside, one is quickly blinded by the dazzling sights. The snowbanks, formed of a billion tiny diamonds, cast the entire street in radiant light. It is a scene unlike any other.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "When the awe subsides and the children are corralled back into the warmth inside, the work begins. Families trudge out of the comfort of their homes, garbed head to toe in winter wear, equipped with shovel in hand. The earth wakes to the scrapes of shovels against pavement, and the spell shatters as the delicate snowdrifts are broken and heaped into rough banks along the road. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "When the awe subsides and the children are corralled back into the warmth inside, the work begins. Families trudge out of the comfort of their homes, garbed head to toe in winter wear, equipped with shovel in hand. The earth wakes to the scrapes of shovels against pavement, and the spell shatters as the delicate snowdrifts are broken and heaped into rough banks along the road. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There\u2019s a sense of solidarity in the air. When the doors open and fathers peer out at the snowdrifts before them. When the shoveling begins and the streets are slowly reclaimed. There\u2019s conversation between neighbors who rarely see one another, offers of a helping hand or a snowblower. The entire neighborhood comes together, for no house is spared by the storm and all must help to clear the streets.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There\u2019s a sense of solidarity in the air. When the doors open and fathers peer out at the snowdrifts before them. When the shoveling begins and the streets are slowly reclaimed. There\u2019s conversation between neighbors who rarely see one another, offers of a helping hand or a snowblower. The entire neighborhood comes together, for no house is spared by the storm and all must help to clear the streets.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "As a child, winter was never truly winter until the snow arrived \u2014 not the light snows, that fall and melt within the hour, but the heavy snows that leave a foot in their wake. The promise of snow would often be enough to keep me peering out through the blinds for the faintest snowflakes. Our home was never cold in the winter, but never did it feel so warm as when the flakes began to fall. The whistles of the radiators steeped our home in coziness, its soft sputters alike the crackling of a warm flame.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "As a child, winter was never truly winter until the snow arrived \u2014 not the light snows, that fall and melt within the hour, but the heavy snows that leave a foot in their wake. The promise of snow would often be enough to keep me peering out through the blinds for the faintest snowflakes. Our home was never cold in the winter, but never did it feel so warm as when the flakes began to fall. The whistles of the radiators steeped our home in coziness, its soft sputters alike the crackling of a warm flame.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Snow breathes new life into winter. When the first snow falls, the entire world pauses to marvel at its beauty. The browns and greys are painted over in brilliant whites, the dirt and dust swept under a soft white blanket. For a brief moment, we forget the mundane that lies just beneath. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Snow breathes new life into winter. When the first snow falls, the entire world pauses to marvel at its beauty. The browns and greys are painted over in brilliant whites, the dirt and dust swept under a soft white blanket. For a brief moment, we forget the mundane that lies just beneath. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The first few days after snowfall are breathtaking. The snowbanks are pristine and untarnished, clean enough to be molded into snow cones and snowballs. But as time passes, the banks inevitably become stained by specks of black and brown. The days melt the snow into muddy rivers, the nights freeze the snowmelt into black ice.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The first few days after snowfall are breathtaking. The snowbanks are pristine and untarnished, clean enough to be molded into snow cones and snowballs. But as time passes, the banks inevitably become stained by specks of black and brown. The days melt the snow into muddy rivers, the nights freeze the snowmelt into black ice.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "What becomes of the snow varies from winter to winter. Colder winters see the snow harden into a tough, slippery crust. Warmer winters see the snow become almost translucent, bleeding out into the streets. But with enough time, the sun will eventually melt the snow. The snowbanks will recede from the sidewalks, shrinking day by day. Within weeks, all that will remain are small patches of dirty snow, and soon after, nothing at all. The greys and browns will reappear; the mundane and unremarkable will return.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "What becomes of the snow varies from winter to winter. Colder winters see the snow harden into a tough, slippery crust. Warmer winters see the snow become almost translucent, bleeding out into the streets. But with enough time, the sun will eventually melt the snow. The snowbanks will recede from the sidewalks, shrinking day by day. Within weeks, all that will remain are small patches of dirty snow, and soon after, nothing at all. The greys and browns will reappear; the mundane and unremarkable will return.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The following snows are never quite as beautiful. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The following snows are never quite as beautiful. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}