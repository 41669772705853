export default {
  "metadata": {
    "num_words": 618
  },
  "content": [
    {
      "text": "Updates from Fog City",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Updates from Fog City",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "I have been a month away from home, and though writing has slowed since the outpour of posts last month, I can assure you that there are plenty of drafts strewn about my desktops, waiting to be worked on. I have two short stories in the works, one to be revised from its first conception years ago, another to be refined for release. The latter is rather inspired by the release of photos from the James Webb telescope, along with a separate piece exploring astronomy more broadly. Another draft in limbo is a personal review of Better Call Saul, a show that I have been eagerly following for years and that is set to conclude in the next few weeks. The last two posts are wild cards, which may or may not be completed, so I will not speak of them as they continue to evolve in my mind.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I have been a month away from home, and though writing has slowed since the outpour of posts last month, I can assure you that there are plenty of drafts strewn about my desktops, waiting to be worked on. I have two short stories in the works, one to be revised from its first conception years ago, another to be refined for release. The latter is rather inspired by the release of photos from the James Webb telescope, along with a separate piece exploring astronomy more broadly. Another draft in limbo is a personal review of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Better Call Saul",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", a show that I have been eagerly following for years and that is set to conclude in the next few weeks. The last two posts are wild cards, which may or may not be completed, so I will not speak of them as they continue to evolve in my mind.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The summer has proved fruitful thus far. I\u2019ve been able to dive deeper into several hobbies that have been held in queue, including photography, writing, lifting, and dance. I\u2019ve been able to explore more of the city and spend time with friends in the broader Bay area. I\u2019ve been able to focus on my growth and learning through my research at Resultid. And I\u2019ve recently picked up a research assistant position at the Stanford Computer and Cognition Lab, working on an exciting self-supervised learning project. It\u2019s been plenty to keep me busy and I am quite content with how my time is spent. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The summer has proved fruitful thus far. I\u2019ve been able to dive deeper into several hobbies that have been held in queue, including photography, writing, lifting, and dance. I\u2019ve been able to explore more of the city and spend time with friends in the broader Bay area. I\u2019ve been able to focus on my growth and learning through my research at Resultid. And I\u2019ve recently picked up a research assistant position at the Stanford Computer and Cognition Lab, working on an exciting self-supervised learning project. It\u2019s been plenty to keep me busy and I am quite content with how my time is spent. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Last week, I took two evenings to stop by the Asian Art Museum and SF MOMA. The Asian Art Museum currently has a three-part exhibit inspired by Carlos Villa, which meshes traditional Filipino styles with modern techniques and invites visitors to reflect upon their own identity. At SF MOMA, I was able to see the new Diego Rivera exhibit, which tied together wonderfully with Pan American Unity, the colossal, ten-piece mural currently on display in the first floor Roberts Family Gallery. Visiting was a meditative experience. I left both galleries brimming with inspiration. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Last week, I took two evenings to stop by the Asian Art Museum and SF MOMA. The Asian Art Museum currently has a three-part exhibit inspired by Carlos Villa, which meshes traditional Filipino styles with modern techniques and invites visitors to reflect upon their own identity. At SF MOMA, I was able to see the new Diego Rivera exhibit, which tied together wonderfully with ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Pan American Unity",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", the colossal, ten-piece mural currently on display in the first floor Roberts Family Gallery. Visiting was a meditative experience. I left both galleries brimming with inspiration. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "San Francisco has remained cool and foggy even despite the heat waves ravaging much of the States and continental Europe. The highs have been in the mid-sixties, the lows dip into the fifties. The fog comes like clockwork, rolling in from the west each evening and dissipating each morning. At times, the fog is barely present, a slight haze that desaturates the skyline. Other times, the fog is so thick that you can feel it condense against your skin as you walk. It is a strange city, with weather unbecoming of summer, but it is beautiful nonetheless. I have been able to capture just some of its beauty with my old, reliable Canon. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "San Francisco has remained cool and foggy even despite the heat waves ravaging much of the States and continental Europe. The highs have been in the mid-sixties, the lows dip into the fifties. The fog comes like clockwork, rolling in from the west each evening and dissipating each morning. At times, the fog is barely present, a slight haze that desaturates the skyline. Other times, the fog is so thick that you can feel it condense against your skin as you walk. It is a strange city, with weather unbecoming of summer, but it is beautiful nonetheless. I have been able to capture just some of its beauty with my old, reliable Canon. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I have been diving into Machine Learning. It is a far more focused exploration than my previous efforts, and I find myself navigating the space with an ease that would\u2019ve been impossible even just months earlier. I\u2019m grateful for my studies in CS 228, 229, and 230 this past year, and for the opportunity to work at a start-up that actively encourages learning. I read papers in my spare time, I train and iterate on various models. It has been a while since I\u2019ve found a subject that challenges me the way AI does.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I have been diving into Machine Learning. It is a far more focused exploration than my previous efforts, and I find myself navigating the space with an ease that would\u2019ve been impossible even just months earlier. I\u2019m grateful for my studies in CS 228, 229, and 230 this past year, and for the opportunity to work at a start-up that actively encourages learning. I read papers in my spare time, I train and iterate on various models. It has been a while since I\u2019ve found a subject that challenges me the way AI does.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "My updates here come to an end. I hope to provide more, shorter updates throughout the year, if not to present a roadmap, to serve as reminders that I am alive and curious. Other writings will arrive when they are ready, when I deem them as such. I am doing much writing, thinking, and exploring. It has been a wonderful summer.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "My updates here come to an end. I hope to provide more, shorter updates throughout the year, if not to present a roadmap, to serve as reminders that I am alive and curious. Other writings will arrive when they are ready, when I deem them as such. I am doing much writing, thinking, and exploring. It has been a wonderful summer.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "E. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "E. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}