export default {
  "metadata": {
    "num_words": 1685
  },
  "content": [
    {
      "text": "Tales from the Rim",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Tales from the Rim",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "Akira, Chris, and Edie hang onto the sides of their drop pod as it soars through the atmosphere of a distant rimworld, leaving in its wake a red streak of molten metal. Moments later, the pod makes impact. The metal shell opens up and, as the dust settles, the trio look out at the wild, untamed land. In a mountainous, temperate forest east of the Camisa Plateau, there is not a soul in sight. Just our protagonists: Ada Akira, a 40-year-old colony settler, Chris Akins, a 42-year-old geologist, and Edie James, a 37-year-old inventor. Nestled in a fertile valley between mountains of slate and marble, with rusted warwalker and warspider hulls scattered about, the three set to work on their new home.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Akira, Chris, and Edie hang onto the sides of their drop pod as it soars through the atmosphere of a distant rimworld, leaving in its wake a red streak of molten metal. Moments later, the pod makes impact. The metal shell opens up and, as the dust settles, the trio look out at the wild, untamed land. In a mountainous, temperate forest east of the Camisa Plateau, there is not a soul in sight. Just our protagonists: Ada Akira, a 40-year-old colony settler, Chris Akins, a 42-year-old geologist, and Edie James, a 37-year-old inventor. Nestled in a fertile valley between mountains of slate and marble, with rusted warwalker and warspider hulls scattered about, the three set to work on their new home.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u273e \u273e \u273e",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I\u2019ve always loved a good story. As a kid who loved his Kindle, I blitzed through the YA hits of the time, diving into their conflicts and characters and falling in love with the worlds within. From smaller, more niche series like the spyscape of Alex Rider and the dystopia of Maximum Ride, to popular hits like Harry Potter and A Song of Ice and Fire, I would lose myself in their worlds. At the time, reading was an escape from the mundanity of childhood life. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I\u2019ve always loved a good story. As a kid who loved his Kindle, I blitzed through the YA hits of the time, diving into their conflicts and characters and falling in love with the worlds within. From smaller, more niche series like the spyscape of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Alex Rider",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " and the dystopia of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Maximum Ride",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", to popular hits like ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Harry Potter",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " and ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Song of Ice and Fire",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", I would lose myself in their worlds. At the time, reading was an escape from the mundanity of childhood life. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I don\u2019t read as much anymore; growing older tends to do that to you. But the stories that I do consume have also matured alongside me. The last novel I read was Life and Fate, nearly eight months ago, and the last one before that was Lolita, a year before. Even now, however, I haven\u2019t lost my love of good stories. I'm a huge fan of HONY. I read the NYT's Modern Love series. My passion for storytelling is a very integral part of the person I am today.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I don\u2019t read as much anymore; growing older tends to do that to you. But the stories that I do consume have also matured alongside me. The last novel I read was ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Life and Fate",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", nearly eight months ago, and the last one before that was ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Lolita",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", a year before. Even now, however, I haven\u2019t lost my love of good stories. I'm a huge fan of HONY. I read the NYT's ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Modern Love",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " series. My passion for storytelling is a very integral part of the person I am today.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u273e \u273e \u273e",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The trio begin their journey on the 6th of Aprimay and soon, they each find their unique role in the colony. Akira is the charming and sociable leader who is equally proficient with trowel and rifle, setting to work on new colony constructions and hunting muffalo herds in the valley at dusk. Edie is the intelligent medic, who sutures wounds while tinkering and experimenting in her free time. Chris is the jack of all trades, who juggles the tasks of mining, sowing, cooking, and cleaning. A quadrum passes, and it is now Jugust. The world begins to open up. A one-armed pirate attacks, armed with just a rusty knife. He is easily dispatched and imprisoned, but strange things begin to happen as they look into his past. His stories reveal a world filled with roving bands of raiders, collectivist outlander unions, and cannibal tribes. Life is peculiar and chaotic on the rim.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The trio begin their journey on the 6th of Aprimay and soon, they each find their unique role in the colony. Akira is the charming and sociable leader who is equally proficient with trowel and rifle, setting to work on new colony constructions and hunting muffalo herds in the valley at dusk. Edie is the intelligent medic, who sutures wounds while tinkering and experimenting in her free time. Chris is the jack of all trades, who juggles the tasks of mining, sowing, cooking, and cleaning. A quadrum passes, and it is now Jugust. The world begins to open up. A one-armed pirate attacks, armed with just a rusty knife. He is easily dispatched and imprisoned, but strange things begin to happen as they look into his past. His stories reveal a world filled with roving bands of raiders, collectivist outlander unions, and cannibal tribes. Life is peculiar and chaotic on the rim.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u273e \u273e \u273e",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Gaming was another outlet for my passions. It was in the open world of Minecraft where I spent many hundreds of hours building castles, churches, and farms, creating stories for myself and the algo-generated ruins around me. It was in the quaint village of Stardew Valley where I found solace, slowly building up a home and becoming friends with the villagers around me. It was in the lawless worlds of Rimworld where I dove into the backstories of my colonists and in the conflict and drama that ensued.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Gaming was another outlet for my passions. It was in the open world of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Minecraft",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " where I spent many hundreds of hours building castles, churches, and farms, creating stories for myself and the algo-generated ruins around me. It was in the quaint village of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Stardew Valley",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " where I found solace, slowly building up a home and becoming friends with the villagers around me. It was in the lawless worlds of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Rimworld",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " where I dove into the backstories of my colonists and in the conflict and drama that ensued.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I loved being lost in a good story. These were worlds where problems could be solved with a trip to the wiki, where actions had consequences that remained confined within the screen. Troubles could be shut off; bad memories could have their save files deleted. Such power is sorely missed in the real world.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I loved being lost in a good story. These were worlds where problems could be solved with a trip to the wiki, where actions had consequences that remained confined within the screen. Troubles could be shut off; bad memories could have their save files deleted. Such power is sorely missed in the real world.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u273e \u273e \u273e",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Akira, Chris, and Edie are firm techno-theist-supremacists. They worship the powers that lay dormant on the planet, the mechanoid mech-hives and archotech intelligences. They believe that to replace flesh with steel is to achieve harmony with the gods of the universe. They also believe that the other inhabitants of the planet are inferior. The hordes of raiders that attack the settlement are seen solely as organs, meat, and leather. Those who fall to our colonists are stripped, searched, and locked into gibbet cages to terrify their comrades, who are then coerced to haul, clean, and sow for the colony. But our colonists also know how to celebrate. Every Aprimay, they gather together for their Spring Jubilee, releasing paper lanterns into the sky. Every Jugust, they convene for a Fiesta, sharing tales and stories among themselves. Every Decembruary, they come together beside the Christmas tree for, well, Christmas. The world is harsh, but our heroes slowly carve out a haven for themselves.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Akira, Chris, and Edie are firm techno-theist-supremacists. They worship the powers that lay dormant on the planet, the mechanoid mech-hives and archotech intelligences. They believe that to replace flesh with steel is to achieve harmony with the gods of the universe. They also believe that the other inhabitants of the planet are inferior. The hordes of raiders that attack the settlement are seen solely as organs, meat, and leather. Those who fall to our colonists are stripped, searched, and locked into gibbet cages to terrify their comrades, who are then coerced to haul, clean, and sow for the colony. But our colonists also know how to celebrate. Every Aprimay, they gather together for their Spring Jubilee, releasing paper lanterns into the sky. Every Jugust, they convene for a Fiesta, sharing tales and stories among themselves. Every Decembruary, they come together beside the Christmas tree for, well, Christmas. The world is harsh, but our heroes slowly carve out a haven for themselves.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u273e \u273e \u273e",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Stories can be dangerous. As someone with a particularly addictive personality, it\u2019s very easy for me to lose myself in the stories I weave in my mind. These tendencies are a double-edged sword; just as powerful as it is for me to obsess over my work and projects, it\u2019s equally as crippling for me to sink hundreds of hours into virtual worlds. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Stories can be dangerous. As someone with a particularly addictive personality, it\u2019s very easy for me to lose myself in the stories I weave in my mind. These tendencies are a double-edged sword; just as powerful as it is for me to obsess over my work and projects, it\u2019s equally as crippling for me to sink hundreds of hours into virtual worlds. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Over winter break, I sank nearly a hundred hours into Minecraft in the span of a week. Over spring break, I spent another hundred hours in Stardew Valley and Hollow Knight. Just this past week, over the span of four days, I\u2019ve spent fifty hours in Rimworld, crafting the story that I\u2019m telling in this post. As I write this on the tail end of my experience, it\u2019s rather terrifying to see just how easily I can be sucked into these narratives. In creating the worlds of my digital domain, I forget the real needs of my reality. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Over winter break, I sank nearly a hundred hours into ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Minecraft",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " in the span of a week. Over spring break, I spent another hundred hours in ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Stardew Valley",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " and ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Hollow Knight",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". Just this past week, over the span of four days, I\u2019ve spent fifty hours in ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Rimworld",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", crafting the story that I\u2019m telling in this post. As I write this on the tail end of my experience, it\u2019s rather terrifying to see just how easily I can be sucked into these narratives. In creating the worlds of my digital domain, I forget the real needs of my reality. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u273e \u273e \u273e",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The high stellarch of a distant, techno-feudal aristocracy reaches out to the colonists. Trials and challenges are sent their way, and our colonists persevere for Chris to be awarded higher and higher noble titles. He becomes first a freeholder, then a yeoman, then a knight, a praetor, a baron. He is rewarded with psychic powers that make him a deadly presence in the battlefield. Akira\u2019s savvy recruiting allows the colony to swell in numbers. As Edie\u2019s research progresses, our colonists are first armed with SMGs, then assault rifles, then charge rifles. Akira names the colony Highgarden, and our heroes becomes well-known all across the rimworld. From time to time, tragedies befall the colony. Levin, a miner, is shot through the chest by a mechanoid lancer, instantly vaporizing his heart. Sid, a barkeep, catches a stray bullet to his head, mangling his brain and rendering him vegetative. Our colonists mourn and bury the fallen, but their work continues. They strategically attack pirate outposts, raid ancient tombs, and trade with nearby factions. The colony grows prosperous.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The high stellarch of a distant, techno-feudal aristocracy reaches out to the colonists. Trials and challenges are sent their way, and our colonists persevere for Chris to be awarded higher and higher noble titles. He becomes first a freeholder, then a yeoman, then a knight, a praetor, a baron. He is rewarded with psychic powers that make him a deadly presence in the battlefield. Akira\u2019s savvy recruiting allows the colony to swell in numbers. As Edie\u2019s research progresses, our colonists are first armed with SMGs, then assault rifles, then charge rifles. Akira names the colony Highgarden, and our heroes becomes well-known all across the rimworld. From time to time, tragedies befall the colony. Levin, a miner, is shot through the chest by a mechanoid lancer, instantly vaporizing his heart. Sid, a barkeep, catches a stray bullet to his head, mangling his brain and rendering him vegetative. Our colonists mourn and bury the fallen, but their work continues. They strategically attack pirate outposts, raid ancient tombs, and trade with nearby factions. The colony grows prosperous.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u273e \u273e \u273e",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Stories are a fundamental part of our human experience. After all, we bond through stories. We share tales of our triumphs, tell ghost stories to one another beside the campfire, and laugh at jokes passed from friend to friend. It is through storytelling that we\u2019re reminded of our shared experience, that perhaps we're not so different after all. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Stories are a fundamental part of our human experience. After all, we bond through stories. We share tales of our triumphs, tell ghost stories to one another beside the campfire, and laugh at jokes passed from friend to friend. It is through storytelling that we\u2019re reminded of our shared experience, that perhaps we're not so different after all. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There are rich sets of stories in every culture, and though the names and places differ, there is much that rhymes between them. In every culture there are tales of love and hate, of greed and charity, sin and virtue. There are the gods we worship and the demons we dread. Even divided by leagues of land and sea, the virtues we teach and the vices we caution against aren\u2019t so different as we might believe.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There are rich sets of stories in every culture, and though the names and places differ, there is much that rhymes between them. In every culture there are tales of love and hate, of greed and charity, sin and virtue. There are the gods we worship and the demons we dread. Even divided by leagues of land and sea, the virtues we teach and the vices we caution against aren\u2019t so different as we might believe.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u273e \u273e \u273e",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Two years goes by, then three, four. The chaos of the rim slowly becomes routine. Traders pass through the colony and our colonists grow wealthy from selling the drugs they grow and organs they \"procure\". Pirate hordes storm the gates but are always encircled and eliminated by Akira and Chris\u2019s strategic command. Mechanoid centipedes and lancers push into our colonists but are grinded down by heavy rifle fire. As Edie continues her work, aided by Nelson and Vector, her new research assistants, she pieces together scraps of knowledge for a starship. She begins work on starflight technology, drawing together schematics for ship hulls, computers, and reactors. Mining and manufacturing efforts intensify to gather resources for the ship. Chris leverages his feudal authority to rally workers for the project. Perhaps there is a life beyond the rimworld after all.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Two years goes by, then three, four. The chaos of the rim slowly becomes routine. Traders pass through the colony and our colonists grow wealthy from selling the drugs they grow and organs they \"procure\". Pirate hordes storm the gates but are always encircled and eliminated by Akira and Chris\u2019s strategic command. Mechanoid centipedes and lancers push into our colonists but are grinded down by heavy rifle fire. As Edie continues her work, aided by Nelson and Vector, her new research assistants, she pieces together scraps of knowledge for a starship. She begins work on starflight technology, drawing together schematics for ship hulls, computers, and reactors. Mining and manufacturing efforts intensify to gather resources for the ship. Chris leverages his feudal authority to rally workers for the project. Perhaps there is a life beyond the rimworld after all.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u273e \u273e \u273e",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "But most important of all are the stories we tell ourselves. Who are we, and who do we wish to become? What will remain of us in life after death, of our legacy? It is these stories that give us the strength to follow our path, the conviction to chase our dreams, even when all the world shuns us. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "But most important of all are the stories we tell ourselves. Who are we, and who do we wish to become? What will remain of us in life after death, of our legacy? It is these stories that give us the strength to follow our path, the conviction to chase our dreams, even when all the world shuns us. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Was it not Napoleon\u2019s obsession with fate that brought him back from Elba to try his hand at war one final time? Was it not Hamilton's fixation with destiny that led him to write over half of the Federalist Papers? Was it not Job's fascination with legacy that led him to found NeXT and execute what would become the greatest comeback in corporate history?",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Was it not Napoleon\u2019s obsession with fate that brought him back from Elba to try his hand at war one final time? Was it not Hamilton's fixation with destiny that led him to write over half of the Federalist Papers? Was it not Job's fascination with legacy that led him to found NeXT and execute what would become the greatest comeback in corporate history?",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "If we live our lives well and with conviction, perhaps we'll leave behind a story worth telling. But there is solace in knowing that, if not, at least we'll leave behind a burning memory. And it is not so hard to leave a kind memory. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "If we live our lives well and with conviction, perhaps we'll leave behind a story worth telling. But there is solace in knowing that, if not, at least we'll leave behind a burning memory. And it is not so hard to leave a kind memory. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u273e \u273e \u273e",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u273e",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "And, at last, we\u2019ve reached the current state of the colony. Akira is the leader, taking charge in combat and diplomacy. Chris leads the construction efforts, preparing the colony for starflight. Edie continues to tend her fellow colonists, installing bionic limbs and cybernetics. But danger looms on the horizon. Toxic fallout from a distant warzone settles upon the colony. Mechanoid encampments threaten the defenses with mortar fire. What will become of our colonists, of Akira, Chris, Edie, and the many more who have joined? Will the next raid be their last, overwhelming their defenses and razing Highgarden to the ground? Or will they persevere, build a ship to the stars, and escape the harsh world they\u2019ve learned to call home? ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "And, at last, we\u2019ve reached the current state of the colony. Akira is the leader, taking charge in combat and diplomacy. Chris leads the construction efforts, preparing the colony for starflight. Edie continues to tend her fellow colonists, installing bionic limbs and cybernetics. But danger looms on the horizon. Toxic fallout from a distant warzone settles upon the colony. Mechanoid encampments threaten the defenses with mortar fire. What will become of our colonists, of Akira, Chris, Edie, and the many more who have joined? Will the next raid be their last, overwhelming their defenses and razing Highgarden to the ground? Or will they persevere, build a ship to the stars, and escape the harsh world they\u2019ve learned to call home? ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We\u2019ll never know for sure.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We\u2019ll never know for sure.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}