export default [
  'Creative Writing',
  'Journals',
  'Poems',
  'Projects',
  'Reflections',
  'Reviews',
  'Stories',
  'Updates',
  'Vignettes',
];
