import { MobileContext } from '@contexts/MobileContext';
import { Box, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { searchContent } from '@utils/searchUtils';
import React, { useContext, useEffect, useState } from 'react';

import styles from '../Navbar.module.css';
import SearchResults from './SearchResults';

const SearchOverlay = ({ isDarkMode, isClosing, handleClose }) => {
  const isMobile = useContext(MobileContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [lastSearchQuery, setLastSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [renderedItems, setRenderedItems] = useState([]);

  const executeSearch = () => {
    setIsSearching(true);
    setLastSearchQuery(searchQuery);
    const results = searchContent(searchQuery);
    setSearchResults(results);
    setRenderedItems([]);
    setIsSearching(false);
    setHasSearched(true);
  };

  useEffect(() => {
    if (isClosing) {
      setIsSearching(false);
      setRenderedItems([]);
      setHasSearched(false);
    }
  }, [isClosing]);

  return (
    <>
      <Box
        className={`${isMobile ? styles.searchOverlayMobile : styles.searchOverlayDesktop} ${isClosing ? styles.closingSearchOverlay : ''}`}
        sx={{
          backgroundColor: isDarkMode ? '#212121' : 'white',
        }}
        onClick={handleClose}
      />
      {!isClosing && (
        <Box
          className={
            isMobile
              ? styles.searchContainerMobile
              : styles.searchContainerDesktop
          }
        >
          <TextField
            value={searchQuery}
            variant="standard"
            placeholder="Search"
            autoFocus
            className={styles.searchBar}
            InputProps={{
              style: {
                fontSize: '2rem',
                textAlign: 'center',
              },
            }}
            inputProps={{
              style: {
                textAlign: 'center',
              },
            }}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                executeSearch();
              }
            }}
          />
          {isSearching && (
            <Box
              className={`${styles.searchResultsPending} ${styles.searchPending}`}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <CircularProgress size={20} />
              </Box>
            </Box>
          )}
          {!isSearching && hasSearched && searchResults.length === 0 && (
            <Box className={styles.searchResults}>
              <Box className={styles.searchResultsHeader}>
                <Typography variant="h5">
                  No results found for "{lastSearchQuery}"
                </Typography>
              </Box>
            </Box>
          )}
          {!isSearching && hasSearched && searchResults.length > 0 && (
            <SearchResults
              isSearching={isSearching}
              hasSearched={hasSearched}
              searchResults={searchResults}
              lastSearchQuery={lastSearchQuery}
              renderedItems={renderedItems}
              setRenderedItems={setRenderedItems}
              handleClose={handleClose}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default SearchOverlay;
