export default {
  "metadata": {
    "num_words": 687
  },
  "content": [
    {
      "text": "not quite a goodbye",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "not quite a goodbye",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "A post-graduation update, first shared via email through my mailing list, Timshel. A brief retrospective on senior year, and initial answers to the question: what\u2019s next?",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "A post-graduation update, first shared via email through my mailing list, ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Timshel",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": "https://tinyletter.com/eyw/"
        },
        {
          "text": ". A brief retrospective on senior year, and initial answers to the question: what\u2019s next?",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Dear friend,",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Dear friend,",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Just over two weeks ago, I graduated from Stanford with a B.S. degree in Computer Science and a minor in Mathematics. Walking at commencement marked the end to my undergraduate career, the end of four years of this fever dream called Stanford. And though I won\u2019t be saying goodbye for good, things will change substantially this coming year. This Summer, I\u2019ll be working full time at Hippocratic AI, at our office right off campus (just by Verve coffee in Palo Alto, come say hi!). In the Fall, I\u2019ll be studying part-time at Stanford, living in Munger and finishing up the remainder of my Master\u2019s degree. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Just over two weeks ago, I graduated from Stanford with a B.S. degree in Computer Science and a minor in Mathematics. Walking at commencement marked the end to my undergraduate career, the end of four years of this fever dream called Stanford. And though I won\u2019t be saying goodbye for good, things will change substantially this coming year. This Summer, I\u2019ll be working full time at Hippocratic AI, at our office right off campus (just by Verve coffee in Palo Alto, come say hi!). In the Fall, I\u2019ll be studying part-time at Stanford, living in Munger and finishing up the remainder of my Master\u2019s degree. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Summer brings with it a brief reprieve. Without classes, it\u2019s been much easier to find a semblance of work-life balance, even with the new responsibilities I\u2019m picking up at work. I\u2019m in the office from just before 9 AM to just after 6 PM. I spend my evenings working out in the gym, writing journals and blog posts, and ramping up on projects archived from seasons ago. It\u2019s quiet on campus. I am surrounded by a silence I didn\u2019t know I needed, and it\u2019s a welcome change from the deafening roar of normal campus life.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Summer brings with it a brief reprieve. Without classes, it\u2019s been much easier to find a semblance of work-life balance, even with the new responsibilities I\u2019m picking up at work. I\u2019m in the office from just before 9 AM to just after 6 PM. I spend my evenings working out in the gym, writing journals and blog posts, and ramping up on projects archived from seasons ago. It\u2019s quiet on campus. I am surrounded by a silence I didn\u2019t know I needed, and it\u2019s a welcome change from the deafening roar of normal campus life.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "So, what\u2019s next? ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "So, what\u2019s next? ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "In the short term, not too much. I\u2019ll just be working and doing my best to find balance during this transitional period. I\u2019m reclaiming time from my day and finding ways to incorporate more intentional learning into my work. I\u2019m discovering the limits of where my work-life boundaries will lie long term. I\u2019ve been splitting my time between Stanford and San Francisco. If you\u2019re around either of those areas, shoot me a message, and we\u2019ll find time to hang out.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "In the short term, not too much. I\u2019ll just be working and doing my best to find balance during this transitional period. I\u2019m reclaiming time from my day and finding ways to incorporate more intentional learning into my work. I\u2019m discovering the limits of where my work-life boundaries will lie long term. I\u2019ve been splitting my time between Stanford and San Francisco. If you\u2019re around either of those areas, shoot me a message, and we\u2019ll find time to hang out.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "In the long term, there\u2019s a lot to look forward to. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "In the long term, there\u2019s a lot to look forward to. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "On the professional front, there\u2019s plenty of learning on the horizon. I am constantly growing, not only as an engineer, but as a leader and future entrepreneur. I\u2019ll make the most of my time at Hippocratic, but I refuse to get too complacent. I\u2019m constantly looking for opportunities as new innovations come.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "On the professional front, there\u2019s plenty of learning on the horizon. I am constantly growing, not only as an engineer, but as a leader and future entrepreneur. I\u2019ll make the most of my time at Hippocratic, but I refuse to get too complacent. I\u2019m constantly looking for opportunities as new innovations come.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "On the artistic front, I\u2019m looking into creating short-form content, hopefully combining my interests in writing, video editing, storytelling, and machine learning to explore new mediums for expression. I\u2019m looking to bring my work to a wider audience, and to iterate as feedback comes. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "On the artistic front, I\u2019m looking into creating short-form content, hopefully combining my interests in writing, video editing, storytelling, and machine learning to explore new mediums for expression. I\u2019m looking to bring my work to a wider audience, and to iterate as feedback comes. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "On the personal front, I\u2019m hoping to build habits for the life ahead. I hope to be a better friend, to be more intentional about reaching out and strengthening friendships cultivated over these past four years. If you\u2019re reading this: hold me to my word.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "On the personal front, I\u2019m hoping to build habits for the life ahead. I hope to be a better friend, to be more intentional about reaching out and strengthening friendships cultivated over these past four years. If you\u2019re reading this: ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "hold me to my word",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ".",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Onto other news. I\u2019m sunsetting the meal Calendly and polaroid wall. Thank you to everybody who humored me and booked a meal, or gave me five minutes of your time for a polaroid. It truly meant a lot to me. Time flies; so much of senior year breezed by before me, the time escaping from my grasp. but looking back at the two stacks of polaroids on my desk, neatly stacked and secured by three rubber bands each, I can recall the happiness each of you brought me and laugh at the creative license some of you took in your doodles. Thank you, truly.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Onto other news. I\u2019m sunsetting the meal Calendly and polaroid wall. Thank you to everybody who humored me and booked a meal, or gave me five minutes of your time for a polaroid. It truly meant a lot to me. Time flies; so much of senior year breezed by before me, the time escaping from my grasp. but looking back at the two stacks of polaroids on my desk, neatly stacked and secured by three rubber bands each, I can recall the happiness each of you brought me and laugh at the creative license some of you took in your doodles. Thank you, truly.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Keep an eye out on the blog. It\u2019ll see some updates in the next two months. New posts, projects, and reflections are coming. Summer is usually a great time for writing.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Keep an eye out on the ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "blog",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": "https://edenincorporated.com/eden"
        },
        {
          "text": ". It\u2019ll see some updates in the next two months. New posts, projects, and reflections are coming. Summer is usually a great time for writing.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I\u2019ll end my letter here. It\u2019s the evening of July 4th, and Kaitlin and I just had dinner at Lakeside. The night is young, and the cool summer night is inviting. Perhaps I\u2019ll take a walk around the now-marshy lake or climb MemAud at sunset. For the first time in a while, I find myself at the helm, the world laid out before me. In front of me, a blank canvas, clear as freshly fallen snow. What will I choose to draw? ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I\u2019ll end my letter here. It\u2019s the evening of July 4th, and Kaitlin and I just had dinner at Lakeside. The night is young, and the cool summer night is inviting. Perhaps I\u2019ll take a walk around the now-marshy lake or climb MemAud at sunset. For the first time in a while, I find myself at the helm, the world laid out before me. In front of me, a blank canvas, clear as freshly fallen snow. What will I choose to draw? ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "With love,",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "With love,",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Eden",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Eden",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}