export default {
  "metadata": {
    "num_words": 297
  },
  "content": [
    {
      "text": "Eden, Built Anew",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Eden, Built Anew",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "The blog you are currently visiting was built over the course of these past two months, with work beginning in Late November and stretching into the New Year. The end result is a static site compiled from JavaScript and deployed via GitHub Pages onto my personal domain.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The blog you are currently visiting was built over the course of these past two months, with work beginning in Late November and stretching into the New Year. The end result is a static site compiled from JavaScript and deployed via GitHub Pages onto my personal domain.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I first launched edenincorporated during the summer of 2019, right before leaving for my frosh year at Stanford. At the time, I had little development experience. The first iteration of the site was built via Squarespace.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I first launched ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "edenincorporated ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "during the summer of 2019, right before leaving for my frosh year at Stanford. At the time, I had little development experience. The first iteration of the site was built via Squarespace.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Since then, my skills have grown, and the type of content I wish to explore has also expanded in scope. I decided, at the end of this past year, that it was time to start anew. During the build, I made a conscious effort to inherit design elements from my old blog. In that sense, there is continuity to be found.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Since then, my skills have grown, and the type of content I wish to explore has also expanded in scope. I decided, at the end of this past year, that it was time to start anew. During the build, I made a conscious effort to inherit design elements from my old blog. In that sense, there is continuity to be found.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Over the years, this blog has remained a side project of mine. I have made little effort to grow my audience beyond friends who accidentally stumble upon it. In the New Year, I'd like to be more intentional with my art and writing. I hope to maintain the site as a personal garden of sorts.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Over the years, this blog has remained a side project of mine. I have made little effort to grow my audience beyond friends who accidentally stumble upon it. In the New Year, I'd like to be more intentional with my art and writing. I hope to maintain the site as a personal garden of sorts.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "New to the blog? Here are a few posts that I think would be a great place to start. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "New to the blog? Here are a few posts that I think would be a great place to start. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Baccalaureate. A speech I wrote for Stanford's 2023 Baccalaureate ceremony.",
      "alignment": null,
      "style": "List Paragraph",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Baccalaureate.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": "https://edenincorporated.com/blog/baccalaureate"
        },
        {
          "text": " A speech I wrote for Stanford's 2023 Baccalaureate ceremony.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Stories from a World Forgotten. A collection of family stories translated from our nuclear Chinglish.",
      "alignment": null,
      "style": "List Paragraph",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Stories from a World Forgotten.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": "https://edenincorporated.com/blog/stories-from-a-world-forgotten"
        },
        {
          "text": " A collection of family stories translated from our nuclear Chinglish.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "At the End of Time. A series of thoughts and musings from my listening of Leland Kirby's Everywhere at the End of Time.",
      "alignment": null,
      "style": "List Paragraph",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "At the End of Time.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": "https://edenincorporated.com/blog/at-the-end-of-time"
        },
        {
          "text": " A series of thoughts and musings from my listening of Leland Kirby's ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Everywhere at the End of Time",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ".",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Tales from the Rim. A tale of three colonists stranded on a distant world, with musings on stories weaved throughout.",
      "alignment": null,
      "style": "List Paragraph",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Tales from the Rim.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": "https://edenincorporated.com/blog/tales-from-the-rim"
        },
        {
          "text": " A tale of three colonists stranded on a distant world, with musings on stories weaved throughout.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Welcome to the garden. Come, stay a while.  ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Welcome to the garden. Come, stay a while.  ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}