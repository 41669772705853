export default {
  "metadata": {
    "num_words": 2471
  },
  "content": [
    {
      "text": "Box Making",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Box Making",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "My father\u2019s woodworking shop has always been a mysterious place. It is a cramped space, twenty feet long by six and a half feet wide, and it is always clouded with fine wood dust from all the sawing and sanding that happens within. It is where my father disappears in the evenings, the source loud whirs and screeches that can be heard from all throughout the house. It is also a wondrous place. When I was younger, I\u2019d venture into the shop, imagining that I was adept enough to shape and craft, pretending that I knew the properties of the wood planks that rested along the walls. As I grew up, the shop shared with us its bounty. A heavy oak desk that forms the centerpiece of my room, a music-stand that I used to practice violin over the years. Shelves, trellises, chairs, chests, and so much more. My father\u2019s works reflect his growth as a hobbyist woodworker. His early works are practical, unadventurous. His more recent works experiment with ingenious contraptions and intricate designs. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "My father\u2019s woodworking shop has always been a mysterious place. It is a cramped space, twenty feet long by six and a half feet wide, and it is always clouded with fine wood dust from all the sawing and sanding that happens within. It is where my father disappears in the evenings, the source loud whirs and screeches that can be heard from all throughout the house. It is also a wondrous place. When I was younger, I\u2019d venture into the shop, imagining that I was adept enough to shape and craft, pretending that I knew the properties of the wood planks that rested along the walls. As I grew up, the shop shared with us its bounty. A heavy oak desk that forms the centerpiece of my room, a music-stand that I used to practice violin over the years. Shelves, trellises, chairs, chests, and so much more. My father\u2019s works reflect his growth as a hobbyist woodworker. His early works are practical, unadventurous. His more recent works experiment with ingenious contraptions and intricate designs. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Over the past half year, my father and I talked on and off about me taking some time to learn my way around the tools in the basement, to experiment with some woodworking and gain a greater appreciation for this hobby of his. When I returned home from my junior year at Stanford, we decided to make this a reality. We settled on a simple starting project: a box. My father thinks it\u2019s a great starting point to learn: a box appears simple and unassuming, but there are so many considerations and techniques involved to making a great box. And so, he invites me into his workshop. He will walk me through the design considerations, methods, and procedures. I will learn to use the tools and make the box with my own hands. We begin our box making journey.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Over the past half year, my father and I talked on and off about me taking some time to learn my way around the tools in the basement, to experiment with some woodworking and gain a greater appreciation for this hobby of his. When I returned home from my junior year at Stanford, we decided to make this a reality. We settled on a simple starting project: a box. My father thinks it\u2019s a great starting point to learn: a box appears simple and unassuming, but there are so many considerations and techniques involved to making a great box. And so, he invites me into his workshop. He will walk me through the design considerations, methods, and procedures. I will learn to use the tools and make the box with my own hands. We begin our box making journey.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Day 1. We begin drafting the design. Hinges or no hinges? Square or rectangular? Oak or maple? My father guides me through the various design considerations. We settle on a design of five by eight inches for the top and bottom, with a height of three inches for the sides. The lid will be three-quarters inch tall, and the walls will be a half inch, allowing for the quarter inch hinges with an eighth inch of buffer space on either side. The walls will be joined at 45-degree angles, with reinforcements in the corners. The top and bottom will be set into the sides, inserted into routed grooves that will allow ample room for the wood to expand and contract. The top of the box will contain an inlaid design it will cover the side to form a near seamless lid. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Day 1. We begin drafting the design. Hinges or no hinges? Square or rectangular? Oak or maple? My father guides me through the various design considerations. We settle on a design of five by eight inches for the top and bottom, with a height of three inches for the sides. The lid will be three-quarters inch tall, and the walls will be a half inch, allowing for the quarter inch hinges with an eighth inch of buffer space on either side. The walls will be joined at 45-degree angles, with reinforcements in the corners. The top and bottom will be set into the sides, inserted into routed grooves that will allow ample room for the wood to expand and contract. The top of the box will contain an inlaid design it will cover the side to form a near seamless lid. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Selecting the wood is the next step. We have plenty of oak, but given our inlaid design, we decide that a more subtle maple would be more fitting. We settle on a large slab of three-quarter-inch thick maple, once part of an old desk. We sketch out the steps for the next few days. First, we will use the table saw to cut out a three-inch plank. Then, we will trim off a quarter-inch to form the half-inch walls. We leave execution for the next day.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Selecting the wood is the next step. We have plenty of oak, but given our inlaid design, we decide that a more subtle maple would be more fitting. We settle on a large slab of three-quarter-inch thick maple, once part of an old desk. We sketch out the steps for the next few days. First, we will use the table saw to cut out a three-inch plank. Then, we will trim off a quarter-inch to form the half-inch walls. We leave execution for the next day.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Our starting plank, from which we will cut to form the box sides. We will use the bottom plank of the joined piece.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Our starting plank, from which we will cut to form the box sides. We will use the bottom plank of the joined piece.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Day 2. We begin to prepare the wood. We set up a feather board on the table saw, adjust the sawblade, and slide the rail to guide the plank. The first cut trims off the three-inch board. The second and third cuts thin the plank to just over a half inch. My first cut is slow, but the wood remains largely unburnt, with enough margin to sand off any marks. The second and third cuts are smoother, though small shifts leave the wood with a faint ridge in the middle. We pass the wood through a sander my father designed, specifically made to sand and even out freshly cut planks. After cutting and sanding, the maple plank has been roughly leveled, and all additional work will be done by hand. It is a beautiful plank. We leave it on its edge, so that any absorbed moisture won\u2019t warp the plank. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Day 2. We begin to prepare the wood. We set up a feather board on the table saw, adjust the sawblade, and slide the rail to guide the plank. The first cut trims off the three-inch board. The second and third cuts thin the plank to just over a half inch. My first cut is slow, but the wood remains largely unburnt, with enough margin to sand off any marks. The second and third cuts are smoother, though small shifts leave the wood with a faint ridge in the middle. We pass the wood through a sander my father designed, specifically made to sand and even out freshly cut planks. After cutting and sanding, the maple plank has been roughly leveled, and all additional work will be done by hand. It is a beautiful plank. We leave it on its edge, so that any absorbed moisture won\u2019t warp the plank. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The trimmed piece (top right), having been cut out from the plank using the table saw.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The trimmed piece (top right), having been cut out from the plank using the table saw.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The plank atop the sander that will sand the piece to exactly a half-inch thickness. ",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The plank atop the sander that will sand the piece to exactly a half-inch thickness. ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Day 3: Today, we began sanding. The belt sander that we used yesterday helped to even out the wood, removing most of the large imperfections from the table saw. But the sander leaves small ridges in the wood that must be sanded out. We use a hand sander with 150 and 220 grit, sanding for several passes until the wood feels smooth to the touch. We only sand the inside of the box, as it won\u2019t be easily finished after putting together the box walls. In addition to sanding, we make adjustments to the box dimensions and lid. Whereas the original design had dimensions of 5x8 inches, we have enough material to expand the box to 6x8.5. The lid of the box will contain an inlay, and in the center, we\u2019ll use the scroll saw to create a design with purple heart and a deep red hardwood. I am covered in sawdust when I leave the workshop. Sanding is messy business.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Day 3: Today, we began sanding. The belt sander that we used yesterday helped to even out the wood, removing most of the large imperfections from the table saw. But the sander leaves small ridges in the wood that must be sanded out. We use a hand sander with 150 and 220 grit, sanding for several passes until the wood feels smooth to the touch. We only sand the inside of the box, as it won\u2019t be easily finished after putting together the box walls. In addition to sanding, we make adjustments to the box dimensions and lid. Whereas the original design had dimensions of 5x8 inches, we have enough material to expand the box to 6x8.5. The lid of the box will contain an inlay, and in the center, we\u2019ll use the scroll saw to create a design with purple heart and a deep red hardwood. I am covered in sawdust when I leave the workshop. Sanding is messy business.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Day 4: We think more about the design. We look through sketches curated from my father\u2019s many woodworking books. The one that stands out to me most is a landscape with mountains and a setting sun. Though we initially considered using the scroll saw to carve out shapes from solid wood, my father suggests that marquetry techniques work best. We can use natural wood veneer to form the mountains and dyed wood veneer for the setting sun. We look through samples and settle on a collection of veneer pieces, arranged in a gradient. I sketch the initial pattern. Tomorrow, we aim to construct the box and carve out the marquetry pieces. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Day 4: We think more about the design. We look through sketches curated from my father\u2019s many woodworking books. The one that stands out to me most is a landscape with mountains and a setting sun. Though we initially considered using the scroll saw to carve out shapes from solid wood, my father suggests that marquetry techniques work best. We can use natural wood veneer to form the mountains and dyed wood veneer for the setting sun. We look through samples and settle on a collection of veneer pieces, arranged in a gradient. I sketch the initial pattern. Tomorrow, we aim to construct the box and carve out the marquetry pieces. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Day 5: The day starts early. We get down and begin working on the walls. We first begin by making two grooves for the top and bottom of the box to slide in. We use the table saw to carve out a three-eighth inch wide and quarter-inch deep groove on both sides of the plank. Then, we route out a five-eighth inch wide- groove to fit in the inlay. The inlay itself must be cut from a larger piece, shaving off two sixteenth-inch strip from the sides that are joined to form a larger piece. We glue in the inlay, wait for it to set, then sand out any excess material until it is flush against the wall. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Day 5: The day starts early. We get down and begin working on the walls. We first begin by making two grooves for the top and bottom of the box to slide in. We use the table saw to carve out a three-eighth inch wide and quarter-inch deep groove on both sides of the plank. Then, we route out a five-eighth inch wide- groove to fit in the inlay. The inlay itself must be cut from a larger piece, shaving off two sixteenth-inch strip from the sides that are joined to form a larger piece. We glue in the inlay, wait for it to set, then sand out any excess material until it is flush against the wall. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The inside wall of the plank, containing the two grooves that will contain the top and bottom.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The inside wall of the plank, containing the two grooves that will contain the top and bottom.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The outside wall, with a small routed groove that will contain the inlay.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The outside wall, with a small routed groove that will contain the inlay.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The inserted inlay, before being passed through the sander.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The inserted inlay, before being passed through the sander.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Next begins the cutting. We swap out the sawblade for a larger one, then rotate it such that it is 45\u00ba. We make all the cuts, forming the walls and leaving some excess at the end. After a bit of planing to fine-tune the corners, the walls fit together perfectly. We move on to the bottom piece, which we create from a three-eighth inch piece of maple. It is slightly curved, so we set the curved side down and cut out enough material to form a tendon that can insert into the grooves of the wall. With the bottom completed, we can perform a dry assemble. We plane out any excess material from the tendon, then insert it into the grooves. We slide in each wall until the four sides are flush against each other. There is very little error. The inside of the box measures 5x7.5 inches, just as we calculated.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Next begins the cutting. We swap out the sawblade for a larger one, then rotate it such that it is 45\u00ba. We make all the cuts, forming the walls and leaving some excess at the end. After a bit of planing to fine-tune the corners, the walls fit together perfectly. We move on to the bottom piece, which we create from a three-eighth inch piece of maple. It is slightly curved, so we set the curved side down and cut out enough material to form a tendon that can insert into the grooves of the wall. With the bottom completed, we can perform a dry assemble. We plane out any excess material from the tendon, then insert it into the grooves. We slide in each wall until the four sides are flush against each other. There is very little error. The inside of the box measures 5x7.5 inches, just as we calculated.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The cut pieces, laid out. The piece on the bottom is excess material.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The cut pieces, laid out. The piece on the bottom is excess material.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Our first dry assembly. ",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Our first dry assembly. ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The dry assembly, showing the bottom of the box.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The dry assembly, showing the bottom of the box.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "With the walls and bottom together, we move onto the top. The top piece is straightforward, but the marquetry design is the most time-consuming part. The veneer pieces are delicate, and the scroll saw can be temperamental. We begin by sketching the design onto a thin piece of paper, which is then transposed onto the individual wooden pieces. The veneer pieces are joined one at a time, laid atop one another then cut at an angle such that they fit together seamlessly. We work from the left to the right, first the left-most mountains, then the right-most, until at last, the sun and sky are joined together with the mountains to complete the landscape. The day ends with us gluing the entire piece onto plywood, with another piece in the back to balance out the stresses. Tomorrow, we hope to complete the box.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "With the walls and bottom together, we move onto the top. The top piece is straightforward, but the marquetry design is the most time-consuming part. The veneer pieces are delicate, and the scroll saw can be temperamental. We begin by sketching the design onto a thin piece of paper, which is then transposed onto the individual wooden pieces. The veneer pieces are joined one at a time, laid atop one another then cut at an angle such that they fit together seamlessly. We work from the left to the right, first the left-most mountains, then the right-most, until at last, the sun and sky are joined together with the mountains to complete the landscape. The day ends with us gluing the entire piece onto plywood, with another piece in the back to balance out the stresses. Tomorrow, we hope to complete the box.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The marquetry setup, with the wood veneer pieces on the right.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The marquetry setup, with the wood veneer pieces on the right.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The cutting process, with the veneer pieces atop the scroll saw. ",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The cutting process, with the veneer pieces atop the scroll saw. ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The glued marquetry piece in its clamps.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The glued marquetry piece in its clamps.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Day 6: The day begins with us returning to the marquetry piece from the evening before. We trace out its dimensions then make the cuts until it is rectangular, with no excess material on all sides. We cut a small tendon in its side so that it may fit in a larger frame that will form the top of the box. The frame itself requires several cuts. First, we cut an inch-wide piece from a larger plank and saw it down to its proper thickness of a nine-sixteenth inch. Then, we cut a small groove for the second inlay, glue it in, and sand until it is flush against the wood. We return to the table saw to cut a groove for the center marquetry piece, and when it is complete, we use a rig to cut the piece into four strips at 45\u00ba and glue it all together to form the top. We glue and clamp the top frame together and rest as it sets.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Day 6: The day begins with us returning to the marquetry piece from the evening before. We trace out its dimensions then make the cuts until it is rectangular, with no excess material on all sides. We cut a small tendon in its side so that it may fit in a larger frame that will form the top of the box. The frame itself requires several cuts. First, we cut an inch-wide piece from a larger plank and saw it down to its proper thickness of a nine-sixteenth inch. Then, we cut a small groove for the second inlay, glue it in, and sand until it is flush against the wood. We return to the table saw to cut a groove for the center marquetry piece, and when it is complete, we use a rig to cut the piece into four strips at 45\u00ba and glue it all together to form the top. We glue and clamp the top frame together and rest as it sets.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The finished, untrimmed marquetry.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The finished, untrimmed marquetry.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The assembled marquetry glued and clamped in its frame.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The assembled marquetry glued and clamped in its frame.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The top and bottom of the box, side by side, prior to assembly.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The top and bottom of the box, side by side, prior to assembly.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We sand the top of the box until it is silky smooth then cover it in a layer of masking tape to protect it. Only then do we begin preparing the top for assembly. We cut grooves along the sides of the top piece until it can slide into the walls, and after a few dry assemblies and our finishing touches, we glue it all together and add clamps to hold all the pieces in place. After drying, the box is then cut in the corners for us to insert splines, which we glue in for additional support. In the evening, we saw off the extra pieces and use the belt sander to smooth all the sides. Then, we move onto using the smaller sander, working our way up to 220 grit paper. With the overall box structure complete, we call it a day. The box is almost together.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We sand the top of the box until it is silky smooth then cover it in a layer of masking tape to protect it. Only then do we begin preparing the top for assembly. We cut grooves along the sides of the top piece until it can slide into the walls, and after a few dry assemblies and our finishing touches, we glue it all together and add clamps to hold all the pieces in place. After drying, the box is then cut in the corners for us to insert splines, which we glue in for additional support. In the evening, we saw off the extra pieces and use the belt sander to smooth all the sides. Then, we move onto using the smaller sander, working our way up to 220 grit paper. With the overall box structure complete, we call it a day. The box is almost together.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The glued and clamped box, with top and bottom assembled together.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The glued and clamped box, with top and bottom assembled together.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The splines, which will offer additional structural support to the box.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The splines, which will offer additional structural support to the box.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "After trimming and sanding.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "After trimming and sanding.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Day 7: We\u2019re ready to move onto the final stages: cutting out the top, installing the hinges and lock, and sanding and finishing to completion. We begin by preparing to cut the box apart. We align the saw and raise the blade, then run the box through the saw four times until it comes apart into the base and lid. It is not a perfect fit, as differences in pressure applied during the cut lead to small inconsistencies. We sand the edge until the lid and box fit snugly. Then, we sand the sharp corners so that they are smooth to the touch. We leave the box at this stage. Tomorrow, we will begin installing the lock and hinges.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Day 7: We\u2019re ready to move onto the final stages: cutting out the top, installing the hinges and lock, and sanding and finishing to completion. We begin by preparing to cut the box apart. We align the saw and raise the blade, then run the box through the saw four times until it comes apart into the base and lid. It is not a perfect fit, as differences in pressure applied during the cut lead to small inconsistencies. We sand the edge until the lid and box fit snugly. Then, we sand the sharp corners so that they are smooth to the touch. We leave the box at this stage. Tomorrow, we will begin installing the lock and hinges.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The cut top and bottom piece.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The cut top and bottom piece.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Top and bottom, with the hinges aside them.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Top and bottom, with the hinges aside them.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Day 8: We set to work on the hinges. We set a router guide upon the box and route a slim groove into the base and top. Then, we cut a deeper groove into the same guide such that the hinge supports can slide into the base. It is a long task, with lots of fine adjustments. We use wood carving tools to widen the grooves to fit the hinge pieces, then we slowly chisel out the box bit by bit until the groove is deep enough to fit the supports and screw the hinges into the maple wood. The base and lid are just a bit misaligned, but the error is fixed with some sanding. All that remains is the lock, which we aim to complete tomorrow. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Day 8: We set to work on the hinges. We set a router guide upon the box and route a slim groove into the base and top. Then, we cut a deeper groove into the same guide such that the hinge supports can slide into the base. It is a long task, with lots of fine adjustments. We use wood carving tools to widen the grooves to fit the hinge pieces, then we slowly chisel out the box bit by bit until the groove is deep enough to fit the supports and screw the hinges into the maple wood. The base and lid are just a bit misaligned, but the error is fixed with some sanding. All that remains is the lock, which we aim to complete tomorrow. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The router template set atop the box. ",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The router template set atop the box. ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The routed grooves, with hinges in place.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The routed grooves, with hinges in place.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The box, with hinges installed.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The box, with hinges installed.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Day 9: We work to install the last bit of hardware: the lock. We route out a deep groove for the locking mechanism and shallow groove for the cover plate on both the base and top of the box. It is a long process, and most of the time is spent measuring distances and aligning the router bit. We chisel and file out all remaining grooves, including a small keyhole through which the key fits perfectly. We end with all the pieces in place, saving the final installation for tomorrow.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Day 9: We work to install the last bit of hardware: the lock. We route out a deep groove for the locking mechanism and shallow groove for the cover plate on both the base and top of the box. It is a long process, and most of the time is spent measuring distances and aligning the router bit. We chisel and file out all remaining grooves, including a small keyhole through which the key fits perfectly. We end with all the pieces in place, saving the final installation for tomorrow.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The installed lock with a rough keyhole in place.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The installed lock with a rough keyhole in place.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Day 10: We assemble the lock and screw in the last of the hardware. All the work that remains is sanding and finishing the piece. Beginning with 100 grit sandpaper, and slowly working our way up to 150, 220, 320, 400, 600, and finally, 1000, we sand every face and edge of the box until it is pleasant to touch and carries a faint glow in the light. When all is done, we use mineral spirit to clean out the sawdust. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Day 10: We assemble the lock and screw in the last of the hardware. All the work that remains is sanding and finishing the piece. Beginning with 100 grit sandpaper, and slowly working our way up to 150, 220, 320, 400, 600, and finally, 1000, we sand every face and edge of the box until it is pleasant to touch and carries a faint glow in the light. When all is done, we use mineral spirit to clean out the sawdust. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The finished box, without its lacquer finish. ",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The finished box, without its lacquer finish. ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Day 11: With the box sanded, all that remains is to finish the piece. We use a food-safe lacquer and spray it on layer by layer, sanding after every few layers to keep it smooth. After several rounds of spraying and sanding, we end the finishing process with two last layers. The box gleams with the lacquer added, but it carries a manufactured look. We move on to buffing. With fine steel wool and wax, we apply the wax and buff, first with the steel wool, then with fine cloth, until the box has a pleasant sheen and a light layer of protective wax. The box is complete. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Day 11: With the box sanded, all that remains is to finish the piece. We use a food-safe lacquer and spray it on layer by layer, sanding after every few layers to keep it smooth. After several rounds of spraying and sanding, we end the finishing process with two last layers. The box gleams with the lacquer added, but it carries a manufactured look. We move on to buffing. With fine steel wool and wax, we apply the wax and buff, first with the steel wool, then with fine cloth, until the box has a pleasant sheen and a light layer of protective wax. The box is complete. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The final product!",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The final product!",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}