import { MobileProvider } from '@contexts/MobileContext';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import AboutPage from '@pages/AboutPage/AboutPage.js';
import BlogPage from '@pages/BlogPage/BlogPage.js';
import ChangelogPage from '@pages/ChangelogPage/ChangelogPage.js';
import FallbackPage from '@pages/FallbackPage/FallbackPage';
import GalleryPage from '@pages/GalleryPage/GalleryPage.js';
import LandingPage from '@pages/LandingPage/LandingPage.js';
import NewsletterPage from '@pages/NewsletterPage/NewsletterPage.js';
import PostPage from '@pages/PostPage/PostPage.js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';

import './App.css';
import { getTheme } from './Theme.js';

function usePageViewTracking() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);
}

const App = () => {
  const [isMounted, setIsMounted] = useState(false);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const theme = getTheme(isDarkMode);
  usePageViewTracking();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: theme.palette.background.default,
            transition: isMounted
              ? 'background-color 1s ease, color 1s ease'
              : 'none',
            color: theme.palette.text.primary,
          },
        }}
      />
      <MobileProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<PostPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/timshel" element={<NewsletterPage />} />
          <Route path="/changelog" element={<ChangelogPage />} />
          <Route path="*" element={<FallbackPage />} />
        </Routes>
      </MobileProvider>
    </ThemeProvider>
  );
};

export default App;
