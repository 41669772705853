export default {
  "metadata": {
    "num_words": 534
  },
  "content": [
    {
      "text": "Storm in Midsummer",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Storm in Midsummer",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "New York summers are hot and humid, a heavy, smothering heat that sticks to your skin and seeps into your bones. It is a heat that thickens and congeals around the flesh, a heat that does not fear the wind, for even the wind itself becomes hot and humid. New York summers are a meteorologist\u2019s nightmare. The forecast may predict a week of blue sky one hour and a week of thunderstorms the next. A cloudy day may darken on a moment\u2019s notice and yield a torrent of rainfall, only to fade away into brilliant blue mere minutes after. On occasion, hail falls in defiance of summer. Alike the Atlantic itself, the weather is ever changing, never predictable, a force unbent by all the efforts of science and man. One soon learns caution and carries an umbrella with them at all times. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "New York summers are hot and humid, a heavy, smothering heat that sticks to your skin and seeps into your bones. It is a heat that thickens and congeals around the flesh, a heat that does not fear the wind, for even the wind itself becomes hot and humid. New York summers are a meteorologist\u2019s nightmare. The forecast may predict a week of blue sky one hour and a week of thunderstorms the next. A cloudy day may darken on a moment\u2019s notice and yield a torrent of rainfall, only to fade away into brilliant blue mere minutes after. On occasion, hail falls in defiance of summer. Alike the Atlantic itself, the weather is ever changing, never predictable, a force unbent by all the efforts of science and man. One soon learns caution and carries an umbrella with them at all times. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The summer clouds come in many shapes and forms. On some days, the skies are adrift with wispy cotton strands that roll peacefully through the air. On others, swollen grey beasts rage and war with one another. There are thin curtains that given the sky a silver sheen and heavy grey blankets that shroud the world in grey. But there are always clouds, this much is certain. A steady stream of moisture feeds into the sky that draws from the water all around. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The summer clouds come in many shapes and forms. On some days, the skies are adrift with wispy cotton strands that roll peacefully through the air. On others, swollen grey beasts rage and war with one another. There are thin curtains that given the sky a silver sheen and heavy grey blankets that shroud the world in grey. But there are always clouds, this much is certain. A steady stream of moisture feeds into the sky that draws from the water all around. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Each storm begins the same way. The clouds build over days, swelling and growing, gathering moisture until they become plump and grey and heavy. They roll in, grey herds that swarm across the sky, drawing the color from the world. At first, the clouds are barely thick enough that the sun peeks through as a glowing silver disk. Then, as the days pass, the clouds grow heavier and heavier, until they seem to almost sag from the sky, the storm within barely held back. There are moments when the clouds are so dense that the very air itself feels wet and clams the senses. In such moments, it feels as though a single extra drop of water could call down the storm.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Each storm begins the same way. The clouds build over days, swelling and growing, gathering moisture until they become plump and grey and heavy. They roll in, grey herds that swarm across the sky, drawing the color from the world. At first, the clouds are barely thick enough that the sun peeks through as a glowing silver disk. Then, as the days pass, the clouds grow heavier and heavier, until they seem to almost sag from the sky, the storm within barely held back. There are moments when the clouds are so dense that the very air itself feels wet and clams the senses. In such moments, it feels as though a single extra drop of water could call down the storm.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "When the clouds are grey and heavy, the entire world seems to wait with bated breath. All the sounds of the world become muffled. The trees shift restlessly, the winds blow ominously, the storm builds silently. The clouds slowly darken until the sun is completely covered and the colors of the world collapse into shades of grey, into highlight and shadow, light and dark, black and white. The world teeters upon the edge, not quite in calm, not yet in storm. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "When the clouds are grey and heavy, the entire world seems to wait with bated breath. All the sounds of the world become muffled. The trees shift restlessly, the winds blow ominously, the storm builds silently. The clouds slowly darken until the sun is completely covered and the colors of the world collapse into shades of grey, into highlight and shadow, light and dark, black and white. The world teeters upon the edge, not quite in calm, not yet in storm. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "And then, a shift in the winds, a change in pressure. Whereas the world was previously silent, its voices pick up in intensity, whispering at first, then shouting, calling, until their voices swell into a chorus. The winds swirl and swell, their claws tearing at the trees, the grass, until they too sing in concert with the winds. The leaves dance upon the trees, the boughs shake and quake and crackle in the wind. The clouds boil in the sky, a violent grey sea that threatens to crash upon the parched earth below. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "And then, a shift in the winds, a change in pressure. Whereas the world was previously silent, its voices pick up in intensity, whispering at first, then shouting, calling, until their voices swell into a chorus. The winds swirl and swell, their claws tearing at the trees, the grass, until they too sing in concert with the winds. The leaves dance upon the trees, the boughs shake and quake and crackle in the wind. The clouds boil in the sky, a violent grey sea that threatens to crash upon the parched earth below. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Then, the sky opens up, and rain begins to pour.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Then, the sky opens up, and rain begins to pour.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}