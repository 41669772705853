import Footer from '@components/Footer/Footer';
import Navbar from '@components/Navbar/Navbar';
import { MobileContext } from '@contexts/MobileContext';
import useIsResizing from '@hooks/useIsResizing';
import FlareIcon from '@mui/icons-material/Flare';
import { Box, Typography } from '@mui/material';
import commonStyles from '@styles/common.module.css';
import { shiftCharacter } from '@utils/textUtils';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import styles from './AboutPage.module.css';
import TypewriterText from './components/TypewriterText';

const ABOUT_TEXT = `
  Steinbeck Enthusiast. Sunset connoisseur. Engineer by trade. New Yorker by blood.
  Stargazing, daydreaming. I write, on occasion.
`;

const WELCOME_TEXT = 'Welcome to the garden. Come, stay a while.';

const AboutPage = () => {
  const isResizing = useIsResizing();
  const isMobile = useContext(MobileContext);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);

  const [shiftedText, setShiftedText] = useState(
    ABOUT_TEXT.split('')
      .map((char) => shiftCharacter(char, 1))
      .join('')
  );
  const [isTextShifted, setIsTextShifted] = useState(false);
  const [isShifting, setIsShifting] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const shiftText = async (n) => {
    if (isTextShifted || isShifting) return;

    setIsShifting(true);
    for (let i = 0; i < n; i++) {
      await new Promise((resolve) =>
        setTimeout(resolve, 500 * Math.exp(-0.2 * i))
      );
      setShiftedText((prev) =>
        prev
          .split('')
          .map((char) => shiftCharacter(char, 1))
          .join('')
      );
    }
    setIsShifting(false);
    setIsTextShifted(true);
  };

  const handleIconClick = (e) => {
    if (isShifting || isTextShifted) return;

    e.target.style.transform = 'rotate(720deg)';
    e.target.style.transition =
      'transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)';
    shiftText(103);
  };

  return (
    <>
      <Helmet>
        <title>About | EdenInc</title>
      </Helmet>
      <Box className={commonStyles.pageLayout}>
        <Navbar />
        <Box
          className={
            isMobile ? styles.mobilePageContainer : styles.pageContainer
          }
          sx={{
            opacity: isResizing ? 0 : 1,
            transition: 'opacity 0.3s ease-out',
          }}
        >
          <Box
            className={styles.pageContent}
            sx={{
              flexDirection: isMobile ? 'column' : 'row',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
            }}
          >
            <Box
              className={styles.imageContainer}
              sx={{ width: isMobile ? '100%' : '45%' }}
            >
              <img
                className={styles.pageImage}
                src={require('@assets/eyw.webp')}
                alt="About"
              />
            </Box>
            <Box
              className={styles.textContainer}
              sx={{
                width: isMobile ? '100%' : '55%',
                ...(!isMobile && { marginLeft: '2rem' }),
                ...(isMobile && { marginTop: '2rem' }),
              }}
            >
              <Typography
                variant="h1"
                sx={{ fontSize: '2rem', marginBottom: '1rem' }}
              >
                eden west
                <FlareIcon
                  className={
                    isShifting || isTextShifted
                      ? undefined
                      : isDarkMode
                        ? styles.iconDark
                        : styles.icon
                  }
                  onClick={handleIconClick}
                  sx={{
                    fontSize: '1.5rem',
                    marginLeft: '0.75rem',
                  }}
                />
              </Typography>
              <Typography variant="body1" sx={{ height: '150px' }}>
                {shiftedText}
                {isTextShifted && !isShifting && (
                  <>
                    <br />
                    <br />
                    <TypewriterText
                      isVisible={true}
                      duration={3500}
                      text={WELCOME_TEXT}
                    />
                  </>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default AboutPage;
