export default {
  "metadata": {
    "num_words": 680
  },
  "content": [
    {
      "text": "Baccalaureate",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Baccalaureate",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "Each year, Stanford hosts a Baccalaureate Speechwriting contest. This year, two hours before the deadline, I began writing. Five minutes before the deadline, I trimmed a hundred words to meet the word limit. I submitted seconds before the competition closed. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Each year, Stanford hosts a Baccalaureate Speechwriting contest. This year, two hours before the deadline, I began writing. Five minutes before the deadline, I trimmed a hundred words to meet the word limit. I submitted seconds before the competition closed. ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "This speech was a chance for me to reflect on my time at Stanford, to mull on my experiences and wonder over what comes next. The following speech is the product of this thought.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "This speech was a chance for me to reflect on my time at Stanford, to mull on my experiences and wonder over what comes next. The following speech is the product of this thought.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": " \u2767\u2767 \u2766 \u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": " ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2767\u2767",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2766",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Stanford has taught me many, many things. How to read into a novel, to pull stories apart at their seams and understand their gods and creators. How to project into subspaces, compute all sorts of products, and perform Gram-Schmidt orthogonalization (thanks, MATH 51). How to ride a bike first with one hand, then none, then how to patch up scrapes and bruises after accidents on the Circle of Death. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Stanford has taught me many, ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "many ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "things. How to read into a novel, to pull stories apart at their seams and understand their gods and creators. How to project into subspaces, compute all sorts of products, and perform Gram-Schmidt orthogonalization (thanks, MATH 51). How to ride a bike first with one hand, then none, then how to patch up scrapes and bruises after accidents on the Circle of Death. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I can go on and on. But standing here today, there are a few special lessons I\u2019d like to share that I\u2019ll carry with me for the rest of my life. Three, to be precise. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I can go on and on. But standing here today, there are a few special lessons I\u2019d like to share that I\u2019ll carry with me for the rest of my life. Three, to be precise. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The first, a lesson on home. October 27th, 2019, was the first day when, without even thinking, the word home wrote itself into my journal. It was then, a month into my stay, that I finally grew familiar with campus. The Row transformed from a verb to a noun; the sprawling campus finally seemed to gain a semblance of order. And though I was still finding my place, it was a moment of wonder. What a beautiful place, I remember thinking, how I\u2019d love to spend my next four years here.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The first, a lesson on home. October 27th, 2019, was the first day when, without even thinking, the word ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "home",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " wrote itself into my journal. It was then, a month into my stay, that I finally grew familiar with campus. The ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Row",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " transformed from a verb to a noun; the sprawling campus finally seemed to gain a semblance of order. And though I was still finding my place, it was a moment of wonder. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "What a beautiful place",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", I remember thinking, ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "how I\u2019d love to spend my next four years here",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ".",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "A few months later, the pandemic happened.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "A few months later, the pandemic happened.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "For many of us, this Spring is just the second Spring we\u2019ve had on campus. The pandemic shook our worlds, but it also taught me what home truly is. Home was the communities that worked so hard to stay together despite time zones and Zoom fatigue. Home was the upperclassmen that mentored a sophomore still finding their way, the bits of conversations exchanged in digital passing. And when we finally returned to Stanford, home was not this campus, but the people, the creative, colorful communities I\u2019ve grown to love all these years. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "For many of us, this Spring is just the second Spring we\u2019ve had on campus. The pandemic shook our worlds, but it also taught me what ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "home",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " truly is. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Home",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " was the communities that worked so hard to stay together despite time zones and Zoom fatigue. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Home",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " was the upperclassmen that mentored a sophomore still finding their way, the bits of conversations exchanged in digital passing. And when we finally returned to Stanford, ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "home",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " was not this campus, but the people, the creative, colorful communities I\u2019ve grown to love all these years. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The second, a lesson on faith. Stanford taught me to take the leap, because so often, the best things in life are just a bit out of reach. I\u2019ve learned that when your friend says it\u2019s the super bloom, you take the drive out to see fields aflame with lupins, poppies, and fuchsias. That when your friend says there are opportunities to explore Asian American liberation through dance, theater, and service, you dive into all three. That when your neighbor down the hall invites you to take a swim in Lake Lag at 4 AM because it finally stopped raining and they\u2019re surely draining the lake soon, you\u2026 maybe sit that one out. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The second, a lesson on faith. Stanford taught me to take the leap, because so often, the best things in life are just a bit out of reach. I\u2019ve learned that when your friend says it\u2019s the super bloom, you take the drive out to see fields aflame with lupins, poppies, and fuchsias. That when your friend says there are opportunities to explore Asian American liberation through dance, theater, and service, you dive into all three. That when your neighbor down the hall invites you to take a swim in Lake Lag at 4 AM because it finally stopped raining and they\u2019re surely draining the lake soon, you\u2026 maybe sit that one out. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The point stands. My only regrets have been leaps not taken, bridges not crossed. I invite you all to live without regret. Take that leap, and see where you land. If not on solid ground, you\u2019ll soar above us all. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The point stands. My only regrets have been leaps not taken, bridges not crossed. I invite you all to live without regret. Take that leap, and see where you land. If not on solid ground, you\u2019ll soar above us all. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The last, a lesson on learning. It\u2019s funny \u2014 the most important lessons I\u2019ve learned have rarely come from lecture halls. Rather, it\u2019s been the moments in between that have taught me the most, moments of calm spent in meditation, late-night conversations until 3 AM with friends. Learning is a lifelong process, a state of open-mindedness and eagerness to empathize. Issues that once seemed black and white have given way to rich gradients of viewpoints. Injustices previously painted over by the brush of history invite me to rise up and challenge the systems around me. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The last, a lesson on learning. It\u2019s funny \u2014 the most important lessons I\u2019ve learned have rarely come from lecture halls. Rather, it\u2019s been the moments in between that have taught me the most, moments of calm spent in meditation, late-night conversations until 3 AM with friends. Learning is a lifelong process, a state of open-mindedness and eagerness to empathize. Issues that once seemed black and white have given way to rich gradients of viewpoints. Injustices previously painted over by the brush of history invite me to rise up and challenge the systems around me. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Looking ahead, I can\u2019t help but feel a certain excitement, tempered by the bittersweet reality of saying goodbye. In the next few years, we will disperse across the world, into communities, disciplines, and industries ripe for change. We\u2019ll create the worlds that, at present, we can only dream of. I\u2019m confident we\u2019ll create a world we can be proud of.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Looking ahead, I can\u2019t help but feel a certain excitement, tempered by the bittersweet reality of saying goodbye. In the next few years, we will disperse across the world, into communities, disciplines, and industries ripe for change. We\u2019ll create the worlds that, at present, we can only dream of. I\u2019m confident we\u2019ll create a world we can be proud of.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}