import { MobileContext } from '@contexts/MobileContext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography } from '@mui/material';
import { getFormattedDate, getFullDate } from '@utils/dateUtils';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from '../BlogPage.module.css';

const BlogPost = ({
  shouldRender,
  slug,
  post,
  index,
  handleTagClick,
  renderFullDate = true,
  isFeatured = false,
  isTransitioning = false,
  handleBreadcrumbClick = () => {},
}) => {
  const { date, image, title, excerpt, tags, type, readingTime } = post;
  const isMobile = useContext(MobileContext);
  const navigate = useNavigate();
  const formattedDate = renderFullDate
    ? getFullDate(date)
    : getFormattedDate(date);
  const isClickablePost = type === 'post';

  const handleClick = (e) => {
    if (e.button === 2 || e.ctrlKey || e.metaKey) return;
    e.preventDefault();
    navigate(`/blog/${slug}`);
  };

  const baseLinkStyles = {
    cursor: isClickablePost ? 'pointer' : 'default',
    textDecoration: 'none',
    color: 'inherit',
  };

  const linkProps = isClickablePost
    ? {
        href: `/blog/${slug}`,
        onClick: handleClick,
        style: baseLinkStyles,
      }
    : {
        style: baseLinkStyles,
      };

  const dateLinkProps = {
    ...linkProps,
    style: {
      ...baseLinkStyles,
      color: '#ce0000',
    },
  };

  const renderImage = (imageProps) => (
    <img
      src={require(`@content/blog/images/${image}`)}
      alt={title}
      {...imageProps}
    />
  );

  const renderMobileFeaturedImage = () => (
    <Box
      className={styles.mobileImageArrowContainer}
      sx={{
        width: '100%',
        opacity: isTransitioning ? 0 : 1,
      }}
    >
      <Box
        className={styles.mobileImageArrow}
        onClick={() => handleBreadcrumbClick(index - 1)}
      >
        <ArrowBackIosNewIcon />
      </Box>
      <a
        href={`/blog/${slug}`}
        onClick={handleClick}
        className={styles.featuredPostImage}
      >
        {renderImage({
          style: { width: '100%', height: '100%', objectFit: 'contain' },
        })}
      </a>
      <Box
        className={styles.mobileImageArrow}
        onClick={() => handleBreadcrumbClick(index + 1)}
      >
        <ArrowForwardIosIcon />
      </Box>
    </Box>
  );

  const renderDesktopFeaturedImage = () => (
    <a
      href={`/blog/${slug}`}
      onClick={handleClick}
      className={styles.featuredPostImage}
      style={{
        opacity: isTransitioning ? 0 : 1,
        width: isMobile ? '100%' : '45%',
        height: isMobile ? '40%' : '100%',
      }}
    >
      {renderImage({
        style: { width: '100%', height: '100%', objectFit: 'contain' },
      })}
    </a>
  );

  const renderPostImage = () => {
    if (!isFeatured) {
      return (
        <a {...linkProps}>
          {renderImage({
            className: styles.listPostImage,
            style: { cursor: isClickablePost ? 'pointer' : 'default' },
          })}
        </a>
      );
    }
    return isMobile
      ? renderMobileFeaturedImage()
      : renderDesktopFeaturedImage();
  };

  const renderTags = () =>
    tags?.length > 0 && (
      <Box className={styles.postTags}>
        {tags.map((tag, i) => (
          <React.Fragment key={tag}>
            <Typography
              component="span"
              variant="body2"
              className={styles.postTag}
              onClick={() => handleTagClick(tag)}
              sx={{ marginBottom: '0.5rem' }}
            >
              {tag}
            </Typography>
            {i < tags.length - 1 && ', '}
          </React.Fragment>
        ))}
      </Box>
    );

  const featuredStyles = {
    flexDirection: isMobile ? 'column' : 'row',
    height: isMobile ? 'auto' : '28rem',
    minHeight: isMobile ? '36rem' : '28rem',
  };

  return (
    <Box sx={isFeatured ? featuredStyles : undefined}>
      <Box
        key={index}
        className={isFeatured ? styles.featuredPost : styles.blogListPost}
        sx={
          isFeatured
            ? {
                ...featuredStyles,
                minHeight: isMobile ? '32rem' : '28rem',
              }
            : {
                opacity: shouldRender ? 1 : 0,
              }
        }
      >
        {renderPostImage()}
        <Box
          className={styles.postInfoContainer}
          sx={
            isFeatured
              ? {
                  opacity: isTransitioning ? 0 : 1,
                  width: isMobile ? '90%' : '55%',
                  height: isMobile ? '15rem' : 'auto',
                }
              : undefined
          }
        >
          <Box className={styles.postTitleContainer}>
            <Typography variant="h3" sx={{ mb: 0.5 }}>
              <a {...dateLinkProps} className={styles.postDate}>
                {formattedDate}
              </a>
              <a {...linkProps}>{title}</a>
            </Typography>
          </Box>

          <Box className={styles.postDivider} />

          {renderTags()}
          <Typography variant="body2" sx={{ marginBottom: '0.5rem' }}>
            <a {...linkProps}>{excerpt}</a>
          </Typography>
          {readingTime && (
            <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
              <a {...linkProps}>{readingTime} min read</a>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BlogPost;
