import { MobileContext } from '@contexts/MobileContext';
import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './Navbar.module.css';
import NavbarMain from './components/NavbarMain';
import SearchOverlay from './components/SearchOverlay';
import SidebarOverlay from './components/SidebarOverlay';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const isMobile = useContext(MobileContext);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [sidebarClosing, setSidebarClosing] = useState(false);
  const [searchClosing, setSearchClosing] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (sidebarOpen || searchOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [sidebarOpen, searchOpen]);

  const toggleSidebar = () => {
    handleSearchClose();
    if (!sidebarOpen) {
      setSidebarOpen(true);
    } else {
      handleSidebarClose();
    }
  };

  const toggleSearch = () => {
    handleSidebarClose();
    if (!searchOpen) {
      setSearchOpen(true);
    } else {
      handleSearchClose();
    }
  };

  const handleSidebarClose = () => {
    setSidebarClosing(true);
    setTimeout(() => {
      setSidebarClosing(false);
      setSidebarOpen(false);
    }, 500);
  };

  const handleSearchClose = () => {
    setSearchClosing(true);
    setTimeout(() => {
      setSearchClosing(false);
      setSearchOpen(false);
    }, 500);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleClick = (path) => {
    if (!isActive(path)) {
      navigate(path);
      handleSearchClose();
    }
  };

  const handleContactClick = () => {
    console.log('Contact Us Clicked');
  };

  return (
    <>
      <Box
        className={
          isMobile
            ? styles.navbarMobileContainer
            : styles.navbarDesktopContainer
        }
        sx={{
          backgroundColor: isScrolled
            ? isDarkMode
              ? 'rgba(33, 33, 33)'
              : 'rgba(255, 255, 255)'
            : isDarkMode
              ? '#212121'
              : 'white',
          transition: 'background-color 1s ease, opacity 0.5s ease',
          opacity: searchOpen ? 1 : 0.95,
        }}
      >
        <NavbarMain
          toggleSidebar={toggleSidebar}
          toggleSearch={toggleSearch}
          sidebarOpen={sidebarOpen}
          sidebarClosing={sidebarClosing}
          searchOpen={searchOpen}
          searchClosing={searchClosing}
        />
      </Box>

      {sidebarOpen && (
        <SidebarOverlay
          isDarkMode={isDarkMode}
          isClosing={sidebarClosing}
          handleClose={handleSidebarClose}
          handleClick={handleClick}
          isActive={isActive}
        />
      )}

      {searchOpen && (
        <SearchOverlay
          isDarkMode={isDarkMode}
          isClosing={searchClosing}
          handleClose={handleSearchClose}
        />
      )}
    </>
  );
};

export default Navbar;
