export default {
  "metadata": {
    "num_words": 1751
  },
  "content": [
    {
      "text": "At the End of Time",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "At the End of Time",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "From my listening of Leyland Kirby\u2019s Everywhere at the End of Time: a collection of thoughts and musings, loosely tied together by the idea of memory.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "From my listening of Leyland Kirby\u2019s Everywhere at the End of Time: a collection of thoughts and musings, loosely tied together by the idea of memory.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Everywhere at the End of Time is a series of six of albums released by electronic musician Leyland Kirby under the alias The Caretaker. The work consists of six hours of music, subdivided into stages and tracks within each album. Each stage represents a stage of Alzheimer\u2019s, with the tracks becoming increasingly warped, distorted, and confused as the album progresses. The early stages are marked by longing and nostalgia. The later stages are almost transcendent, their melodies an empty white bliss. Below are annotations from my own listening, a collection of thoughts and musings loosely tied together by the idea of memory. You are encouraged to listen to the tracks in the album here.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Everywhere at the End of Time",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " is a series of six of albums released by electronic musician Leyland Kirby under the alias The Caretaker. The work consists of six hours of music, subdivided into stages and tracks within each album. Each stage represents a stage of Alzheimer\u2019s, with the tracks becoming increasingly warped, distorted, and confused as the album progresses. The early stages are marked by longing and nostalgia. The later stages are almost transcendent, their melodies an empty white bliss. Below are annotations from my own listening, a collection of thoughts and musings loosely tied together by the idea of memory. You are encouraged to listen to the tracks in the album ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "here",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": "https://www.youtube.com/watch?v=wJWksPWDKOc"
        },
        {
          "text": ".",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\nStage 1: Here we experience the first signs of memory loss. This stage is most like a beautiful daydream. The glory of old age and recollection. The last of the great days.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\n",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Stage 1: Here we experience the first signs of memory loss. This stage is most like a beautiful daydream. The glory of old age and recollection. The last of the great days.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We\u2019re not sure how it begins, but amyloid plaques are the most compelling theory. Mangled masses of proteins coalesce between the neurons of the brain, straining and snapping their connections. Alike the buildup of fat in arteries, the process is gradual and devastating. A severed connection here, a strained connection there. At first, redundant connections that don\u2019t impact cognition on a noticeable scale. But the buildup is irreversible and only worsens with time. These are no ordinary proteins. They latch onto one another, and as the body blindly continues to produce them, their tangles continue to grow. They become even more monstrous and intrusive. The body fights back with inflammation and immune responses that only harm the brain further. It is a cruel battle where defeat is inevitable. At late stages, the patient experiences nerve death, tissue loss, and organ failure. Alzheimer\u2019s is a terrible disease. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We\u2019re not sure how it begins, but amyloid plaques are the most compelling theory. Mangled masses of proteins coalesce between the neurons of the brain, straining and snapping their connections. Alike the buildup of fat in arteries, the process is gradual and devastating. A severed connection here, a strained connection there. At first, redundant connections that don\u2019t impact cognition on a noticeable scale. But the buildup is irreversible and only worsens with time. These are no ordinary proteins. They latch onto one another, and as the body blindly continues to produce them, their tangles continue to grow. They become even more monstrous and intrusive. The body fights back with inflammation and immune responses that only harm the brain further. It is a cruel battle where defeat is inevitable. At late stages, the patient experiences nerve death, tissue loss, and organ failure. Alzheimer\u2019s is a terrible disease. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Memory is fragile. All that was, all that is, stored in the hippocampus, a mass of neurons just a few cubic centimeters in volume. Memory is fallible. It is known that each time we reference a memory, we leave tiny changes of our own, fingerprints upon the film of our mind. Memory is precious. Without memory, who are we? If we were to wake up the next morning and forget those we love, those we hate, and everybody in between, would we still be ourselves? If we lose the milestones that define our lives, if we are unable to remember that which makes us who we were, are, and want to be, what meaning is left? The miracles of modern medicine allow us to graft limbs and excise tumors, to separate twins conjoined at birth and transplant the very heart of our body. But damaged memories cannot be so healed. We may never understand the chemical connections that define love and grief, joy and sorrow.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Memory is fragile. All that was, all that is, stored in the hippocampus, a mass of neurons just a few cubic centimeters in volume. Memory is fallible. It is known that each time we reference a memory, we leave tiny changes of our own, fingerprints upon the film of our mind. Memory is precious. Without memory, who are we? If we were to wake up the next morning and forget those we love, those we hate, and everybody in between, would we still be ourselves? If we lose the milestones that define our lives, if we are unable to remember that which makes us who we were, are, and want to be, what meaning is left? The miracles of modern medicine allow us to graft limbs and excise tumors, to separate twins conjoined at birth and transplant the very heart of our body. But damaged memories cannot be so healed. We may never understand the chemical connections that define love and grief, joy and sorrow.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Stage 2: The second stage is the self-realization and awareness that something is wrong with a refusal to accept that. More effort is made to remember so memories can be more long form with a little more deterioration in quality. The overall personal mood is generally lower than the first stage and at a point before confusion starts setting in.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Stage 2: The second stage is the self-realization and awareness that something is wrong with a refusal to accept that. More effort is made to remember so memories can be more long form with a little more deterioration in quality. The overall personal mood is generally lower than the first stage and at a point before confusion starts setting in.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Sifting through memory is a privilege reserved for old age. Society conditions us to accept the anesthetic of hard work, teaches us to compartmentalize and process our joys and sorrows quietly, discreetly. We build our routines, repeating the bitter parts of our lives until they become automatic. The eccentricities and incongruencies of our lives are ironed as we learn to conform. Life settles into local minima, becomes a sea of routine interspersed with islands of spontaneity. One goes months without seeing dear friends, until suddenly, five years have passed and we are strangers in all but name. One goes years without taking a proper look in the mirror, until suddenly, a decade has passed and our hair thins and joints ache. One goes decades without thinking about their happiness, until suddenly, half a lifetime has passed and one forgets what truly makes them happy. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Sifting through memory is a privilege reserved for old age. Society conditions us to accept the anesthetic of hard work, teaches us to compartmentalize and process our joys and sorrows quietly, discreetly. We build our routines, repeating the bitter parts of our lives until they become automatic. The eccentricities and incongruencies of our lives are ironed as we learn to conform. Life settles into local minima, becomes a sea of routine interspersed with islands of spontaneity. One goes months without seeing dear friends, until suddenly, five years have passed and we are strangers in all but name. One goes years without taking a proper look in the mirror, until suddenly, a decade has passed and our hair thins and joints ache. One goes decades without thinking about their happiness, until suddenly, half a lifetime has passed and one forgets what truly makes them happy. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Blink, and a day, a week, a month, a year has passed. Blink, and who is this man in the mirror? ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Blink, and a day, a week, a month, a year has passed. Blink, and ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "who is this man in the mirror",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "? ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Is this the life we want to lead? As our roots reach deeper with the years, do we lose the spontaneity that is so essential to growth? Do we, like a tree, become too grounded in our own mental models, unable and unwilling to reconcile with change, both physical changes in our environment and the people around us and abstract changes in ideas and norms? It is this that I fear most, that days, months, and years might pass before me in an instant, that I will lose my curiosity, my ability to make memories and learn from each moment. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Is this the life we want to lead? As our roots reach deeper with the years, do we lose the spontaneity that is so essential to growth? Do we, like a tree, become too grounded in our own mental models, unable and unwilling to reconcile with change, both physical changes in our environment and the people around us and abstract changes in ideas and norms? It is this that I fear most, that days, months, and years might pass before me in an instant, that I will lose my curiosity, my ability to make memories and learn from each moment. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Stage 3: Here we are presented with some of the last coherent memories before confusion fully rolls in and the grey mists form and fade away. Finest moments have been remembered, the musical flow in places is more confused and tangled. As we progress some singular memories become more disturbed, isolated, broken and distant. These are the last embers of awareness before we enter the post awareness stages.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Stage 3: Here we are presented with some of the last coherent memories before confusion fully rolls in and the grey mists form and fade away. Finest moments have been remembered, the musical flow in places is more confused and tangled. As we progress some singular memories become more disturbed, isolated, broken and distant. These are the last embers of awareness before we enter the post awareness stages.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "When I was younger, I dreamed of leaving a mark upon the world, of doing some great deed that would etch my name into history. I looked towards historical figures with reverence, upon Caesar and Washington. I admired corporate contemporaries in Jobs and Gates. When I told my parents of my ambitions, they\u2019d replied, we all did. In his youth, my father had dreams of becoming a great scientist, my mother of becoming a rocket engineer. Realities, of traveling to a new country, of planting roots, has worn down these dreams over time. In another life, had they been born just ten years later, in a China whose industrial furnaces roared and who held their daughters in as high regard as their sons, they might have had the opportunity to turn their dreams into reality. But such was not the case. They have found peace in what has come to pass. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "When I was younger, I dreamed of leaving a mark upon the world, of doing some great deed that would etch my name into history. I looked towards historical figures with reverence, upon Caesar and Washington. I admired corporate contemporaries in Jobs and Gates. When I told my parents of my ambitions, they\u2019d replied, ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "we all did",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". In his youth, my father had dreams of becoming a great scientist, my mother of becoming a rocket engineer. Realities, of traveling to a new country, of planting roots, has worn down these dreams over time. In another life, had they been born just ten years later, in a China whose industrial furnaces roared and who held their daughters in as high regard as their sons, they might have had the opportunity to turn their dreams into reality. But such was not the case. They have found peace in what has come to pass. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "After that conversation, I promised myself that I wouldn\u2019t let anything get between me and my dreams. But it was a child's conviction, that I would sooner break before I bend, and it would be tested. What is conviction and pride in the face of love and family? And these heroes of mine, what harms have they committed against those on the silent side of history, on those who are not so fortunate enough to be graced with our empathy? Time adds nuance. There are no heroes or villains. We are only human. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "After that conversation, I promised myself that I wouldn\u2019t let anything get between me and my dreams. But it was a child's conviction, that I would sooner break before I bend, and it would be tested. What is conviction and pride in the face of love and family? And these heroes of mine, what harms have they committed against those on the silent side of history, on those who are not so fortunate enough to be graced with our empathy? Time adds nuance. There are no heroes or villains. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "We are only human",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Stage 4: Post-Awareness Stage 4 is where serenity and the ability to recall singular memories gives way to confusions and horror. It's the beginning of an eventual process where all memories begin to become more fluid through entanglements, repetition and rupture. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Stage 4: Post-Awareness Stage 4 is where serenity and the ability to recall singular memories gives way to confusions and horror. It's the beginning of an eventual process where all memories begin to become more fluid through entanglements, repetition and rupture. ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The summer after my seventh grade, as I was visiting my grandparents in China, a cousin of mine died just weeks after I visited the countryside. He had drowned. He had been playing in the fields, in patches of wild greenery that hid bogs and deep water beneath them, and when day turned to night and hours passed with him gone, his mother began to panic. I was too young at the time to gather the details, but news of his disappearance spread like wildfire through the local news. It was a week later that his body was found and his death was confirmed. He had been carried away by the currents, his body decomposing and bloating until fishermen downstream netted what remained of him from the water. He was within a year of my age, no more than twelve or thirteen years old. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The summer after my seventh grade, as I was visiting my grandparents in China, a cousin of mine died just weeks after I visited the countryside. He had drowned. He had been playing in the fields, in patches of wild greenery that hid bogs and deep water beneath them, and when day turned to night and hours passed with him gone, his mother began to panic. I was too young at the time to gather the details, but news of his disappearance spread like wildfire through the local news. It was a week later that his body was found and his death was confirmed. He had been carried away by the currents, his body decomposing and bloating until fishermen downstream netted what remained of him from the water. He was within a year of my age, no more than twelve or thirteen years old. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Nearly a decade has passed. I have grown older, lived and learned and loved. He is buried, laid to rest within a mile of where he was born. His name is lost to me, his face just a shadow. I think of him, at times, here at the edge of the world. Why was I given this privilege to grow up in the States, to attend university, to live in this land of opportunity? I am the first in my family to be born outside of China. But had I been born in the land of my ancestors, perhaps I would have been there with my cousin, running through lush greenery in a hidden pocket of the world that was our own. I do not deserve this life, but it is the one I was given. I feel that I must live it to its potential. If not for me, then for his burning memory.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Nearly a decade has passed. I have grown older, lived and learned and loved. He is buried, laid to rest within a mile of where he was born. His name is lost to me, his face just a shadow. I think of him, at times, here at the edge of the world. Why was I given this privilege to grow up in the States, to attend university, to live in this land of opportunity? I am the first in my family to be born outside of China. But had I been born in the land of my ancestors, perhaps I would have been there with my cousin, running through lush greenery in a hidden pocket of the world that was our own. I do not deserve this life, but it is the one I was given. I feel that I must live it to its potential. If not for me, then for his burning memory.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Stage 5: Post-Awareness Stage 5 confusions and horror. More extreme entanglements, repetition and rupture can give way to calmer moments. The unfamiliar may sound and feel familiar. Time is often spent only in the moment leading to isolation.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Stage 5: Post-Awareness Stage 5 confusions and horror. More extreme entanglements, repetition and rupture can give way to calmer moments. The unfamiliar may sound and feel familiar. Time is often spent only in the moment leading to isolation.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Immortality is a myth. We have always flirted with the idea, if not for our physical self, then for our spiritual soul. Medieval alchemists searched for centuries to concoct elixirs that could promise eternal life. Modern scientists dedicate their working lives to finding cures for cell senescence and nucleic mutations. But we will never find a cure for death. Despots, oligarchs, and billionaires spend their entire lives distancing themselves from the masses, holding desperately to their power and conjuring projections of smoke and mirrors, but they share the same inevitable destination as everybody else. Wealth, power, and influence mean nothing upon the deathbed. We will all arrive at the same destination. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Immortality is a myth. We have always flirted with the idea, if not for our physical self, then for our spiritual soul. Medieval alchemists searched for centuries to concoct elixirs that could promise eternal life. Modern scientists dedicate their working lives to finding cures for cell senescence and nucleic mutations. But we will never find a cure for death. Despots, oligarchs, and billionaires spend their entire lives distancing themselves from the masses, holding desperately to their power and conjuring projections of smoke and mirrors, but they share the same inevitable destination as everybody else. Wealth, power, and influence mean nothing upon the deathbed. We will all arrive at the same destination. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "In a few billion years, the sun will sputter out and fade. In a hundred billion years, the galaxies will fade from our sky. And past that, in times beyond our grasp, the very fabric of our universe will fold in upon itself, with protons and neutrons disintegrating into the darkness of space. If humanity survives half that long, they will soon realize that there is nothing at the end of time. History becomes meaningless, and, in the distant future, there will remain nothing, not even the stars, to remember our names. But perhaps it is a point of solace. We need not concern ourselves with vain notions of legacy. We need not rebel against the course of nature. We need not be heroic. We can just be good.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "In a few billion years, the sun will sputter out and fade. In a hundred billion years, the galaxies will fade from our sky. And past that, in times beyond our grasp, the very fabric of our universe will fold in upon itself, with protons and neutrons disintegrating into the darkness of space. If humanity survives half that long, they will soon realize that there is nothing at the end of time. History becomes meaningless, and, in the distant future, there will remain nothing, not even the stars, to remember our names. But perhaps it is a point of solace. We need not concern ourselves with vain notions of legacy. We need not rebel against the course of nature. We need not be heroic. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "We can just be good",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ".",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Stage 6: Post-Awareness Stage 6 is without description.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Stage 6: Post-Awareness Stage 6 is without description.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}