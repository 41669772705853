import NightlightIcon from '@mui/icons-material/Nightlight';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { Box, IconButton } from '@mui/material';
import { toggleTheme } from '@redux/slices/themeSlice';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ThemeToggle.module.css';

const ThemeToggle = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const [isChangingTheme, setIsChangingTheme] = useState(false);
  const [showSun, setShowSun] = useState(!isDarkMode);

  const handleClick = () => {
    if (isChangingTheme) return;
    setIsChangingTheme(true);
    dispatch(toggleTheme());

    setTimeout(() => {
      setShowSun(!showSun);
      setIsChangingTheme(false);
    }, 500);
  };

  return (
    <Box className={styles.themeToggle}>
      <IconButton
        color="inherit"
        aria-label="theme-toggle"
        onClick={handleClick}
        disabled={isChangingTheme}
        className={styles.themeToggleIconButton}
        sx={{
          '&.Mui-disabled': {
            color: 'inherit',
          },
        }}
      >
        <Box
          className={styles.themeButtonIcon}
          sx={{ opacity: showSun ? 1 : 0 }}
        >
          <WbSunnyIcon />
        </Box>
        <Box
          className={styles.themeButtonIcon}
          sx={{ opacity: showSun ? 0 : 1 }}
        >
          <NightlightIcon />
        </Box>
      </IconButton>
    </Box>
  );
};

export default ThemeToggle;
