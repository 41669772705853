export default {
  "metadata": {
    "num_words": 723
  },
  "content": [
    {
      "text": "Eden, in Paris",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Eden, in Paris",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "Reflections on my one-week stay in Paris over spring break. Alternatively, an American\u2019s view of Paris.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Reflections on my one-week stay in Paris over spring break. Alternatively, an American\u2019s view of Paris.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Paris is a beautiful, sprawling city that nestles the Seine, cross-crossed by cobblestone streets and narrow alleys that carve out an almost Picasso-esque imagination of what a modern city might look like. It is a stolid elegy to a long and storied past, a timeless promise to an ever-changing future, but at present, it is the little details that catch my gaze. It is the long coats and berets that float the avenues, the daring motorcyclists that weave between even bolder drivers, the black trash bags piled high up to your chest aside bright green bins. We are not all that different, across the pond, so it is the subtle eccentricities that draw my attention the most.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Paris is a beautiful, sprawling city that nestles the Seine, cross-crossed by cobblestone streets and narrow alleys that carve out an almost Picasso-esque imagination of what a modern city might look like. It is a stolid elegy to a long and storied past, a timeless promise to an ever-changing future, but at present, it is the little details that catch my gaze. It is the long coats and berets that float the avenues, the daring motorcyclists that weave between even bolder drivers, the black trash bags piled high up to your chest aside bright green bins. We are not all that different, across the pond, so it is the subtle eccentricities that draw my attention the most.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The Seine is the pulse of Paris. It is a monarch of a river, not in size, but in history, magnifying and magnified by the city around it. The waters of the Seine are dark and brackish, never still, determined and steady on its path to the Channel. In millennia past, it must have been a cradle of life, sourcing the heartwater that nurtured tribes, city states, and nations. In recent centuries, it has been polluted and spoiled, its fauna and flora dwindling as it became ever more inhospitable. We have only recently begun to reclaim its lost dignity. Like the Hudson, swimming in the Seine, though inadvisable, is no longer lethal. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The Seine is the pulse of Paris. It is a monarch of a river, not in size, but in history, magnifying and magnified by the city around it. The waters of the Seine are dark and brackish, never still, determined and steady on its path to the Channel. In millennia past, it must have been a cradle of life, sourcing the heartwater that nurtured tribes, city states, and nations. In recent centuries, it has been polluted and spoiled, its fauna and flora dwindling as it became ever more inhospitable. We have only recently begun to reclaim its lost dignity. Like the Hudson, swimming in the Seine, though inadvisable, is no longer lethal. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "For the French, a second life begins after retirement, and the truth of this can be seen throughout the city, at corner caf\u00e9s and alleyway bars with cushioned seats facing toward the street. Silver locks and snowy beards gather, sipping on coffee and wine, soaking in the sun, peering out at a shifting mirage of faces. It is not hard to see the appeal of such a lifestyle. They are constants in the torrent of life that flows around them, washes over them. We sit as they do, sipping on spritzes in the early afternoon. It is a near perfect scene \u2014 if you ignore the clouds of cigarette smoke that drift over as the wind turns.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "For the French, a second life begins after retirement, and the truth of this can be seen throughout the city, at corner caf\u00e9s and alleyway bars with cushioned seats facing toward the street. Silver locks and snowy beards gather, sipping on coffee and wine, soaking in the sun, peering out at a shifting mirage of faces. It is not hard to see the appeal of such a lifestyle. They are constants in the torrent of life that flows around them, washes over them. We sit as they do, sipping on spritzes in the early afternoon. It is a near perfect scene \u2014 if you ignore the clouds of cigarette smoke that drift over as the wind turns.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There is a tendency to romanticize great cities. Paris stands proud among their ranks. The city of love, of wine and cheese, of baked aromas wafting out antique boulangeries, of foie gras and charcuterie. Though the French chafe at attempts to represent the essence of Paris (see: reactions to Emily in Paris) the city does lean into its grandeur. Even along the smallest side streets, one can find luxury stores selling a variety of boutique goods. It is a modern-day Rumpelstiltskin story, weaving from simple textiles handbags and purses worth their weight in gold. If not of love, Paris must surely be the city of luxury, writ large by names like Wertheimer, Arnault, and Pinault.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There is a tendency to romanticize great cities. Paris stands proud among their ranks. The city of love, of wine and cheese, of baked aromas wafting out antique boulangeries, of foie gras and charcuterie. Though the French chafe at attempts to represent the essence of Paris (see: reactions to ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Emily in Paris",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ") the city does lean into its grandeur. Even along the smallest side streets, one can find luxury stores selling a variety of boutique goods. It is a modern-day Rumpelstiltskin story, weaving from simple textiles handbags and purses worth their weight in gold. If not of love, Paris must surely be the city of luxury, writ large by names like Wertheimer, Arnault, and Pinault.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We had a wonderful time in Paris. Our seven-day whirlwind tour took us from the Eiffel Tower to the Arc du Triomphe, from Le Jardin de Tuileries to Le Jardin de Luxembourg, from La Basilique Du Sacre-Coeur to Le Palais de Versailles. We dined throughout the city, exploring tastes and culture alike. The museums were incredible. Le Mus\u00e9e Rodin, Le Mus\u00e9e D\u2019Orsay, and the Louvre have all stolen my heart, and I am eager to return, to slow down and take it all in with fresh eyes. Some of my favorite memories were caught in moments of liminality. Two ducks asleep aside a rustling fountain. A warmly-lit alleyway after a bout of rain. Szechuan chicken at a hole-in-the-wall restaurant. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We had a wonderful time in Paris. Our seven-day whirlwind tour took us from the Eiffel Tower to the Arc du Triomphe, from Le Jardin de Tuileries to Le Jardin de Luxembourg, from La Basilique Du Sacre-Coeur to Le Palais de Versailles. We dined throughout the city, exploring tastes and culture alike. The museums were incredible. Le Mus\u00e9e Rodin, Le Mus\u00e9e D\u2019Orsay, and the Louvre have all stolen my heart, and I am eager to return, to slow down and take it all in with fresh eyes. Some of my favorite memories were caught in moments of liminality. Two ducks asleep aside a rustling fountain. A warmly-lit alleyway after a bout of rain. Szechuan chicken at a hole-in-the-wall restaurant. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "On our last night together, before she was to move into her home-stay and I was to return to the States, we took a stroll through the city to the seawall of the Seine. It was a brisk evening, made cooler by the wind that blew through the streets. I remember standing upon the bridge, looking out past the shifting currents towards the Eiffel Tower. By then, the lights had just turned off, the tower\u2019s trusses a dark silhouette against the night sky. As we stood on the bridge, I remember feeling a bittersweet mix of emotion. Bitter, that I was to leave come dawn. Sweet, that we were able to make so many beautiful memories together.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "On our last night together, before she was to move into her home-stay and I was to return to the States, we took a stroll through the city to the seawall of the Seine. It was a brisk evening, made cooler by the wind that blew through the streets. I remember standing upon the bridge, looking out past the shifting currents towards the Eiffel Tower. By then, the lights had just turned off, the tower\u2019s trusses a dark silhouette against the night sky. As we stood on the bridge, I remember feeling a bittersweet mix of emotion. Bitter, that I was to leave come dawn. Sweet, that we were able to make so many beautiful memories together.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I shall write again when we return. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I shall write again when we return. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}