export default {
  "metadata": {
    "num_words": 848
  },
  "content": [
    {
      "text": "Voices",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Voices",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "In the Fall of my frosh year, as I began diving into the vibrant, wonderful community that is Stanford, I began a small project called Voices. Largely inspired by the Humans of New York project, the idea behind it was simple, as was the blurb that I wrote when I first introduced it. It goes as follows:",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "In the Fall of my frosh year, as I began diving into the vibrant, wonderful community that is Stanford, I began a small project called ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Voices",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". Largely inspired by the ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Humans of New York",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " project, the idea behind it was simple, as was the blurb that I wrote when I first introduced it. It goes as follows:",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We see many faces throughout our day. But just how many of those do we truly see? Voices aims to bridge the gap between the familiar and the foreign; between those we know and those we do not yet know. Because within every one of us is a story waiting to be set free.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We see many faces throughout our day. But just how many of those do we truly see?",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Voices aims to bridge the gap between the familiar and the foreign; between those we know and those we do not yet know. Because within every one of us is a story waiting to be set free.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It was an ambitious project. But, like many of my other projects, Voices ultimately came to an end after a short but fiery effort. While it lasted, however, I had the pleasure of collecting four short interviews, narratives shared by new friends I met. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It was an ambitious project. But, like many of my other projects, ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Voices",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ultimately came to an end after a short but fiery effort. While it lasted, however, I had the pleasure of collecting four short interviews, narratives shared by new friends I met. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u201cI was a TA for a year, and I think that it was one of the experiences I\u2019m really glad to have, to lead a class and teach other students. It allows you to learn the material a lot better, and it\u2019s also a really unique experience that\u2019s hard to get if you never think about being a TA. You spend a lot of time with your students. I had four three-hour sections, so I spent at least twelve hours in the lab to stay after and help. I didn\u2019t like grading the tests \u2014 that was not my favorite. But I did like holding office hours and seeing the progress of my students. It\u2019s a very rewarding feeling. I think having a good TA can really enhance your learning experience in a class.\u201d",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u201cI was a TA for a year, and I think that it was one of the experiences I\u2019m really glad to have, to lead a class and teach other students. It allows you to learn the material a lot better, and it\u2019s also a really unique experience that\u2019s hard to get if you never think about being a TA. You spend a lot of time with your students. I had four three-hour sections, so I spent at least twelve hours in the lab to stay after and help. I didn\u2019t like grading the tests \u2014 that was not my favorite. But I did like holding office hours and seeing the progress of my students. It\u2019s a very rewarding feeling. I think having a good TA can really enhance your learning experience in a class.\u201d",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "John majored in Electrical Engineering and Computer Science and will be working at Google this coming fall. He will return to Stanford to work on a few remaining projects.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "John majored in Electrical Engineering and Computer Science and will be working at Google this coming fall. He will return to Stanford to work on a few remaining projects.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "(Tresidder Memorial Union)",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "(Tresidder Memorial Union)",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\n\u201cIt\u2019d be really nice to have some more motivation beyond \u201coh, that\u2019s cool, I want to learn more about it\u201d. Or just finally knowing what direction I want to go with my life. When I was applying to college, a lot of people said \u2018oh, I want to dedicate my life to researching this disease\u2019 or \u2018oh, I want to help humanity\u2019. I can respect that, but I probably would not be okay with doing that unless I genuinely thought researching that disease was interesting. I\u2019d just like to have some greater motivation or direction in life. I guess that\u2019s just the college existential angst. I think I\u2019m fine with not knowing, but it\u2019d be nice to know. Totally not contradictory.\u201d",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\n\u201cIt\u2019d be really nice to have some more motivation beyond \u201coh, that\u2019s cool, I want to learn more about it\u201d. Or just finally knowing what direction I want to go with my life. When I was applying to college, a lot of people said \u2018oh, I want to dedicate my life to researching this disease\u2019 or \u2018oh, I want to help humanity\u2019. I can respect that, but I probably would not be okay with doing that unless I genuinely thought researching that disease was interesting. I\u2019d just like to have some greater motivation or direction in life. I guess that\u2019s just the college existential angst. I think I\u2019m fine with not knowing, but it\u2019d be nice to know. Totally not contradictory.\u201d",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Nathan is a frosh living in Sally Ride this year. Originally from Indiana, he is currently intent on studying Computer Science and Mathematics during his time at Stanford.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Nathan is a frosh living in Sally Ride this year. Originally from Indiana, he is currently intent on studying Computer Science and Mathematics during his time at Stanford.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "(Stern, Sally Ride)",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "(Stern, Sally Ride)",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I miss my twin sister. That\u2019s pretty rough. Recently, she cut her hair super short, and it really got me the other night because I realized that her hair is growing and I\u2019m not there to see it grow. And that just makes me really sad. There\u2019s this one Saturday night where I went out to talk to my other friend, but then she talked about my sister, so I ended up crying in the hammock for two hours. She\u2019s on the semester system, so the last time I saw her was this past August.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I miss my twin sister. That\u2019s pretty rough. Recently, she cut her hair super short, and it really got me the other night because I realized that her hair is growing and I\u2019m not there to see it grow. And that just makes me really sad. There\u2019s this one Saturday night where I went out to talk to my other friend, but then she talked about my sister, so I ended up crying in the hammock for two hours. She\u2019s on the semester system, so the last time I saw her was this past August.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There was one weekend where we spontaneously went to two concerts in a row, then we went to another concert the week after. The first concert was the most spontaneous because we bought the tickets the night below to see Shawn Mendes. I love Shawn Mendes \u2014 he was at the Hollywood Bowl, so we went there for fifteen dollars. We were in the back, so we were just listening to other people and talking and hanging out. I wanted to film some of Shawn Mendes, so my sister filmed it for me just so I could appreciate the moment. That was really nice.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There was one weekend where we spontaneously went to two concerts in a row, then we went to another concert the week after. The first concert was the most spontaneous because we bought the tickets the night below to see Shawn Mendes. I love Shawn Mendes \u2014 he was at the Hollywood Bowl, so we went there for fifteen dollars. We were in the back, so we were just listening to other people and talking and hanging out. I wanted to film some of Shawn Mendes, so my sister filmed it for me just so I could appreciate the moment. That was really nice.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Originally from Los Angeles, Julie is a freshman living at Sally Ride, Stern. She has recently learned to eat ice cream with chopsticks, an impressive feat by many standards.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Originally from Los Angeles, Julie is a freshman living at Sally Ride, Stern. She has recently learned to eat ice cream with chopsticks, an impressive feat by many standards.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": " (The Axe and Palm)",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": " (The Axe and Palm)",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I\u2019m here with my boyfriend and he\u2019s busy and he\u2019s studying a lot and that means I\u2019m alone a lot of the time. The community makes me feel connected, though. Everybody is so close, and you can do anything. It never feels dangerous, so it feels good to be here. ",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I\u2019m here with my boyfriend and he\u2019s busy and he\u2019s studying a lot and that means I\u2019m alone a lot of the time. The community makes me feel connected, though. Everybody is so close, and you can do anything. It never feels dangerous, so it feels good to be here. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There\u2019s a lot that\u2019s great here. You can go to the gym. You have a meal plan. You can go to the pool. The libraries are great. You don\u2019t need a car; you can just bike everywhere. I\u2019m from Iceland, so I need to drive 10 minutes just to get anywhere. It\u2019s all just really good \u2014 it\u2019s hard to put anything first.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There\u2019s a lot that\u2019s great here. You can go to the gym. You have a meal plan. You can go to the pool. The libraries are great. You don\u2019t need a car; you can just bike everywhere. I\u2019m from Iceland, so I need to drive 10 minutes just to get anywhere. It\u2019s all just really good \u2014 it\u2019s hard to put anything first.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Eva is currently staying with her boyfriend on campus. Originally from Iceland, she hopes to study graphic design at Stanford this coming year.",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Eva is currently staying with her boyfriend on campus. Originally from Iceland, she hopes to study graphic design at Stanford this coming year.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "(Meyer Green)",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "(Meyer Green)",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}