export default {
  "metadata": {
    "num_words": 432
  },
  "content": [
    {
      "text": "Into the New Year",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Into the New Year",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "It is the first day of the New Year. I stand on the Port Washington docks, watching the last light of the setting sun. It is an overcast day and it is windy \u2014 enough so that the grey clouds above appear almost to be boiling. It is not all dreary. In the distance, just above the horizon, is a band of brilliant gold. And if you look closely at the sky, you\u2019ll see blue patches where the clouds crack to reveal clear skies beyond.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It is the first day of the New Year. I stand on the Port Washington docks, watching the last light of the setting sun. It is an overcast day and it is windy \u2014 enough so that the grey clouds above appear almost to be boiling. It is not all dreary. In the distance, just above the horizon, is a band of brilliant gold. And if you look closely at the sky, you\u2019ll see blue patches where the clouds crack to reveal clear skies beyond.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I had not noticed this in visits past, but if you lift your gaze from the horizon, up past the mansions that line the West Egg's waterfront, you\u2019ll see thin spires that reach into the clouds. There, on the left, is the Freedom Tower, and there, on the right, is the Empire State. The city\u2019s shadow looms large even here, so many miles away from the heart of it all. The shadow of the greatest city in the world.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I had not noticed this in visits past, but if you lift your gaze from the horizon, up past the mansions that line the West Egg's waterfront, you\u2019ll see thin spires that reach into the clouds. There, on the left, is the Freedom Tower, and there, on the right, is the Empire State. The city\u2019s shadow looms large even here, so many miles away from the heart of it all. The shadow of the greatest city in the world.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The seagulls hover just above the water beyond the pier, held aloft by the southerly winds. They squawk, challenging each other to dive into the waves beneath. Some oblige, diving into the Sound before emerging some distance away. I remember seeing a similar sight in San Francisco, on the cliffs of Land's End, of an eagle flying still in the air, as though commanded by an invisible puppeteer. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The seagulls hover just above the water beyond the pier, held aloft by the southerly winds. They squawk, challenging each other to dive into the waves beneath. Some oblige, diving into the Sound before emerging some distance away. I remember seeing a similar sight in San Francisco, on the cliffs of Land's End, of an eagle flying still in the air, as though commanded by an invisible puppeteer. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I watch as the golden band grows ever brighter, until at last, the sun emerges beneath the clouds. The world is aglow, the town comes alive, and the gray of winter retreats beneath the gold. It is a brief moment, but stunning, and soon after, the sun retreats from view once again. And then, the afterglow. The clouds burn a fierce orange, their edges a blood red, and the sky takes on a soft pink hue. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I watch as the golden band grows ever brighter, until at last, the sun emerges beneath the clouds. The world is aglow, the town comes alive, and the gray of winter retreats beneath the gold. It is a brief moment, but stunning, and soon after, the sun retreats from view once again. And then, the afterglow. The clouds burn a fierce orange, their edges a blood red, and the sky takes on a soft pink hue. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It is a beautiful sight. What a great way to herald in the year ahead.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It is a beautiful sight. What a great way to herald in the year ahead.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I have been away from the blog for some time now. Life, with its endless mysteries, has kept me rather busy for the past few months. But as the dust settles and the future comes into focus, I'm excited to create again. Here's to a year of passion, creation, and joy.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I have been away from the blog for some time now. Life, with its endless mysteries, has kept me rather busy for the past few months. But as the dust settles and the future comes into focus, I'm excited to create again. Here's to a year of passion, creation, and joy.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Happy New Year, to friends and family. The Old Year was a year of celebration in the face of loss, of growth in the face of failure. The New Year brings with it an infectious optimism for the year to come. May we create the worlds we can, at present, only dream of.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Happy New Year, to friends and family. The Old Year was a year of celebration in the face of loss, of growth in the face of failure. The New Year brings with it an infectious optimism for the year to come. May we create the worlds we can, at present, only dream of.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "E.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "E.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}