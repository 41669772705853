export default {
  2020: {
    type: 'image',
    title: '2020',
    date: '2020-01-01',
    excerpt: 'From a year of odyssey to a year of hope.',
    image: '2020.webp',
  },
  'ode-to-summer': {
    type: 'post',
    title: 'Ode to Summer',
    date: '2020-06-22',
    tags: ['Reflections'],
    excerpt:
      'On Summer and Stanford; post-Pandemic musings after a quarter spent at home.',
    file: 'odeToSummer.js',
    image: 'odeToSummer.webp',
    readingTime: 4,
  },
  'first-snow': {
    type: 'post',
    title: 'First Snow',
    date: '2020-12-17',
    tags: ['Creative Writing'],
    excerpt:
      "A love letter to Winter, and the fleeting beauty of the season's first snowfall.",
    file: 'firstSnow.js',
    image: 'firstSnow.webp',
    readingTime: 4,
  },
  'an-epochs-soul': {
    type: 'post',
    title: "An Epoch's Soul",
    date: '2020-12-23',
    tags: ['Reviews'],
    excerpt:
      'It is a novel about freedom, kindness, and humanity — their fragility and their strength, their universality.',
    file: 'anEpochsSoul.js',
    image: 'anEpochsSoul.webp',
    readingTime: 8,
    featured: true,
  },
  2021: {
    type: 'image',
    title: '2021',
    date: '2021-01-01',
    excerpt: 'From a year of appreciation to a year of promise.',
    image: '2021.webp',
  },
  meditations: {
    type: 'post',
    title: 'Meditations',
    date: '2021-01-01',
    tags: ['Journals'],
    excerpt:
      'After a month away from home and two cities explored: reflections on the chaos of this past month.',
    file: 'meditations.js',
    image: 'meditations.webp',
    readingTime: 5,
  },
  'tales-from-the-rim': {
    type: 'post',
    title: 'Tales from the Rim',
    date: '2021-07-25',
    tags: ['Stories'],
    excerpt:
      'A tale of three colonists marooned on a distant world, interspersed with reflections on stories: the ones we tell each other, and the ones we tell ourselves.',
    file: 'talesFromTheRim.js',
    image: 'talesFromTheRim.webp',
    readingTime: 11,
    featured: true,
  },
  'love-from-seattle': {
    type: 'post',
    title: 'Love, from Seattle',
    date: '2021-08-23',
    tags: ['Updates'],
    excerpt:
      'Reflections on Seattle, on our time in this wonderful city — if only for just three months.',
    file: 'loveFromSeattle.js',
    image: 'loveFromSeattle.webp',
    readingTime: 5,
  },
  'an-afternoon-away': {
    type: 'post',
    title: 'An Afternoon Away',
    date: '2021-12-15',
    tags: ['Reflections'],
    excerpt:
      'A short, wistful reflection on this campus I call home — written after my last final, right before winter break.',
    file: 'anAfternoonAway.js',
    image: 'anAfternoonAway.webp',
    readingTime: 4,
  },
  2022: {
    type: 'image',
    title: '2022',
    date: '2022-01-01',
    excerpt: 'From a year of budding to a year of full bloom.',
    image: '2022.webp',
  },
  voices: {
    type: 'post',
    title: 'Voices',
    date: '2022-06-24',
    tags: ['Projects'],
    excerpt:
      "A tribute to the Voices Project that I'd worked on my freshman year. Exploring just some of the stories from the campus we call home.",
    file: 'voices.js',
    image: 'voices.webp',
    readingTime: 6,
  },
  'box-making': {
    type: 'post',
    title: 'Box Making',
    date: '2022-07-01',
    tags: ['Projects'],
    excerpt:
      'During my two weeks back home, my father and I set to work on a box. The full process, comprising thirty hours of work spread across eleven days, is documented here.',
    file: 'boxMaking.js',
    image: 'boxMaking.webp',
    readingTime: 16,
  },
  'storm-in-midsummer': {
    type: 'post',
    title: 'Storm in Midsummer',
    date: '2022-07-08',
    tags: ['Reflections'],
    excerpt:
      "Scattered thoughts and poetic imaginings of New York's great summer storms, of their capricious temperament and destructive beauty.",
    file: 'stormInMidsummer.js',
    image: 'stormInMidsummer.webp',
  },
  'updates-from-fog-city': {
    type: 'post',
    title: 'Updates from Fog City',
    date: '2022-07-27',
    tags: ['Updates'],
    excerpt:
      'A quick update after my first month in San Francisco, with a roadmap for upcoming projects and reflections on the summer so far.',
    file: 'updatesFromFogCity.js',
    image: 'updatesFromFogCity.webp',
    readingTime: 4,
  },
  'at-the-end-of-time': {
    type: 'post',
    title: 'At the End of Time',
    date: '2022-08-03',
    tags: ['Stories'],
    excerpt:
      "From my listening of Leyland Kirby's Everywhere at the End of Time: a collection of thoughts and musings, loosely tied together by the idea of memory.",
    file: 'atTheEndOfTime.js',
    image: 'atTheEndOfTime.webp',
    readingTime: 12,
    featured: true,
  },
  'stories-from-a-world-forgotten': {
    type: 'post',
    title: 'Stories from a World Forgotten',
    date: '2022-08-19',
    tags: ['Stories'],
    excerpt:
      'A collection of stories and tall tales from my family, or rather, my attempt to translate them from our nuclear Chinese dialect.',
    file: 'storiesFromAWorldForgotten.js',
    image: 'storiesFromAWorldForgotten.webp',
    readingTime: 8,
    featured: true,
  },
  2023: {
    type: 'image',
    title: '2023',
    date: '2023-01-01',
    excerpt: 'From a year of new wonders into a year yet to be written.',
    image: '2023.webp',
  },
  'under-the-brooklyn-bridge': {
    type: 'post',
    title: 'Under the Brooklyn Bridge',
    date: '2023-01-08',
    tags: ['Vignettes'],
    excerpt:
      'A memory from a lifetime ago, of a sunset from the Brooklyn Bridge.',
    file: 'underTheBrooklynBridge.js',
    image: 'underTheBrooklynBridge.webp',
    readingTime: 2,
  },
  'life-death-and-the-little-things-in-between': {
    type: 'post',
    title: 'Life, Death, and the Little Things in Between',
    date: '2023-02-21',
    tags: ['Reflections'],
    excerpt:
      'A loose collection of thoughts and musings from these past few weeks.',
    file: 'lifeDeathAndTheLittleThingsInBetween.js',
    image: 'lifeDeathAndTheLittleThingsInBetween.webp',
    readingTime: 7,
  },
  'eden-in-paris': {
    type: 'post',
    title: 'Eden in Paris',
    date: '2023-04-17',
    tags: ['Journals'],
    excerpt:
      "Reflections on my one-week stay in Paris over Spring Break. Alternatively, an American's view of Paris.",
    file: 'edenInParis.js',
    image: 'edenInParis.webp',
    readingTime: 5,
  },
  baccalaureate: {
    type: 'post',
    title: 'Baccalaureate',
    date: '2023-05-17',
    tags: ['Projects'],
    excerpt:
      'Each year, Stanford hosts a Baccalaureate speechwriting contest. This is my submission, a reflection on this beautiful place I learned to call home.',
    file: 'baccalaureate.js',
    image: 'baccalaureate.webp',
    readingTime: 5,
    featured: true,
  },
  'not-quite-a-goodbye': {
    type: 'post',
    title: 'Not Quite a Goodbye',
    date: '2023-07-04',
    tags: ['Journals'],
    excerpt:
      "A brief retrospective on senior year, and outlooks on the summer. What's next?",
    file: 'notQuiteAGoodbye.js',
    image: 'notQuiteAGoodbye.webp',
    readingTime: 5,
  },
  'two-little-lives': {
    type: 'post',
    title: 'Two Little Lives',
    date: '2023-12-28',
    tags: ['Reviews'],
    excerpt:
      "A dual review of Amor Towles' A Gentleman in Moscow and Hanya Yanagihara's A Little Life.",
    file: 'twoLittleLives.js',
    image: 'twoLittleLives.webp',
    readingTime: 15,
  },
  2024: {
    type: 'image',
    title: '2024',
    date: '2024-01-01',
    excerpt: 'From a year of viscious constant to a year of boundless promise.',
    image: '2024.webp',
  },
  'on-the-outskirts-of-versailles': {
    type: 'post',
    title: 'On the Outskirts of Versailles',
    date: '2024-01-14',
    tags: ['Vignettes'],
    excerpt:
      'A memory from a lifetime ago, of a quiet moment shared on a rainy day, just outside Versailles.',
    file: 'onTheOutskirtsOfVersailles.js',
    image: 'onTheOutskirtsOfVersailles.webp',
    readingTime: 2,
  },
  'of-spring-and-summergrass': {
    type: 'post',
    title: 'Of Spring and Summergrass',
    date: '2024-03-24',
    tags: ['Journals'],
    excerpt: 'Musings from an afternoon spent cloudwatching under blue skies.',
    file: 'ofSpringAndSummergrass.js',
    image: 'ofSpringAndSummergrass.webp',
    readingTime: 3,
  },
  intermezzo: {
    type: 'post',
    title: 'Intermezzo',
    date: '2024-05-28',
    tags: ['Journals'],
    excerpt:
      'With twenty to days to go until graduation, I reflect on my time at Stanford, and on the uncertainty ahead.',
    file: 'intermezzo.js',
    image: 'intermezzo.webp',
    readingTime: 3,
  },
  'nagano-for-an-evening': {
    type: 'post',
    title: 'Nagano for an Evening',
    date: '2024-06-29',
    tags: ['Vignettes'],
    excerpt:
      'A memory from a lifetime ago, of a magical evening spent exploring Nagano.',
    file: 'naganoForAnEvening.js',
    image: 'naganoForAnEvening.webp',
    readingTime: 2,
  },
  'atop-mount-inari': {
    type: 'post',
    title: 'Atop Mount Inari',
    date: '2024-07-01',
    tags: ['Vignettes'],
    excerpt:
      'A memory from a lifetime ago, of hiking Mount Inari under sheets of silver rain.',
    file: 'atopMountInari.js',
    image: 'atopMountInari.webp',
    readingTime: 2,
  },
  'beneath-the-london-eye': {
    type: 'post',
    title: 'Beneath the London Eye',
    date: '2024-10-13',
    tags: ['Vignettes'],
    excerpt:
      'A memory from a lifetime ago, of a trip spent soul-searching in the grey city of London.',
    file: 'beneathTheLondonEye.js',
    image: 'beneathTheLondonEye.webp',
    readingTime: 2,
  },
  'ego-death': {
    type: 'post',
    title: 'Ego Death',
    date: '2024-10-22',
    tags: ['Creative Writing'],
    excerpt:
      'My best attempt at capturing, in words, my experience with ego death and rebirth.',
    file: 'egoDeath.js',
    image: 'egoDeath.webp',
    readingTime: 3,
  },
  2025: {
    type: 'image',
    title: '2025',
    date: '2025-01-01',
    excerpt: 'From a year of old endings to a year of new beginnings.',
    image: '2025.webp',
  },
  'into-the-new-year': {
    type: 'post',
    title: 'Into the New Year',
    date: '2025-01-01',
    tags: ['Journals'],
    excerpt:
      'In calm between storms, I pause to watch the sunset from Port Washington, and let the world crash over me.',
    file: 'intoTheNewYear.js',
    image: 'intoTheNewYear.webp',
    readingTime: 3,
  },
  'making-the-must': {
    type: 'post',
    title: 'Making the Must',
    date: '2025-01-10',
    tags: ['Projects'],
    excerpt:
      'Initial updates from my first two weeks spent experimenting with mead making.',
    file: 'makingTheMust.js',
    image: 'makingTheMust.webp',
    readingTime: 8,
  },
  'eden-built-anew': {
    type: 'post',
    title: 'Eden, Built Anew',
    date: '2025-01-17',
    tags: ['Updates'],
    excerpt:
      'Warm introductions to all; a short post announcing the launch of the new blog.',
    file: 'edenBuiltAnew.js',
    image: 'edenBuiltAnew.webp',
    readingTime: 2,
  },
};

