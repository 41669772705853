export default [
  {
    date: '2025-01-21',
    version: 'v1.3.0',
    changes:
      'Added post sort and filter functionality to the Blog page. Posts can now be sorted by date (ascending or descneding) and filtered by tag. This change also includes minor fixes to featured post rendering on mobile.',
  },
  {
    date: '2025-01-20',
    version: 'v1.2.2',
    changes:
      'Minor patch to integrate document titles with Google Analytics.\n\nMinor fixes include:\n- Verify posts are tagged with supported tags.\n- Render full post titles when filtering by tags.\n- Post images are now rendered in a more consistent manner.',
  },
  {
    date: '2025-01-16',
    version: 'v1.2.1',
    changes:
      'Minor patches and improvements to animations and rendering.\n\nFixes include:\n- Improved theme toggle animation.\n- Fixed a regression where the search would not query all post content.\n- Better enforce styling consistency across components.',
  },
  {
    date: '2025-01-15',
    version: 'v1.2.0',
    changes:
      'Added a new **Gallery** page, which includes a new carousel component for displaying images in a gallery format. Clicking on an image opens a full-screen carousel modal.\n\nFixes include:\n- Minor improvements to **About** page animation.\n- Optimize .webp images to be more performant.',
  },
  {
    date: '2025-01-15',
    version: 'v1.1.1',
    changes:
      'Minor patches and visual improvements to various components.\n\nFixes include:\n- Changelog entries now transition when the theme changes.\n- Improvements to the feel of the **About** page.\n- Footer links can now be right-clicked to open in a new tab.',
  },
  {
    date: '2025-01-14',
    version: 'v1.1.0',
    changes:
      'Minor improvements to the blog, particularly with respect to the mobile experience.\n\nFixes include:\n- Cleaner animation when shifting between featured posts.\n- The **About** page is complete and includes a small easter egg.\n- Post tags are now rendered to be more intuively clickable.',
  },
  {
    date: '2025-01-11',
    version: 'v1.0.0',
    changes:
      'First major release of the updated **edenincorporated** site. The site has been built with mobile- and desktop-compatibility in mind and supports a light- and dark-mode theme for improved readability (hint: try clicking the sun / moon icon in the menu).\n\nOn the roadmap:\n- Improve post rendering and support custom styling for individual posts.\n- Build in newsletter functionality reminiscent of TinyLetter.\n- Release refactored source code & setup instructions for an OpenBlog project.',
  },
];
