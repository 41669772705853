export default {
  "metadata": {
    "num_words": 1034
  },
  "content": [
    {
      "text": "Life, Death, and the Little Things in Between",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Life, Death, and the Little Things in Between",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "Winter quarter has been busy. Since arriving back at Stanford, I find myself dashing non-stop from classes to projects to work. There is a subtle structure to this chaos. Each day begins and ends with the same two rituals, each lunch and dinner is scheduled for the same fixed times, each assignment is due by the same weekly deadlines. The days blur together. Each week seems near impossible until suddenly, I find myself looking back and wondering how I managed to get through it at all. I am more than halfway through my senior year at Stanford, months away from graduating, and though I may stay a fifth year, I become increasingly aware that my days as a student are numbered. Now, more than ever, I find myself thinking about what kind of life I\u2019d like to build for myself, where I\u2019d like to see myself ten, twenty years down the road. What, truly, does it mean to live a good life? The question burns itself into my mind.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Winter quarter has been busy. Since arriving back at Stanford, I find myself dashing non-stop from classes to projects to work. There is a subtle structure to this chaos. Each day begins and ends with the same two rituals, each lunch and dinner is scheduled for the same fixed times, each assignment is due by the same weekly deadlines. The days blur together. Each week seems near impossible until suddenly, I find myself looking back and wondering how I managed to get through it at all. I am more than halfway through my senior year at Stanford, months away from graduating, and though I may stay a fifth year, I become increasingly aware that my days as a student are numbered. Now, more than ever, I find myself thinking about what kind of life I\u2019d like to build for myself, where I\u2019d like to see myself ten, twenty years down the road. What, truly, does it mean to live a good life? The question burns itself into my mind.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "As a child in grade school, I would watch the second hand carve out its gentle orbit around the clock. Back then, it seemed as though I could force time to a crawl just by concentrating hard enough. But as the years have passed, time has also seemed to speed up. Ever-lasting days and never-ending nights now pass by in an instant. Weeks and months that once felt a lifetime now pass faster than I can turn the calendar page. A few days ago, after waking to an early alarm, I spent a few moments watching the second hand sweep its arc from bed. I focused, trying to slow down the clock, fighting against the viscous current of time. For just a fleeting moment, time was still, but then, just as quickly, it passed. The moment was gone, the silence was shattered. The rest of the day came and went before I knew it.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "As a child in grade school, I would watch the second hand carve out its gentle orbit around the clock. Back then, it seemed as though I could force time to a crawl just by concentrating hard enough. But as the years have passed, time has also seemed to speed up. Ever-lasting days and never-ending nights now pass by in an instant. Weeks and months that once felt a lifetime now pass faster than I can turn the calendar page. A few days ago, after waking to an early alarm, I spent a few moments watching the second hand sweep its arc from bed. I focused, trying to slow down the clock, fighting against the viscous current of time. For just a fleeting moment, time was still, but then, just as quickly, it passed. The moment was gone, the silence was shattered. The rest of the day came and went before I knew it.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "A few weeks ago, I found myself thinking back on my time as a dancer. It\u2019s been four years since I started dancing at Stanford. A lifetime ago, it seems, a pre-pandemic world it had been. Looking back, life felt simpler: life was a canvas blank as snow, California was a fever dream, and I could hardly place Kyiv on the map. Yet the memories of dancing on a new team in a new campus, nervous and alone, still feel so incredibly vivid. I had been eighteen then, and the possibilities of who I could become left me with a sense of wanderlust. An infinite number of possibilities there had been, another infinity that has been lost, narrowed down day by day until arriving at the road that has become my life and the new worlds it brings. I have few regrets about how my time has been spent. My time at Stanford has taught me so much.  ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "A few weeks ago, I found myself thinking back on my time as a dancer. It\u2019s been four years since I started dancing at Stanford. A lifetime ago, it seems, a pre-pandemic world it had been. Looking back, life felt simpler: life was a canvas blank as snow, California was a fever dream, and I could hardly place Kyiv on the map. Yet the memories of dancing on a new team in a new campus, nervous and alone, still feel so incredibly vivid. I had been eighteen then, and the possibilities of who I could become left me with a sense of wanderlust. An infinite number of possibilities there had been, another infinity that has been lost, narrowed down day by day until arriving at the road that has become my life and the new worlds it brings. I have few regrets about how my time has been spent. My time at Stanford has taught me so much.  ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There are days when entire years-worth of memories are made. There are years when just days seem to pass. I hope to never experience the latter. I am terrified by the passage of time. I fear waking up one morning and realizing that years have gone by without appreciating my place on this earth, the privilege it is to be a friend, a lover, a son. I fear that this life will pass me by, that time will sweep me up in its rushing torrent as it has this quarter. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There are days when entire years-worth of memories are made. There are years when just days seem to pass. I hope to never experience the latter. I am terrified by the passage of time. I fear waking up one morning and realizing that years have gone by without appreciating my place on this earth, the privilege it is to be a friend, a lover, a son. I fear that this life will pass me by, that time will sweep me up in its rushing torrent as it has this quarter. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Two weeks ago, she came down with a mild bout of Covid while I, miraculously, did not. During her six-day isolation period, we met by Lake Lagunita for masked walks by the shore. The lake is a beautiful place; the winter storms have endowed it with a beauty more stunning than anything I could ever imagine. And with the lake a sapphire mirror, wildlife has slowly returned. On our walks together, we saw egrets and vultures, falcons and herons. Oh, and ducks, so many ducks. Four pairs of lovely mallard couples and a lone male, to be exact, all of whom wade in the shallow waters, dipping their heads into the waters and poking their tails to the sky. When I think back on this quarter, those walks stand out so vividly against a backdrop of assignments, commitments, and projects. These were moments of serendipity, when the immediacy of individual obligations fell away to reveal the beauty of the world that we live in. Life can be good, sometimes.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Two weeks ago, she came down with a mild bout of Covid while I, miraculously, did not. During her six-day isolation period, we met by Lake Lagunita for masked walks by the shore. The lake is a beautiful place; the winter storms have endowed it with a beauty more stunning than anything I could ever imagine. And with the lake a sapphire mirror, wildlife has slowly returned. On our walks together, we saw egrets and vultures, falcons and herons. Oh, and ducks, so many ducks. Four pairs of lovely mallard couples and a lone male, to be exact, all of whom wade in the shallow waters, dipping their heads into the waters and poking their tails to the sky. When I think back on this quarter, those walks stand out so vividly against a backdrop of assignments, commitments, and projects. These were moments of serendipity, when the immediacy of individual obligations fell away to reveal the beauty of the world that we live in. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Life can be good, sometimes",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ".",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Our walks around the lake remind me of what matters most to me. Seeing her smile and hearing her laugh means more to me than any achievement. What does professional success mean if I cannot spend time with the ones I love? What does wealth mean if I cannot care for my family and friends? I am no less ambitious, no less driven, but I begin to recognize the nuanced considerations that must drive my work. In a heartbeat, I would give up a life of fame and fortune if I may spend an extra hour each day with the people that matter most to me. Life is truly too short. I don\u2019t want to regret a single moment of it.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Our walks around the lake remind me of what matters most to me. Seeing her smile and hearing her laugh means more to me than any achievement. What does professional success mean if I cannot spend time with the ones I love? What does wealth mean if I cannot care for my family and friends? I am no less ambitious, no less driven, but I begin to recognize the nuanced considerations that must drive my work. In a heartbeat, I would give up a life of fame and fortune if I may spend an extra hour each day with the people that matter most to me. Life is truly too short. I don\u2019t want to regret a single moment of it.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "After returning from a wedding in Palm Springs, I caught a vision of what life could look like a decade from now. Friends, scattered across the country, around the world, yet bound by strong ties formed in youth, reminiscing about times long past. Success, not just professional but personal, that manifests itself in a calm confidence and quiet self-assuredness. A life of love, of having loved and been loved, of joys, created and indulged, of wonder, constantly pursued and always treasured. For a moment, I caught a glimpse of the life that lies ahead if I\u2019m eager and determined to work for it. And it is a beautiful life, a life brimming with possibilities. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "After returning from a wedding in Palm Springs, I caught a vision of what life could look like a decade from now. Friends, scattered across the country, around the world, yet bound by strong ties formed in youth, reminiscing about times long past. Success, not just professional but personal, that manifests itself in a calm confidence and quiet self-assuredness. A life of love, of having loved and been loved, of joys, created and indulged, of wonder, constantly pursued and always treasured. For a moment, I caught a glimpse of the life that lies ahead if I\u2019m eager and determined to work for it. And it is a beautiful life, a life brimming with possibilities. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The vision fades, but this time, my mind feels clearer. The road ahead is no less uncertain, but I don\u2019t need it to be. All I need is a promise \u2014 a promise of what could be \u2014 and I\u2019ll hold that to my heart as I leap into the unknown. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The vision fades, but this time, my mind feels clearer. The road ahead is no less uncertain, but I don\u2019t need it to be. All I need is a promise \u2014 a promise of what could be \u2014 and I\u2019ll hold that to my heart as I leap into the unknown. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}