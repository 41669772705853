import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import styles from '../PostPage.module.css';

const AnimatedParagraph = ({ paragraph, variant }) => {
  const [visibleWordsCount, setVisibleWordsCount] = useState(0);

  const parseParagraphContent = (paragraphContent) => {
    const words = [];

    paragraphContent.forEach((fragment) => {
      const splitWords = fragment.text.split(' ');

      splitWords.forEach((word, index) => {
        const finalWord = index < splitWords.length - 1 ? `${word} ` : word;

        words.push({
          text: finalWord,
          hyperlink: fragment.hyperlink,
          bold: fragment.bold,
          italic: fragment.italic,
          underline: fragment.underline,
          color: fragment.color,
          style: fragment.style,
        });
      });
    });

    return words;
  };

  const words = parseParagraphContent(paragraph.content);

  useEffect(() => {
    if (!words.length) return;
    const TOTAL_DURATION = 1500;
    const MIN_INTERVAL_SPEED = 20;
    const intervalSpeed = Math.min(
      MIN_INTERVAL_SPEED,
      TOTAL_DURATION / words.length
    );

    const intervalId = setInterval(() => {
      setVisibleWordsCount((prevCount) => {
        if (prevCount >= words.length) {
          clearInterval(intervalId);
          return prevCount;
        }
        return prevCount + 1;
      });
    }, intervalSpeed);

    return () => clearInterval(intervalId);
  }, [words.length]);

  const renderWord = (wordObj, i) => {
    const commonStyles = {
      fontWeight: wordObj.bold ? 'bold' : 'normal',
      fontStyle: wordObj.italic ? 'italic' : 'normal',
      textDecoration: wordObj.underline ? 'underline' : 'none',
      color: wordObj.color,
    };

    if (wordObj.hyperlink) {
      return (
        <a
          key={i}
          href={wordObj.hyperlink}
          style={{
            ...commonStyles,
            color: '#B12AFFFF',
          }}
        >
          {wordObj.text}
        </a>
      );
    }

    return (
      <span key={i} style={commonStyles}>
        {wordObj.text}
      </span>
    );
  };

  const isListParagraph = paragraph.style === 'List Paragraph';

  return (
    <Typography
      variant={variant}
      component={isListParagraph ? 'li' : 'p'}
      className={styles.paragraph}
      sx={{
        marginBottom: '1rem',
        textAlign: paragraph.alignment?.toLowerCase() || 'left',
        listStyleType: isListParagraph ? 'disc' : 'none',
        marginLeft: isListParagraph ? '1.5rem' : '0',
        display: 'list-item',
      }}
    >
      {words
        .slice(0, visibleWordsCount)
        .map((wordObj, i) => renderWord(wordObj, i))}
    </Typography>
  );
};

export default AnimatedParagraph;
