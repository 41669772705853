export default {
  "metadata": {
    "num_words": 1249
  },
  "content": [
    {
      "text": "Making the Must",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Making the Must",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "Fermentation and civilization are inseparable, John Ciard",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Fermentation and civilization are inseparable, ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "John Ciard",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Fermenting my own mead has been a bucket list hobby of mine for quite some time. Late last month, I finally gave it a shot. I sourced the materials and studied up on the process, then, after work one evening, put it all together to make my first two batches of mead must. Both batches are happily fermenting away as I write this.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Fermenting my own mead has been a bucket list hobby of mine for quite some time. Late last month, I finally gave it a shot. I sourced the materials and studied up on the process, then, after work one evening, put it all together to make my first two batches of mead must. Both batches are happily fermenting away as I write this.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "My main takeaway so far: home brewing is equal parts science and magic, with the scale tipping towards either depending on your willingness to get the details exactly right. Mead making, however, affords a degree of imperfection along the way. It\u2019s a great starting point for home brewers looking to get initiated.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "My main takeaway so far: home brewing is equal parts science and magic, with the scale tipping towards either depending on your willingness to get the details exactly right. Mead making, however, affords a degree of imperfection along the way. It\u2019s a great starting point for home brewers looking to get initiated.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Below is an account of the steps I took to get started, how things have gone during the first week of fermentation, and learnings along the way. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Below is an account of the steps I took to get started, how things have gone during the first week of fermentation, and learnings along the way. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Materials & Ingredients",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Materials & Ingredients",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "As a first-time brewer, I did things by the book. I curated a set of rough instructions over the course of a week spent studying home brewing blogs and Reddit mead-making communities. For materials, I bought: ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "As a first-time brewer, I did things by the book. I curated a set of rough instructions over the course of a week spent studying home brewing blogs and Reddit mead-making communities. For materials, I bought: ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Four one-gallon demijohns with fitting bungs, airlocks, and bubblers.",
      "alignment": null,
      "style": "List Paragraph",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Four one-gallon demijohns with fitting bungs, airlocks, and bubblers.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Several packets of Lalvin wine yeast (EC-1118) and a pack of yeast nutrition (Fermaid-O).",
      "alignment": null,
      "style": "List Paragraph",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Several packets of Lalvin wine yeast (EC-1118) and a pack of yeast nutrition (Fermaid-O).",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Sterilizer (Star San).",
      "alignment": null,
      "style": "List Paragraph",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Sterilizer (Star San).",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "For brewing ingredients, I bought: ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "For brewing ingredients, I bought: ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "A bag of dried elderflowers, for an elderflower-infused mead.",
      "alignment": null,
      "style": "List Paragraph",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "A bag of dried elderflowers, for an elderflower-infused mead.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Dried cloves, raisins, and cinnamon sticks, for additional flavor notes.",
      "alignment": null,
      "style": "List Paragraph",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Dried cloves, raisins, and cinnamon sticks, for additional flavor notes.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Wildflower honey and maple syrup, both from Costco.",
      "alignment": null,
      "style": "List Paragraph",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Wildflower honey and maple syrup, both from Costco.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "All in all, with decent quality ingredients and a standard set of materials, the cost of getting started was just over $150. I'm sure that, with sufficient optimization, a wise brewer can source their kit for even cheaper.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "All in all, with decent quality ingredients and a standard set of materials, the cost of getting started was just over $150. I'm sure that, with sufficient optimization, a wise brewer can source their kit for even cheaper.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Getting Started",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Getting Started",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I primarily referenced these several blogs for my first two batches of mead. Each batch ended up producing two gallons of raw must, for a total of four gallons. If you\u2019re thinking about making one gallon, you might as well make two. It\u2019ll end up taking the same amount of time.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I primarily referenced ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "these",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": "https://homebrewanswers.com/how-to-make-mead/"
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "several",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": "https://blackstonevalleybrewing.com/getting-started-with-mead/"
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "blogs",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": "https://www.growforagecookferment.com/how-to-make-elderberry-mead/"
        },
        {
          "text": " for my first two batches of mead. Each batch ended up producing two gallons of raw must, for a total of four gallons. If you\u2019re thinking about making one gallon, you might as well make two. It\u2019ll end up taking the same amount of time.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "If there\u2019s one thing vibes-based brewers and science-based brewers can agree on, it\u2019s that proper sanitation is the most critical step of brewing, regardless of what you make. Contamination can produce off-notes and unpleasant flavors that can ruin months of work. A little goes a long way. Brewers who don\u2019t properly sanitize may get lucky for a while, but they\u2019ll inevitably run into a spoiled batch. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "If there\u2019s one thing vibes-based brewers and science-based brewers can agree on, it\u2019s that proper sanitation is the most critical step of brewing, regardless of what you make. Contamination can produce off-notes and unpleasant flavors that can ruin months of work. A little goes a long way. Brewers who don\u2019t properly sanitize may get lucky for a while, but they\u2019ll inevitably run into a spoiled batch. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Star San is a no-rinse, acid-based sanitizer that can be easily mixed with water and stored in a spray bottle. As my equipment was newly purchased, sterilizing was a matter of spraying down all surfaces and rinsing with tap water.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Star San is a no-rinse, acid-based sanitizer that can be easily mixed with water and stored in a spray bottle. As my equipment was newly purchased, sterilizing was a matter of spraying down all surfaces and rinsing with tap water.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "(Brewers usually advocate for using boiled water. My home water system filters out bacteria, so I felt safe enough using tap water to rinse after each sanitization step.)",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "(Brewers usually advocate for using boiled water. My home water system filters out bacteria, so I felt safe enough using tap water to rinse after each sanitization step.)",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Assuming all your materials and ingredients are within reach (and sanitized), the first step is to hydrate your yeast. A single packet goes a long way: I brewed all four gallons with just two packets of yeast. After mixing the yeast into lukewarm water and adding in a teaspoon of yeast nutrition, I covered the yeast with saran wrap and let it sit off to the side. You could be a jerk and toss the yeast in without this step. I just thought it wise to get the party started.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Assuming all your materials and ingredients are within reach (and sanitized), the first step is to hydrate your yeast. A single packet goes a long way: I brewed all four gallons with just two packets of yeast. After mixing the yeast into lukewarm water and adding in a teaspoon of yeast nutrition, I covered the yeast with saran wrap and let it sit off to the side. You could be a jerk and toss the yeast in without this step. I just thought it wise to get the party started.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The must is the raw mix of honey, water, and other ingredients that is consumed over the course of fermentation (think: must is to mead as wort is to beer). Making the must is fairly simple: bring half a gallon of water to around 100 degrees, then dissolve in the honey. Don\u2019t bring the mix to a boil \u2014 doing so will break down the honey and risk losing its distinctive flavor profile \u2014 instead, just stir on low heat until the mixture is fully dissolved.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The must is the raw mix of honey, water, and other ingredients that is consumed over the course of fermentation (think: must is to mead as wort is to beer). Making the must is fairly simple: bring half a gallon of water to around 100 degrees, then dissolve in the honey. Don\u2019t bring the mix to a boil \u2014 doing so will break down the honey and risk losing its distinctive flavor profile \u2014 instead, just stir on low heat until the mixture is fully dissolved.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There is much room for experimentation at this step. My first batch was a simple mix consisting of 5 lbs of honey and water. My second batch was more adventurous, with 3 lbs of honey and 1.5 lbs of maple syrup, along with cloves and cinnamon. The first will produce a classic mead. The second will produce acerglyn, which should hopefully retain notes of maple in the end product.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There is much room for experimentation at this step. My first batch was a simple mix consisting of 5 lbs of honey and water. My second batch was more adventurous, with 3 lbs of honey and 1.5 lbs of maple syrup, along with cloves and cinnamon. The first will produce a classic mead. The second will produce acerglyn, which should hopefully retain notes of maple in the end product.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "(The quality of your honey is one of the most important factors during the mead-making process. Many brewers advocate for first-timers to start with wildflower honey and clove-infused honey, which should produce a nuttier end product)",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "(The quality of your honey is one of the most important factors during the mead-making process. Many brewers advocate for first-timers to start with wildflower honey and clove-infused honey, which should produce a nuttier end product)",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The next step is to add in whatever fruit or herbs you intend to infuse your mead with. Adding oranges and raisins to your mix is typically recommended as an easy way to provide nitrogen for your yeast, but the consensus is mixed on whether or not it\u2019s actually useful. Some brewers believe raisins add off-flavors to the end product. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The next step is to add in whatever fruit or herbs you intend to infuse your mead with. Adding oranges and raisins to your mix is typically recommended as an easy way to provide nitrogen for your yeast, but the consensus is mixed on whether or not it\u2019s actually useful. Some brewers believe raisins add off-flavors to the end product. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The most scientific response I found claimed that a single teaspoon of yeast nutrient had the equivalent nitrogen content of a hundred raisins. I shrugged and added raisins to both batches anyways.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The most scientific response I found claimed that a single teaspoon of yeast nutrient had the equivalent nitrogen content of a hundred raisins. I shrugged and added raisins to both batches anyways.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "To infuse my first batch, I added a cup of dried elderflowers both demijohn flasks. For my second, I added half an orange each (with their peels). Then, I poured the must in, which filled the demijohns halfway full. I added the hydrated yeast, then topped them off with distilled water, leaving around three inches of air. I capped the flask, shook the mixture to aerate the yeast, then topped each flask off with a three-piece airlock.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "To infuse my first batch, I added a cup of dried elderflowers both demijohn flasks. For my second, I added half an orange each (with their peels). Then, I poured the must in, which filled the demijohns halfway full. I added the hydrated yeast, then topped them off with distilled water, leaving around three inches of air. I capped the flask, shook the mixture to aerate the yeast, then topped each flask off with a three-piece airlock.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "And that was that. I hauled the flasks down to my basement and left the yeast to begin their magic. From here, the rest is waiting.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "And that was that. I hauled the flasks down to my basement and left the yeast to begin their magic. From here, the rest is waiting.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "How It\u2019s Going",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "How It\u2019s Going",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It has been over a week since both batches were prepared. All four gallons are happily fermenting away in my basement. It is around 70 degrees and dark in the room the flasks are kept. Unlike in beer brewing, light won\u2019t ruin the batch, but it\u2019s still generally preferred to keep yeast in darkness. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It has been over a week since both batches were prepared. All four gallons are happily fermenting away in my basement. It is around 70 degrees and dark in the room the flasks are kept. Unlike in beer brewing, light won\u2019t ruin the batch, but it\u2019s still generally preferred to keep yeast in darkness. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Primary fermentation takes around 2-3 weeks. Secondary fermentation can last up to a year. You can start drinking the mead after three months, but like wine, the flavors develop and age over time. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Primary fermentation takes around 2-3 weeks. Secondary fermentation can last up to a year. You can start drinking the mead after three months, but like wine, the flavors develop and age over time. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Brewers tend to have strong opinions about best practices at this stage.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Brewers tend to have strong opinions about best practices at this stage.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Some brewers advocate for daily aeration during the first few days of primary fermentation. The idea here is to give the yeast enough oxygen to quickly grow and form a stronger fermenting base. Other brewers caution that aeration can oxidize the product and produce off flavors. Most brewers generally agree that aeration during the first week of fermentation is a good idea.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Some brewers advocate for daily aeration during the first few days of primary fermentation. The idea here is to give the yeast enough oxygen to quickly grow and form a stronger fermenting base. Other brewers caution that aeration can oxidize the product and produce off flavors. Most brewers generally agree that aeration during the first week of fermentation is a good idea.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There\u2019s also debate on the importance of yeast nutrition. Laissez-faire brewers will typically be incredibly minimal and rely solely on the honey and raisins to sustain their yeast. More industrial brewers recommend supplying nutrition in staggered intervals over the course of primary fermentation. From what I saw, there\u2019s no clear consensus on what\u2019s \u201cright\u201d here. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There\u2019s also debate on the importance of yeast nutrition. Laissez-faire brewers will typically be incredibly minimal and rely solely on the honey and raisins to sustain their yeast. More industrial brewers recommend supplying nutrition in staggered intervals over the course of primary fermentation. From what I saw, there\u2019s no clear consensus on what\u2019s \u201cright\u201d here. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I took a fairly na\u00efve and simple approach. Each evening over this past week, I replaced the airlock with a (sterilized) cap, then shook the flask for several minutes before replacing the cap with the (sterilized) airlock. This process also helps release pressure in the must. Every other day, I\u2019ll add in another teaspoon of yeast nutrition then swirl it in to keep the fermentation healthy.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I took a fairly na\u00efve and simple approach. Each evening over this past week, I replaced the airlock with a (sterilized) cap, then shook the flask for several minutes before replacing the cap with the (sterilized) airlock. This process also helps release pressure in the must. Every other day, I\u2019ll add in another teaspoon of yeast nutrition then swirl it in to keep the fermentation healthy.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "So far, so good. The must is actively fermenting, and quite heavily at that.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "So far, so good. The must is actively fermenting, and quite heavily at that.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}