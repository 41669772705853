import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from '../Navbar.module.css';

const SearchResults = ({
  isSearching,
  hasSearched,
  searchResults,
  lastSearchQuery,
  renderedItems,
  setRenderedItems,
  handleClose,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSearching && searchResults.length > 0 && hasSearched) {
      const renderNextItem = () => {
        for (let i = 0; i < searchResults.length; i++) {
          const result = searchResults[i];

          const titleAlreadyRendered = renderedItems.some(
            (item) => item.type === 'title' && item.resultIndex === i
          );

          if (!titleAlreadyRendered) {
            setRenderedItems((prev) => [
              ...prev,
              { type: 'title', resultIndex: i },
            ]);
            setTimeout(renderNextItem, 250);
            return;
          }

          const renderedContextIndices = renderedItems
            .filter((item) => item.type === 'context' && item.resultIndex === i)
            .map((item) => item.contextIndex);

          for (let j = 0; j < result.context.length; j++) {
            if (!renderedContextIndices.includes(j)) {
              setRenderedItems((prev) => [
                ...prev,
                { type: 'context', resultIndex: i, contextIndex: j },
              ]);
              setTimeout(renderNextItem, 250);
              return;
            }
          }
        }
      };

      renderNextItem();
    }
  }, [isSearching, searchResults, renderedItems, hasSearched]);

  const handleResultClick = (result) => {
    navigate(`/blog/${result.id}`);
    handleClose();
  };

  return (
    <Box className={styles.searchResults}>
      <Box className={styles.searchResultsHeader}>
        <Typography variant="h5">
          Found {searchResults.length} results for "{lastSearchQuery}"
        </Typography>
      </Box>
      {searchResults.map((result, resultIndex) => (
        <Box key={result.id} className={styles.searchResult}>
          {renderedItems.some(
            (item) => item.type === 'title' && item.resultIndex === resultIndex
          ) && (
            <Typography
              variant="h5"
              onClick={() => handleResultClick(result)}
              sx={{
                animation: 'fadeIn 0.5s ease-in',
                '@keyframes fadeIn': {
                  '0%': { opacity: 0 },
                  '100%': { opacity: 1 },
                },
              }}
            >
              {result.title
                .split(
                  new RegExp(
                    `(${lastSearchQuery}|${lastSearchQuery.toLowerCase()}|${lastSearchQuery.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '')})`,
                    'gi'
                  )
                )
                .map((part, i) =>
                  new RegExp(
                    `^(${lastSearchQuery}|${lastSearchQuery.toLowerCase()}|${lastSearchQuery.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '')})$`,
                    'i'
                  ).test(part) ? (
                    <strong key={i}>{part}</strong>
                  ) : (
                    part
                  )
                )}
            </Typography>
          )}
          {result.context.map(
            (contextItem, contextIndex) =>
              renderedItems.some(
                (item) =>
                  item.type === 'context' &&
                  item.resultIndex === resultIndex &&
                  item.contextIndex === contextIndex
              ) && (
                <Typography
                  key={contextIndex}
                  variant="body2"
                  onClick={() => handleResultClick(result)}
                  sx={{
                    ml: 2,
                    marginTop: '1rem',
                    animation: 'fadeIn 0.5s ease-in',
                    '@keyframes fadeIn': {
                      '0%': { opacity: 0 },
                      '100%': { opacity: 1 },
                    },
                  }}
                >
                  {contextItem.snippet
                    .split(
                      new RegExp(
                        `(${lastSearchQuery}|${lastSearchQuery.toLowerCase()}|${lastSearchQuery.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '')})`,
                        'gi'
                      )
                    )
                    .map((part, i) =>
                      new RegExp(
                        `^(${lastSearchQuery}|${lastSearchQuery.toLowerCase()}|${lastSearchQuery.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '')})$`,
                        'i'
                      ).test(part) ? (
                        <strong key={i}>{part}</strong>
                      ) : (
                        part
                      )
                    )}
                </Typography>
              )
          )}
        </Box>
      ))}
    </Box>
  );
};

export default SearchResults;
