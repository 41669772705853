export default {
    "title": "Sunsets",
    "description": "Of sun and sea and summergrass.",
    "source": [
        {
            "image": "01.webp",
            "date": "2024-07-16",
            "description": "Captured in Yellowstone Park, in a glacier carved valley filled with wildflowers."},
        {
            "image": "02.webp",
            "date": "2023-05-29",
            "description": "Captured during Spring at Stanford, while descending the steps of EVGR."},
        {
            "image": "03.webp",
            "date": "2021-08-03",
            "description": "Captured from the slope of Olympic Sculpture Park."
        },
        {
            "image": "04.webp",
            "date": "2023-02-14",
            "description": "Captured along the shore of Lake Lagunita, after a winter heavy with rain."
        },
        {
            "image": "05.webp",
            "date": "2023-03-25",
            "description": "Captured during Spring Break in Monterey, with the Asian American Issues ASB."
        },
        {
            "image": "06.webp",
            "date": "2022-09-02",
            "description": "Captured along a pebble beach in Mukilteo, WA, with Binh as my guide."
        },
        {
            "image": "07.webp",
            "date": "2024-11-09",
            "description": "Captured in Port Washington, on a day filled with smoke from Jersey wildfires."
        },
        {
            "image": "08.webp",
            "date": "2024-07-15",
            "description": "Captured in Salt Lake City, on a gentle hill park overlooking the city."
        },
        {
            "image": "09.webp",
            "date": "2021-05-31",
            "description": "Captured in Land's End, with my friend Natalie in frame, on the steps."
        },
        {
            "image": "10.webp",
            "date": "2024-03-25",
            "description": "Captured during dawn in Singapore, with the helix bridge in the background."
        },
        {
            "image": "11.webp",
            "date": "2023-02-13",
            "description": "Captured along the Lake, with the waters aglow with the colors of the sunset."
        }
    ]
}