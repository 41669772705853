export default {
  "metadata": {
    "num_words": 1283
  },
  "content": [
    {
      "text": "Stories from a World Forgotten",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Stories from a World Forgotten",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "The greatest stories are often gentle lies, for the world is rarely so neat that we may extract lessons and tie them together cleanly with a bow on top. It is only with time, after the details blur together and the line between fact and fantasy becomes muddied, that we might begin to make sense of it all. The following are a collection of stories from my parents, or rather, my attempt to translate them from our nuclear Chinglish. Some of these stories have gone through many iterations of retelling, but while dates and details may have changed, within each story remains a kernel of precious truth. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The greatest stories are often gentle lies, for the world is rarely so neat that we may extract lessons and tie them together cleanly with a bow on top. It is only with time, after the details blur together and the line between fact and fantasy becomes muddied, that we might begin to make sense of it all. The following are a collection of stories from my parents, or rather, my attempt to translate them from our nuclear Chinglish. Some of these stories have gone through many iterations of retelling, but while dates and details may have changed, within each story remains a kernel of precious truth. ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2767\u2767 \u2766 \u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2767\u2767",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2766",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Years ago, my mother told me a story of how our family came to be in Anhui, along the banks of the Yangtze. As the story goes, one of our ancestors had been a philosopher during the reign of the first emperor, and, in a rebellious streak, decided to speak against the emperor\u2019s orthodoxy. The emperor, in turn, sent soldiers to capture him. However, by the time the soldiers had donned their armor and left their barracks, our hero had fled. For years, he fled south by southeast along the Yangtze and away from Xi\u2019an, the capital at the time. On occasion, he settled in a village for a few months, but the soldiers would eventually arrive and he would be forced to flee, leaving his life behind. Six times he settled, believing himself to have escaped; six times he was uprooted, each time fleeing further than the last. On his last escape, he pressed his way farther than he had ever gone before, trekking through high mountain passes and dense shaded woods before making his way to a low, rolling valley. Exhausted after days of travel, he collapsed in the shade of a nearby tree. It was days later that he awoke to a sweet scent, and when he opened his eyes, he saw plump, ripe peaches hanging from the low boughs above him. He knew then, that he had found freedom at last.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Years ago, my mother told me a story of how our family came to be in Anhui, along the banks of the Yangtze. As the story goes, one of our ancestors had been a philosopher during the reign of the first emperor, and, in a rebellious streak, decided to speak against the emperor\u2019s orthodoxy. The emperor, in turn, sent soldiers to capture him. However, by the time the soldiers had donned their armor and left their barracks, our hero had fled. For years, he fled south by southeast along the Yangtze and away from Xi\u2019an, the capital at the time. On occasion, he settled in a village for a few months, but the soldiers would eventually arrive and he would be forced to flee, leaving his life behind. Six times he settled, believing himself to have escaped; six times he was uprooted, each time fleeing further than the last. On his last escape, he pressed his way farther than he had ever gone before, trekking through high mountain passes and dense shaded woods before making his way to a low, rolling valley. Exhausted after days of travel, he collapsed in the shade of a nearby tree. It was days later that he awoke to a sweet scent, and when he opened his eyes, he saw plump, ripe peaches hanging from the low boughs above him. He knew then, that he had found freedom at last.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2767\u2767 \u2766 \u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2767\u2767",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2766",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Whereas my mother grew up in southern China, in the temperate province of Anhui, my father grew up in northern China, in the colder province of Liaoning. My father\u2019s family were farmers who worked the land during the warm, wet season and hunkered down during the harsh, cold season. My grandparents were adamant that their children should live a life better than the one they led, and so, while my father and uncle would help out around the farm where they could, they were barred from the hardest labor and provided time to study and play. My father\u2019s family was poor and could not afford many toys, and so my father spent his early years roaming the woods, finding wonder in the world around him. He played in shallow pools, observing as tadpoles hatched from their eggs and slowly grew into frogs. He climbed trees, watching as sparrows built their nests and fed their young before flying south in droves. And at night, he studied, poring over textbooks under candlelight, working hard well into the night. As I grew up, my father would occasionally tell these stories, of a wild and untamed world, of a boyhood spent in adventure. Hard as his childhood was, my father always told these tales with a warm gleam in his eye. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Whereas my mother grew up in southern China, in the temperate province of Anhui, my father grew up in northern China, in the colder province of Liaoning. My father\u2019s family were farmers who worked the land during the warm, wet season and hunkered down during the harsh, cold season. My grandparents were adamant that their children should live a life better than the one they led, and so, while my father and uncle would help out around the farm where they could, they were barred from the hardest labor and provided time to study and play. My father\u2019s family was poor and could not afford many toys, and so my father spent his early years roaming the woods, finding wonder in the world around him. He played in shallow pools, observing as tadpoles hatched from their eggs and slowly grew into frogs. He climbed trees, watching as sparrows built their nests and fed their young before flying south in droves. And at night, he studied, poring over textbooks under candlelight, working hard well into the night. As I grew up, my father would occasionally tell these stories, of a wild and untamed world, of a boyhood spent in adventure. Hard as his childhood was, my father always told these tales with a warm gleam in his eye. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2767\u2767 \u2766 \u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2767\u2767",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2766",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "As a child, my mother skipped two years of school, and though the teachers were fond of her, she found herself ostracized from the other schoolchildren. It is no surprise then, that when she found herself preparing for university at the age of 16, she was restless and eager to depart. In those days, traveling from Anhui to Shanghai required a week-long trip by ferry, and a grueling one at that. The ferry itself was a wretched thing, old and rusty and reeking of filth, with infrequent service such that ships only arrived in port every few days. After packing, my mother insisted on taking the earliest ferry, but my grandparents refused to let her go in favor of spending a few more days at home. My mother had been furious as she watched the ferry depart from her hometown port, but days later, news arrived that a storm had raged downriver and the ferry had capsized, killing all those who had been on board. Despite this, my mother boarded the very next ferry with bags in hand, jaw tight and knuckles white, and when she found the barge to be near empty, it only hardened her resolve to brave whatever the world could throw at her. She arrived safely in Shanghai one week later.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "As a child, my mother skipped two years of school, and though the teachers were fond of her, she found herself ostracized from the other schoolchildren. It is no surprise then, that when she found herself preparing for university at the age of 16, she was restless and eager to depart. In those days, traveling from Anhui to Shanghai required a week-long trip by ferry, and a grueling one at that. The ferry itself was a wretched thing, old and rusty and reeking of filth, with infrequent service such that ships only arrived in port every few days. After packing, my mother insisted on taking the earliest ferry, but my grandparents refused to let her go in favor of spending a few more days at home. My mother had been furious as she watched the ferry depart from her hometown port, but days later, news arrived that a storm had raged downriver and the ferry had capsized, killing all those who had been on board. Despite this, my mother boarded the very next ferry with bags in hand, jaw tight and knuckles white, and when she found the barge to be near empty, it only hardened her resolve to brave whatever the world could throw at her. She arrived safely in Shanghai one week later.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2767\u2767 \u2766 \u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2767\u2767",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2766",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "As the industrial engines of a 1980\u2019s communist China slowly stirred to life, party leadership found themselves in demand for mechanical lubricant. Of particular interest was a slick, greasy sap produced by crushing Castor seeds, which didn\u2019t require complex refineries to produce. In a stroke of brilliance, it was decided that growing and harvesting these seed pods could be an excellent competition for the children of the state. Never mind that the seeds are quite toxic and used to produce the deadly toxin ricin, for, guided by love of the motherland, schoolteachers across the country doled out seeds for children to grow in their secret gardens. My parents had been among the cohorts of bright-eyes children entrusted with this noble cause, but whereas my mother was wise to the folly of the task at hand, my father leaned into the challenge. My father had searched the family farm for open land but found none, and so, while the other children dug their careful plots, my father tossed his seeds carelessly into a marshy patch far from the farm. For months, the other children carefully tended their seedlings while my father neglected his. It was only when time came to turn in their harvests and tally up the yield that it occurred to my father to check on his crop. They had bloomed \u2014 not only had they bloomed, they had thrived. My father won the competition by a landslide, beating out his peers several times over. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "As the industrial engines of a 1980\u2019s communist China slowly stirred to life, party leadership found themselves in demand for mechanical lubricant. Of particular interest was a slick, greasy sap produced by crushing Castor seeds, which didn\u2019t require complex refineries to produce. In a stroke of brilliance, it was decided that growing and harvesting these seed pods could be an excellent competition for the children of the state. Never mind that the seeds are quite toxic and used to produce the deadly toxin ricin, for, guided by love of the motherland, schoolteachers across the country doled out seeds for children to grow in their secret gardens. My parents had been among the cohorts of bright-eyes children entrusted with this noble cause, but whereas my mother was wise to the folly of the task at hand, my father leaned into the challenge. My father had searched the family farm for open land but found none, and so, while the other children dug their careful plots, my father tossed his seeds carelessly into a marshy patch far from the farm. For months, the other children carefully tended their seedlings while my father neglected his. It was only when time came to turn in their harvests and tally up the yield that it occurred to my father to check on his crop. They had bloomed \u2014 not only had they bloomed, they had thrived. My father won the competition by a landslide, beating out his peers several times over. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2767\u2767 \u2766 \u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2767\u2767",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2766",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "My parents met at Fudan University, near the heart of Shanghai. When my father had nearly completed his master\u2019s, he found himself wondering about his future job prospects in China. Of particular concern to him was the culture surrounding alcohol. My father was not a drinker, and with most deals sealed over shots of Maotai, he realized that he would struggle in the business world. There did, however, remain another option, one so absurd that they hadn\u2019t seriously considered it up until that point. At the time, the engineering department had been searching for students to pursue further studies in the United States, to apply for a study visa and accept a modest scholarship. My father mulled over the opportunity. Whereas his drinking skills were substantially worse than those of his peers, his English skills were comparably better, and though he hadn\u2019t been serious about the idea at first, the prospect of immigrating to a land ripe with opportunity slowly grew on him. Not long after, he applied and was readily accepted. Within the month, my parents began the process of packing and borrowing enough money to make ends meet in a new country. Within the year, they boarded the long flight to the United States and embarked on a journey to the other side of the world. They landed with a suitcase full of mismatched clothes, two hundred dollars in an old wallet, and two hearts full of dreams. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "My parents met at Fudan University, near the heart of Shanghai. When my father had nearly completed his master\u2019s, he found himself wondering about his future job prospects in China. Of particular concern to him was the culture surrounding alcohol. My father was not a drinker, and with most deals sealed over shots of Maotai, he realized that he would struggle in the business world. There did, however, remain another option, one so absurd that they hadn\u2019t seriously considered it up until that point. At the time, the engineering department had been searching for students to pursue further studies in the United States, to apply for a study visa and accept a modest scholarship. My father mulled over the opportunity. Whereas his drinking skills were substantially worse than those of his peers, his English skills were comparably better, and though he hadn\u2019t been serious about the idea at first, the prospect of immigrating to a land ripe with opportunity slowly grew on him. Not long after, he applied and was readily accepted. Within the month, my parents began the process of packing and borrowing enough money to make ends meet in a new country. Within the year, they boarded the long flight to the United States and embarked on a journey to the other side of the world. They landed with a suitcase full of mismatched clothes, two hundred dollars in an old wallet, and two hearts full of dreams. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2767\u2767 \u2766 \u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2767\u2767",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2766",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": null,
          "font_size": null,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}