export default {
  "metadata": {
    "num_words": 2212
  },
  "content": [
    {
      "text": "Two Little Lives",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Two Little Lives",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "A dual review of A Gentleman in Moscow by Amor Towles and A Little Life by Hanya Yanagihara.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "A dual review of ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " by Amor Towles and ",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " by Hanya Yanagihara.",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I purchased A Gentleman in Moscow and A Little Life a little over a year ago from the McNally Jackson bookstore in New York City. Though I made attempts to read both novels over this past year, it wasn't until this Winter break that I finally set aside the time each novel deserved and read them in full stride. A Gentleman in Moscow, a novel of approximately 450 pages, took me four days to read. A Little Life, which stretches to just over 600, took me another six. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I purchased ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " and ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " a little over a year ago from the McNally Jackson bookstore in New York City. Though I made attempts to read both novels over this past year, it wasn't until this Winter break that I finally set aside the time each novel deserved and read them in full stride. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", a novel of approximately 450 pages, took me four days to read. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", which stretches to just over 600, took me another six. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Both novels left my mind swimming with thoughts, but the great elation I felt upon completing A Gentleman in Moscow was dampened by the disappointment I felt after A Little Life. This review is my attempt at tying together my stray thoughts and putting to rest my time spent with both novels. These are not books that would naturally be reviewed together, but I find enough parallels between them that a dual review feels appropriate to me. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Both novels left my mind swimming with thoughts, but the great elation I felt upon completing ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " was dampened by the disappointment I felt after ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". This review is my attempt at tying together my stray thoughts and putting to rest my time spent with both novels. These are not books that would naturally be reviewed together, but I find enough parallels between them that a dual review feels appropriate to me. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "For those not looking to indulge in a near-two-thousand-word long analysis, I'll keep a brief review here at the top: I loved A Gentleman in Moscow and would recommend it to all audiences. I had mixed thoughts on A Little Life and hesitate to recommend it. That being said, they're both well-written, and there's enough love and intention behind them that you'll surely find something to appreciate. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "For those not looking to indulge in a near-two-thousand-word long analysis, I'll keep a brief review here at the top: I loved ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " and would recommend it to all audiences. I had mixed thoughts on ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " and hesitate to recommend it. That being said, they're both well-written, and there's enough love and intention behind them that you'll surely find something to appreciate. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Spoilers ahead. Proceed accordingly.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Spoilers ahead. Proceed accordingly.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2767\u2767\u2766\u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2767\u2767\u2766\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Towles' A Gentleman in Moscow is whiskey for the soul. The novel takes as its protagonist the illustrious Count Alexander Ilyich Rostov, a charming, mustachioed aristocrat who is increasingly out of place in post-imperial Russia. After being sentenced via Bolshevik tribunal to house arrest in the Metropol, a luxury hotel lush with amenities not far from the ever-watching Kremlin, we observe as the Count accepts, adapts to, and masters his circumstances, along the way forming a close circle of friends \u2014 hotel staff, weary travelers, and other permanent residents \u2014 that give great heft to the narrative. The entrance of Sofia into the tale marks the second act of the novel, and as she grows to be a capable and confident young woman, we share in the wonder she brings to the hotel. Towles tells a story of reflection, self-mastery, and appreciation that spans thirty years of tumultuous history, and by the end of the novel, we accept what the Count had long ago realized for himself: that he was the luckiest man in all of Russia. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Towles' ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " is whiskey for the soul. The novel takes as its protagonist the illustrious Count Alexander Ilyich Rostov, a charming, mustachioed aristocrat who is increasingly out of place in post-imperial Russia. After being sentenced via Bolshevik tribunal to house arrest in the Metropol, a luxury hotel lush with amenities not far from the ever-watching Kremlin, we observe as the Count accepts, adapts to, and masters his circumstances, along the way forming a close circle of friends \u2014 hotel staff, weary travelers, and other permanent residents \u2014 that give great heft to the narrative. The entrance of Sofia into the tale marks the second act of the novel, and as she grows to be a capable and confident young woman, we share in the wonder she brings to the hotel. Towles tells a story of reflection, self-mastery, and appreciation that spans thirty years of tumultuous history, and by the end of the novel, we accept what the Count had long ago realized for himself: that ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "he was the luckiest man in all of Russia",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Yanagihara's A Little Life is a much more complex tale. What beings as a story exploring the nuanced, brotherly relationship between four friends \u2014 Jude, Willem, Malcolm, and JB \u2014 soon hones in on the life of Jude, a brilliant yet tortured litigator, hoping to create for himself some semblance of a normal life after a childhood of trauma and suffering. We follow Jude as he slowly, over the course of decades, begins to open up, sharing pieces of his life with his many friends that love him. We revel in his successes and share in his sorrows, until eventually he does achieve that little life he so wished for \u2014 only for it to be unceremoniously torn apart. Jude's life ends in suicide. The novel is an almost voyeuristic experience, ambitious in the size and scope of the trauma, disparity, and range of human experience it aims to capture. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Yanagihara's ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " is a much more complex tale. What beings as a story exploring the nuanced, brotherly relationship between four friends \u2014 Jude, Willem, Malcolm, and JB \u2014 soon hones in on the life of Jude, a brilliant yet tortured litigator, hoping to create for himself some semblance of a normal life after a childhood of trauma and suffering. We follow Jude as he slowly, over the course of decades, begins to open up, sharing pieces of his life with his many friends that love him. We revel in his successes and share in his sorrows, until eventually he does achieve that little life he so wished for \u2014 only for it to be unceremoniously torn apart. Jude's life ends in suicide. The novel is an almost voyeuristic experience, ambitious in the size and scope of the trauma, disparity, and range of human experience it aims to capture. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Both novels are expansive works that span decades, tracing the careers and lives of a wide cast of characters. Both narratives focus deeply on individual friendships and how characters push and pull each other through the years. Both narratives revolve around a central theme of mastering \u2014 or being mastered by \u2014 one's circumstances. However, the success with which each author executed upon these ideas is not equal, or even close to equal. I found Towles' to be much more engaging than Yanagihara. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Both novels are expansive works that span decades, tracing the careers and lives of a wide cast of characters. Both narratives focus deeply on individual friendships and how characters push and pull each other through the years. Both narratives revolve around a central theme of mastering \u2014 or being mastered by \u2014 one's circumstances. However, the success with which each author executed upon these ideas is not equal, or even close to equal. I found Towles' to be much more engaging than Yanagihara. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I ended A Gentleman in Moscow feeling triumphant. I ended A Little Life feeling sorrow. Both novels are brilliant and touch the heart, but whereas my love for A Gentleman in Moscow has only grown, I have since begun to sour on A Little Life, my sorrow transforming into a biting indignation. These opinions will certainly shape this review.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I ended ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " feeling triumphant. I ended ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " feeling sorrow. Both novels are brilliant and touch the heart, but whereas my love for ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " has only grown, I have since begun to sour on ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", my sorrow transforming into a biting indignation. These opinions will certainly shape this review.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2767\u2767\u2766\u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2767\u2767\u2766\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "From an aesthetic standpoint, I found Towles' style to be compelling and enjoyable, whereas Yanagihara's writing fell short of my expectations for a novel that received as much acclaim as it did.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "From an aesthetic standpoint, I found Towles' style to be compelling and enjoyable, whereas Yanagihara's writing fell short of my expectations for a novel that received as much acclaim as it did.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Towles is terse when appropriate but does not sacrifice sophistication. His experiments in form \u2014 the interjections of poetry and philosophy, his dilation and contraction of time, Mikhail's final mosaic of references to 'bread' \u2014 all accent the narrative, enriching our understanding of the work. His conversations are brilliantly written; the Count is an excellent conversationalist and Towles is well-equipped to handle the many exchanges he has with a rotating cast of characters. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Towles is terse when appropriate but does not sacrifice sophistication. His experiments in form \u2014 the interjections of poetry and philosophy, his dilation and contraction of time, Mikhail's final mosaic of references to 'bread' \u2014 all accent the narrative, enriching our understanding of the work. His conversations are brilliantly written; the Count is an excellent conversationalist and Towles is well-equipped to handle the many exchanges he has with a rotating cast of characters. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "On the other hand, I grew weary of Yanagihara's platitudes a third of the way through the novel. In each chapter, Yanagihara will inevitably pose a series of questions for which her characters have no answers, but it is clear that neither does she. And, loath as I am to give critique grounded in feeling, I couldn't help but feel Yanagihara's writing begin to drone, with bland characters and chapters blurring into one another until it all begins to feel the same. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "On the other hand, I grew weary of Yanagihara's platitudes a third of the way through the novel. In each chapter, Yanagihara will inevitably pose a series of questions for which her characters have no answers, but it is clear that neither does she. And, loath as I am to give critique grounded in feeling, I couldn't help but ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "feel",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " Yanagihara's writing begin to drone, with bland characters and chapters blurring into one another until it all begins to feel the same. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Towles has a gift for characterization. The denizens of Towles' world all have so much life, a life that is sadly lacking in many of Yanagihara's characters. Take, for instance, Towles' characterization of Anna Urbanova and Sofia Kulikova. Towles describes Anna as willowy, Sofia as demure \u2014 simple descriptions, yes, and yet these two words, willowy and demure, tie together so many other characterizations that, by the end of the novel, our understanding of Anna and Sofia feels almost as rich as our understanding of the Count. This stands in stark contrast to many of Yanagihara's characters; take Malcolm and JB, two of the four boys we should be so fond of. Aside from their careers \u2014 which they happen to be incredibly, unrealistically successful in \u2014 what do we learn about them throughout the novel? Not much.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Towles has a gift for characterization. The denizens of Towles' world all have so much life, a life that is sadly lacking in many of Yanagihara's characters. Take, for instance, Towles' characterization of Anna Urbanova and Sofia Kulikova. Towles describes Anna as ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "willowy",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", Sofia as ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "demure",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " \u2014 simple descriptions, yes, and yet these two words, ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "willowy",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " and ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "demure",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", tie together so many other characterizations that, by the end of the novel, our understanding of Anna and Sofia feels almost as rich as our understanding of the Count. This stands in stark contrast to many of Yanagihara's characters; take Malcolm and JB, two of the four boys we should be so fond of. Aside from their careers \u2014 which they happen to be incredibly, unrealistically successful in \u2014 what do we learn about them throughout the novel? ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Not much",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ".",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2767\u2767\u2766\u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2767\u2767\u2766\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Diving now into the structure of the two novels, more cracks begin to form in Yanagihara's prose. Both novels include large time jumps, but the whereas the transitions in Towles' feel natural, I found it increasingly difficult to entertain Yanagihara's flexing of time. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Diving now into the structure of the two novels, more cracks begin to form in Yanagihara's prose. Both novels include large time jumps, but the whereas the transitions in Towles' feel natural, I found it increasingly difficult to entertain Yanagihara's flexing of time. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The Metropol of A Gentleman in Moscow is a microcosm of Russia in turmoil, where wars, famines, and revolutions are felt in hushed whispers, secret gatherings, and the ingredients and wines available for service in the Boyarsky. On the other hand, the New York City of A Little Life is perpetually set in 2014. Whereas Towles excels in aligning the Count's trajectory at the end of each chapter such that his new circumstances in the succeeding chapter are the natural conclusion of his development, Yanagihara employs the passing of the time as a simple excuse for characters to become more successful, without any substantive development. Time is a powerful tool, allowing for self-reflection, growth, and change. In A Little Life, the passing of time serves none of these goals. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The Metropol of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " is a microcosm of Russia in turmoil, where wars, famines, and revolutions are felt in hushed whispers, secret gatherings, and the ingredients and wines available for service in the Boyarsky. On the other hand, the New York City of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " is perpetually set in 2014. Whereas Towles excels in aligning the Count's trajectory at the end of each chapter such that his new circumstances in the succeeding chapter are the natural conclusion of his development, Yanagihara employs the passing of the time as a simple excuse for characters to become more successful, without any substantive development. Time is a powerful tool, allowing for self-reflection, growth, and change. In ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", the passing of time serves none of these goals. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Another structural issue unique to A Little Life are the viewpoints Yanagihara decides to writes from. We are promised a story about four friends, but aside from two viewpoint chapters dedicated to Malcolm and JB early in the novel (which broach topics of race and sexuality, only to be quickly abandoned), the remainder of the novel oscillates between Willem and Jude, with occasional letters from Harold. A Little Life is promised as a tale of four friends, but in reality, it delivers justice to only two friends, and what friendship does exist between them is revealed to be dysfunctional at best and exploitative at worst. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Another structural issue unique to ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " are the viewpoints Yanagihara decides to writes from. We are promised a story about four friends, but aside from two viewpoint chapters dedicated to Malcolm and JB early in the novel (which broach topics of race and sexuality, only to be quickly abandoned), the remainder of the novel oscillates between Willem and Jude, with occasional letters from Harold. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " is promised as a tale of four friends, but in reality, it delivers justice to only two friends, and what friendship does exist between them is revealed to be dysfunctional at best and exploitative at worst. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Towles scopes his novel to a single perspective and it strengthens his work. With each chapter, we understand the Count on a much deeper level, and we can watch how each event in the Metropol shapes him, changes his personal trajectory, and influences the next chapter of his life. When Towles does decide to weave in stories from beyond the Count's knowledge (e.g., Anna's frustration with the Count, Nina's presumed fate, Sofia's final performance), it only serves to ground the story more firmly, filling in gaps in the narrative we might otherwise have filled for ourselves. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Towles scopes his novel to a single perspective and it strengthens his work. With each chapter, we understand the Count on a much deeper level, and we can watch how each event in the Metropol shapes him, changes his personal trajectory, and influences the next chapter of his life. When Towles does decide to weave in stories from beyond the Count's knowledge (e.g., Anna's frustration with the Count, Nina's presumed fate, Sofia's final performance), it only serves to ground the story more firmly, filling in gaps in the narrative we might otherwise have filled for ourselves. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "My only wish for A Gentleman in Moscow was that it was longer, that I had more time to appreciate the Count's relationship with an adult Sofia, that I could watch the Count settle into old age and reflect fondly upon his years. My wishes for A Little Life, on the other hand, are far more fundamental. I wish we were able to learn about the boys' college years, about why they felt such a strong kinship and why they stayed in touch for so many years. I wish we saw deeper into JB and Malcolm's lives so that the friendship between the four characters could feel real. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "My only wish for ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " was that it was longer, that I had more time to appreciate the Count's relationship with an adult Sofia, that I could watch the Count settle into old age and reflect fondly upon his years. My wishes for ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", on the other hand, are far more fundamental. I wish we were able to learn about the boys' college years, about why they felt such a strong kinship and why they stayed in touch for so many years. I wish we saw deeper into JB and Malcolm's lives so that the friendship between the four characters could feel ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "real",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2767\u2767\u2766\u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2767\u2767\u2766\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "On the central theme of both works: to master, or to be mastered. Towles' A Gentleman in Moscow is a tale of mastery. The Count, having lost his wealth, status, and freedom, instead learns to create for himself a second life in the walls of the Metropol. Eventually, after finding new meaning in his life, he finally earns the freedom he relinquished decades before. Yanagihara's A Little Life, on the other hand, is a tale of being mastered. Jude starts with nothing \u2014 less than nothing \u2014 but he slowly begins to accumulate for himself a life of fame, luxury, and indulgence. This, however, proves fruitless. He is never able to confide in those who love him, and the few joys are eventually taken away from his life. His death comes almost as a relief.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "On the central theme of both works: ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "to master, or to be mastered",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". Towles' ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " is a tale of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "mastery",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". The Count, having lost his wealth, status, and freedom, instead learns to create for himself a second life in the walls of the Metropol. Eventually, after finding new meaning in his life, he finally earns the freedom he relinquished decades before. Yanagihara's ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", on the other hand, is a tale of ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "being mastered",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". Jude starts with nothing \u2014 less than nothing \u2014 but he slowly begins to accumulate for himself a life of fame, luxury, and indulgence. This, however, proves fruitless. He is never able to confide in those who love him, and the few joys are eventually taken away from his life. His death comes almost as a relief.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I will not hold it against Yanagihara that Jude is destined to kill himself, that he is never able to tell those he loves the secrets we are sworn as readers to keep for him. To the contrary, I \u2014 along with most first-wave reviews of the novel \u2014 commend Yanagihara for her portrayal of Jude's trauma and how deeply it seeps into his every action and reaction. What I do hold against Yanagihara is that it seems she decided Jude's death would be that emotional gut punch around which the rest of the novel is formed. Jude never changes. He never finds it within himself to properly seek help. Yanagihara decided that therapy and psychology is pointless, and her decision shapes Jude's attitudes throughout the novel. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I will not hold it against Yanagihara that Jude is destined to kill himself, that he is never able to tell those he loves the secrets we are sworn as readers to keep for him. To the contrary, I \u2014 along with most first-wave reviews of the novel \u2014 commend Yanagihara for her portrayal of Jude's trauma and how deeply it seeps into his every action and reaction. What I do hold against Yanagihara is that it seems she decided Jude's death would be that emotional gut punch around which the rest of the novel is formed. Jude never changes. He never finds it within himself to properly seek help. Yanagihara decided that therapy and psychology is pointless, and her decision shapes Jude's attitudes throughout the novel. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There is only so long that an author might reasonably expect us to suspend our disbelief. I am willing to believe that the Count finds a way of life within the Metropol and that the hotel is insulated from the political upheaval outside its walls. I am willing to believe that Jude suffered through immense abuse and trauma in his childhood. But I am not willing to believe that every trucker between Montana and Boston is a pedophile and rapist. I am not willing to believe that Jude remains forever broken, that his friends and adopted family are so horribly inept at caring for him. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There is only so long that an author might reasonably expect us to suspend our disbelief. I am willing to believe that the Count finds a way of life within the Metropol and that the hotel is insulated from the political upheaval outside its walls. I am willing to believe that Jude suffered through immense abuse and trauma in his childhood. But I am not willing to believe that every trucker between Montana and Boston is a pedophile and rapist. I am not willing to believe that Jude remains forever broken, that his friends and adopted family are so horribly inept at caring for him. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There's a lot more I can say about A Little Life, but if you're curious in reading a more thorough critique, I'll link Andrea Long Chu's Pulitzer Prize winning critique, Hanya's Boys, here.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There's a lot more I can say about ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", but if you're curious in reading a more thorough critique, I'll link Andrea Long Chu's Pulitzer Prize winning critique, ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "Hanya's Boys",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "here",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": "https://www.vulture.com/article/hanya-yanagihara-review.html"
        },
        {
          "text": ".",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "\u2767\u2767\u2766\u2619\u2619",
      "alignment": "CENTER",
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "\u2767\u2767\u2766\u2619\u2619",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Segoe UI Symbol",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I'll leave with a few closing thoughts on both novels:",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I'll leave with a few closing thoughts on both novels:",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "First, some closing remarks on A Little Life. For all its faults, A Little Life is a heart wrenching novel that illuminates patterns and behaviors of abuse in a way that is hauntingly poignant. The novel is beautifully written, with prose that, while droning at times, is deeply thoughtful and meditative. I was invested in the work on my first read and I yearned desperately for Jude to find happiness. When he does achieve some semblance of a life, I celebrated his joy. When he suffers harrowing setback after setback, I mourned his dejection. It is a rare novel that has you so firmly in its grips, that tears deep into your soul and leaves you feeling raw. Yanagihara is undeniably a talented writer.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "First, some closing remarks on ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". For all its faults, ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Little Life",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " is a heart wrenching novel that illuminates patterns and behaviors of abuse in a way that is hauntingly poignant. The novel is beautifully written, with prose that, while droning at times, is deeply thoughtful and meditative. I was invested in the work on my first read and I yearned desperately for Jude to find happiness. When he does achieve some semblance of a life, I celebrated his joy. When he suffers harrowing setback after setback, I mourned his dejection. It is a rare novel that has you so firmly in its grips, that tears deep into your soul and leaves you feeling raw. Yanagihara is undeniably a talented writer.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "And lastly, a few thoughts on A Gentleman in Moscow. Towles weaves characters together to form intricate plotlines that crescendo gracefully into cathartic payoffs (e.g., the bouillabaisse plotline, which was one of my favorites). I was spellbound by the final arc of the novel, in awe as I watched the Count transform his gentle countenance into a Bogart-esque hero to hold the Bishop at gunpoint and escape amidst the chaos. And lastly, I loved Sofia's character, the way she takes after the Count, the way she complements him, inspires him. Her adoption into the found family of the Metropol was perhaps the greatest beat in the novel, cementing A Gentleman in Moscow in my heart as one of my favorite novels. The Count's tale is a triumph of the human spirit, and I loved it all the way to the end.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "And lastly, a few thoughts on ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ". Towles weaves characters together to form intricate plotlines that crescendo gracefully into cathartic payoffs (e.g., the bouillabaisse plotline, which was one of my favorites). I was spellbound by the final arc of the novel, in awe as I watched the Count transform his gentle countenance into a Bogart-esque hero to hold the Bishop at gunpoint and escape amidst the chaos. And lastly, I loved Sofia's character, the way she takes after the Count, the way she complements him, inspires him. Her adoption into the found family of the Metropol was perhaps the greatest beat in the novel, cementing ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "A Gentleman in Moscow",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " in my heart as one of my favorite novels. The Count's tale is a triumph of the human spirit, and I loved it all the way to the end.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}