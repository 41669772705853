import metadata from '@content/blog/metadata';

const getFormattedDate = (date) => {
  const dateObj = new Date(date + 'T00:00:00');
  const formattedDate = dateObj.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  });
  return formattedDate;
};

const getFullDate = (date) => {
  const dateObj = new Date(date + 'T00:00:00');
  const formattedDate = dateObj.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  });
  return formattedDate;
};

const compareDate = (a, b) => {
  const dateComparison = new Date(b[1].date) - new Date(a[1].date);
  if (dateComparison === 0) {
    return (
      Object.keys(metadata).indexOf(b[0]) - Object.keys(metadata).indexOf(a[0])
    );
  }
  return dateComparison;
};

export { getFormattedDate, getFullDate, compareDate };
