import Footer from '@components/Footer/Footer';
import Navbar from '@components/Navbar/Navbar';
import metadata from '@content/blog/metadata';
import { MobileContext } from '@contexts/MobileContext';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import commonStyles from '@styles/common.module.css';
import { getFullDate } from '@utils/dateUtils';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import styles from './PostPage.module.css';
import AnimatedParagraph from './components/AnimatedParagraph';
import PostBookend from './components/PostBookend';

const PostPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const postMeta = metadata[slug];
  const [postContent, setPostContent] = useState(null);
  const [previousPost, setPreviousPost] = useState(null);
  const [nextPost, setNextPost] = useState(null);
  const [error, setError] = useState(null);
  const [key, setKey] = useState(0);
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    setError(null);
    setPostContent(null);

    if (postMeta) {
      import(`@content/blog/posts/${postMeta.file}`)
        .then((module) => setPostContent(module.default.content))
        .catch(() => setError('Failed to load the post content.'));
    } else {
      setError('Post not found.');
    }
    setKey((prevKey) => prevKey + 1);
  }, [postMeta, slug]);

  useEffect(() => {
    const filteredMetadata = Object.keys(metadata).filter(
      (key) => metadata[key].type !== 'image'
    );
    const currentIndex = filteredMetadata.indexOf(slug);
    const previousPost =
      currentIndex > 0
        ? {
            ...metadata[filteredMetadata[currentIndex - 1]],
            slug: filteredMetadata[currentIndex - 1],
          }
        : null;
    const nextPost =
      currentIndex < Object.keys(filteredMetadata).length - 1
        ? {
            ...metadata[filteredMetadata[currentIndex + 1]],
            slug: filteredMetadata[currentIndex + 1],
          }
        : null;

    setPreviousPost(previousPost);
    setNextPost(nextPost);
  }, [slug]);

  const renderPostNavigation = () => {
    const handlePostNavigation = (postSlug) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setPostContent(null);
      navigate(`/blog/${postSlug}`);
    };

    return (
      <>
        <Box className={styles.navigationLeft}>
          {nextPost && (
            <Box
              className={styles.navigationObject}
              onClick={() => handlePostNavigation(nextPost.slug)}
            >
              {!isMobile && (
                <Typography variant="h6">{nextPost.title}</Typography>
              )}
              <ArrowBack />
            </Box>
          )}
        </Box>
        <Box className={styles.navigationRight}>
          {previousPost && (
            <Box
              className={styles.navigationObject}
              onClick={() => handlePostNavigation(previousPost.slug)}
            >
              {!isMobile && (
                <Typography variant="h6">{previousPost.title}</Typography>
              )}
              <ArrowForward />
            </Box>
          )}
        </Box>
      </>
    );
  };

  const renderParagraph = (paragraph, index) => {
    switch (paragraph.type) {
      case 'title':
        return (
          <>
            <Box
              sx={{ display: 'flex', alignItems: 'baseline', gap: 2, mb: 1 }}
            >
              <Typography variant="h2" sx={{ color: '#ce0000' }}>
                {getFullDate(postMeta.date)}
              </Typography>
              <Typography key={index} variant="h2" className={styles.title}>
                {paragraph.text}
              </Typography>
            </Box>
          </>
        );
      case 'paragraph':
        return (
          <AnimatedParagraph
            key={`${key}-${index}`}
            paragraph={paragraph}
            variant={isMobile ? 'body2' : 'body1'}
          />
        );
      case 'image':
        return (
          <Box className={styles.imageContainer}>
            <img
              src={require(`@content/blog/images/${paragraph.path}`)}
              alt={paragraph.text}
              className={styles.image}
            />
          </Box>
        );
      default:
        return (
          <Typography key={index} variant={isMobile ? 'body2' : 'body1'}>
            {paragraph.text}
          </Typography>
        );
    }
  };

  if (error) {
    return <Typography>{error}</Typography>;
  }

  if (!postContent) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <Helmet>
        <title>{postMeta.title} | EdenInc</title>
      </Helmet>
      <Box className={commonStyles.pageLayout}>
        <Navbar />
        {renderPostNavigation()}
        <Box
          className={
            isMobile ? styles.mobilePageContainer : styles.pageContainer
          }
        >
          {postContent.map((paragraph, index) =>
            renderParagraph(paragraph, index)
          )}
          <PostBookend />
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default PostPage;
