export default {
  "metadata": {
    "num_words": 629
  },
  "content": [
    {
      "text": "Love, from Seattle",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Love, from Seattle",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "The weather in Seattle has been lovely recently, with highs under 75 and gentle cloudbanks that dissipate before the mid-afternoon. My roommate, Hannah, remarked the other day that some of the leaves have started to brown, and she\u2019s right, or so I think. It\u2019s hard to tell, just by looking, but one thing is certain: Fall approaches fast. In a month, on-campus life will resume once again, along with the chaos of classes and activities. In a week, I\u2019ll be back in New York, preparing for the move from coast to coast. The lull of quarantine has begun to fade away, the pace of everyday life picks back up. There\u2019s a palpable excitement within my circles, mixed with threads of anxiety. How exactly will we transition back into an in-person Stanford? How normal will our campus experience truly be? Certainty is a privilege; one Stanford is all too reluctant to grant.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The weather in Seattle has been lovely recently, with highs under 75 and gentle cloudbanks that dissipate before the mid-afternoon. My roommate, Hannah, remarked the other day that some of the leaves have started to brown, and she\u2019s right, or so I think. It\u2019s hard to tell, just by looking, but one thing is certain: Fall approaches fast. In a month, on-campus life will resume once again, along with the chaos of classes and activities. In a week, I\u2019ll be back in New York, preparing for the move from coast to coast. The lull of quarantine has begun to fade away, the pace of everyday life picks back up. There\u2019s a palpable excitement within my circles, mixed with threads of anxiety. How exactly will we transition back into an in-person Stanford? How normal will our campus experience truly be? Certainty is a privilege; one Stanford is all too reluctant to grant.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Seattle has been a wonderful experience. Hannah and I had promised ourselves just that when we\u2019d planned for our stay back in April. Our apartment is beautiful, situated right by the Olympic Sculpture Park and the waterfront, not too far from the downtown offices and nightlife. Our work leaves us ample time to enjoy the city around us. We've enjoyed many of the restaurants all around us, slowly curating our personal favorites. We've went on several hikes, including a trip to Rainier during the alpine bloom. We've taken a trip down to Portland for a day to see the city, a trip that gave us a far greater appreciation for Seattle proper. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Seattle has been a wonderful experience. Hannah and I had promised ourselves just that when we\u2019d planned for our stay back in April. Our apartment is beautiful, situated right by the Olympic Sculpture Park and the waterfront, not too far from the downtown offices and nightlife. Our work leaves us ample time to enjoy the city around us. We've enjoyed many of the restaurants all around us, slowly curating our personal favorites. We've went on several hikes, including a trip to Rainier during the alpine bloom. We've taken a trip down to Portland for a day to see the city, a trip that gave us a far greater appreciation for Seattle proper. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "But it\u2019s the people around me that made Seattle so wonderful. Bennett showed me some of my favorite restaurants in the area during our downtown work sessions. Binh and I grabbed brunch several weekends at Portage Bay during our off days. Chloe drove me around to see much of the city beyond the downtown. And in between those adventures were visits from other friends. Andrew visited back in mid-June. Kyle, in early-August. Michelle and Cathy came up from the Bay in mid-August. Despite being two thousand miles from home and eight-hundred miles from Stanford, Seattle still felt remarkably like home.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "But it\u2019s the people around me that made Seattle so wonderful. Bennett showed me some of my favorite restaurants in the area during our downtown work sessions. Binh and I grabbed brunch several weekends at Portage Bay during our off days. Chloe drove me around to see much of the city beyond the downtown. And in between those adventures were visits from other friends. Andrew visited back in mid-June. Kyle, in early-August. Michelle and Cathy came up from the Bay in mid-August. Despite being two thousand miles from home and eight-hundred miles from Stanford, Seattle still felt remarkably like home.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "My thoughts on the city are positive overall. Seattle is a beautiful city. The skyscrapers are polished to a mirror\u2019s edge, the sidewalks are lined with trees, the streets bustle with life. But the brilliant sheen of downtown masks darker undercurrents. Just blocks away are large lots strewn with tents, occupied by those the city left behind. It\u2019s hard to reconcile just what is and what isn\u2019t natural about Seattle. Whereas SLU and Belltown feel incredibly dependent on the corporate ecosystems around it, Cap Hill, Belltown, and International District all feel like unique, independent sections. For better or worse, it\u2019s hard to imagine a Seattle without Amazon. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "My thoughts on the city are positive overall. Seattle is a beautiful city. The skyscrapers are polished to a mirror\u2019s edge, the sidewalks are lined with trees, the streets bustle with life. But the brilliant sheen of downtown masks darker undercurrents. Just blocks away are large lots strewn with tents, occupied by those the city left behind. It\u2019s hard to reconcile just what is and what isn\u2019t natural about Seattle. Whereas SLU and Belltown feel incredibly dependent on the corporate ecosystems around it, Cap Hill, Belltown, and International District all feel like unique, independent sections. For better or worse, it\u2019s hard to imagine a Seattle without Amazon. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Three months was the perfect duration for our stay. Long enough for us to settle in and find our favorite spots in the city, but brief enough that we lived each day with intention. Three months allowed us the space to be both a tourist and a local, to indulge in the many Seattle attractions while also finding our own spots. There was room for routine and spontaneity, and by the end, we felt we could call Seattle home.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Three months was the perfect duration for our stay. Long enough for us to settle in and find our favorite spots in the city, but brief enough that we lived each day with intention. Three months allowed us the space to be both a tourist and a local, to indulge in the many Seattle attractions while also finding our own spots. There was room for routine and spontaneity, and by the end, we felt we could call Seattle home.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "My last week in Seattle will be spent tying the last few threads of this three-month adventure. Hannah and I have begun one last grand tour of our favorite restaurants. We\u2019ll be cleaning up the apartment and packing for the trip home. I\u2019ll be saying goodbye to Seattle friends. And, of course, I\u2019ll stop by the waterfront to watch the sunset one last time. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "My last week in Seattle will be spent tying the last few threads of this three-month adventure. Hannah and I have begun one last grand tour of our favorite restaurants. We\u2019ll be cleaning up the apartment and packing for the trip home. I\u2019ll be saying goodbye to Seattle friends. And, of course, I\u2019ll stop by the waterfront to watch the sunset one last time. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "How wonderful it\u2019s been to call Seattle home, if only for three months.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "How wonderful it\u2019s been to call Seattle home, if only for three months.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}