import { createSlice } from '@reduxjs/toolkit';

const getInitialTheme = () => {
  const storedTheme = localStorage.getItem('isDarkMode');
  if (storedTheme === null) {
    return false;
  }
  return storedTheme === 'true';
};

const initialState = {
  isDarkMode: getInitialTheme(),
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme(state) {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem('isDarkMode', state.isDarkMode.toString());
    },

    setTheme(state, action) {
      state.isDarkMode = action.payload;
      localStorage.setItem('isDarkMode', state.isDarkMode.toString());
    },
  },
});

export const { toggleTheme, setTheme } = themeSlice.actions;
export default themeSlice.reducer;
