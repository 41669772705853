import { MobileContext } from '@contexts/MobileContext';
import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Footer.module.css';

const Footer = () => {
  const isMobile = useContext(MobileContext);
  const navigate = useNavigate();

  const footerLinks = [
    {
      text: 'Changelog',
      href: '/changelog',
      internal: true,
    },
    {
      text: 'GitHub Source',
      href: 'https://github.com/eyw520/edenincorporated',
      internal: false,
    },
    {
      text: 'Report an Issue',
      href: 'https://forms.gle/k1wtu22m8qBqkVse7',
      internal: false,
    },
  ];

  const handleClick = (href, internal, e) => {
    e.preventDefault();
    if (internal) {
      navigate(href);
    } else {
      window.open(href, '_blank');
    }
  };

  return (
    <Box
      className={
        isMobile ? styles.footerContainerMobile : styles.footerContainer
      }
    >
      <Box className={styles.footerLeft}>
        {footerLinks.map(({ text, href, internal }) => (
          <Typography
            key={text}
            variant="body3"
            className={styles.footerLink}
            component="a"
            href={href}
            onClick={(e) => handleClick(href, internal, e)}
            sx={{ marginBottom: '0.25rem', textDecoration: 'none' }}
          >
            {text}
          </Typography>
        ))}
      </Box>
      <Box className={styles.footerRight}>
        <Typography
          variant="body3"
          className={styles.footerRightText}
          component="a"
          href="https://www.lovefrom.com/"
          onClick={(e) => {
            e.preventDefault();
            window.open('https://www.lovefrom.com/', '_blank');
          }}
          sx={{ textDecoration: 'none' }}
        >
          built with love, eyw
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
