import { useEffect, useRef, useState } from 'react';

const useIsResizing = () => {
  const [isResizing, setIsResizing] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const resizeTimerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      if (Math.abs(newWidth - currentWidth) > 2) {
        setIsResizing(true);
        setCurrentWidth(newWidth);

        if (resizeTimerRef.current) {
          clearTimeout(resizeTimerRef.current);
        }

        resizeTimerRef.current = setTimeout(() => {
          setIsResizing(false);
        }, 150);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      if (resizeTimerRef.current) {
        clearTimeout(resizeTimerRef.current);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isResizing;
};

export default useIsResizing;
