export default {
  "metadata": {
    "num_words": 757
  },
  "content": [
    {
      "text": "Meditations",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Meditations",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "I sit on a bench at the foot of the Olympic Sculpture Park, looking west towards Puget Sound. It\u2019s a quiet Friday afternoon. It\u2019s hot, for Seattle, but 86 is nothing compared to the heat wave coming this weekend. A warm breeze blows in from the bay, carrying with it a reminder of the sea \u2014 the faint smell of sea salt, not quite as bold as the Atlantic Ocean spray, but still ever present. Not a single wisp of white floats in the clear blue sky. On a clear day like today, the mountains can be seen rising from the horizon. To my left, Mount Rainier looms large, it\u2019s snowcap glowing a soft pink through the summer haze. To my right, the Olympic mountains sprawl out, their gentle blue ridges almost bleeding into the sky. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I sit on a bench at the foot of the Olympic Sculpture Park, looking west towards Puget Sound. It\u2019s a quiet Friday afternoon. It\u2019s hot, for Seattle, but 86 is nothing compared to the heat wave coming this weekend. A warm breeze blows in from the bay, carrying with it a reminder of the sea \u2014 the faint smell of sea salt, not quite as bold as the Atlantic Ocean spray, but still ever present. Not a single wisp of white floats in the clear blue sky. On a clear day like today, the mountains can be seen rising from the horizon. To my left, Mount Rainier looms large, it\u2019s snowcap glowing a soft pink through the summer haze. To my right, the Olympic mountains sprawl out, their gentle blue ridges almost bleeding into the sky. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It\u2019s been nearly a month since I left New York. On the 31st of May, I flew out to San Francisco to spend five days in the Bay. Two of those days were spent down on campus, spending time with friends. Some, friends made during my Frosh year, who I hadn\u2019t seen since the pandemic, others, friends made during virtual Stanford, who I met in-person for the first time. Three were spent in San Francisco. I visited SF MOMA after two years away. I was given a local\u2019s tour of the city, courtesy of friends from the area. I saw the sun set over the Pacific for the first time. The latter is etched into my mind. How beautiful it was, to watch the sun slowly descend, the horizon grow gold, then orange, then red, until the bright marble that is the sun disappeared into a scarlet haze. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It\u2019s been nearly a month since I left New York. On the 31st of May, I flew out to San Francisco to spend five days in the Bay. Two of those days were spent down on campus, spending time with friends. Some, friends made during my Frosh year, who I hadn\u2019t seen since the pandemic, others, friends made during virtual Stanford, who I met in-person for the first time. Three were spent in San Francisco. I visited SF MOMA after two years away. I was given a local\u2019s tour of the city, courtesy of friends from the area. I saw the sun set over the Pacific for the first time. The latter is etched into my mind. How beautiful it was, to watch the sun slowly descend, the horizon grow gold, then orange, then red, until the bright marble that is the sun disappeared into a scarlet haze. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Living in Seattle, this beauty has become almost routine. My apartment overlooks Elliot Bay, with a clear view of the Sound. On clear days (which recently there has been an abundance of), I can watch as the Olympic mountains are gilded by the setting sun, cast against clouds blush in jovial pinks. Admittedly, it took me a while to even realize that there were mountains in the distance. My first few days in the city, the Seattle fog hung low over the water, hiding even the nearby hills just opposite the Bay. It was only a few days after I settled in that the fog lifted, revealing the jagged, snowy peaks in the distance. It would be two weeks until I saw Rainier for the first time. Standing at the top of Kerry Park, Rainier juts out above the skyline. It\u2019s still odd for me to see the mountains in the distance. Coming from the East coast, my mountains are spires of grey concrete and twisted steel. Seeing these untamed, nature-formed peaks is humbling. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Living in Seattle, this beauty has become almost routine. My apartment overlooks Elliot Bay, with a clear view of the Sound. On clear days (which recently there has been an abundance of), I can watch as the Olympic mountains are gilded by the setting sun, cast against clouds blush in jovial pinks. Admittedly, it took me a while to even realize that there were mountains in the distance. My first few days in the city, the Seattle fog hung low over the water, hiding even the nearby hills just opposite the Bay. It was only a few days after I settled in that the fog lifted, revealing the jagged, snowy peaks in the distance. It would be two weeks until I saw Rainier for the first time. Standing at the top of Kerry Park, Rainier juts out above the skyline. It\u2019s still odd for me to see the mountains in the distance. Coming from the East coast, my mountains are spires of grey concrete and twisted steel. Seeing these untamed, nature-formed peaks is humbling. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Spring finally gives way to summer and I take my first break after seven consecutive quarters of classes. I look back on virtual Stanford and can\u2019t help but feel somewhat content. As difficult as it, good things have come from quarantine. Being back home allowed me to reconnect with old friends, rekindling friendships that I\u2019d nearly lost in the chaos of frosh year. Being online challenged me to engage more with the communities that matter to me, whether that be dance, a community I\u2019ve now been a part of for two years, or theater, a second family I grew close to just months ago. And despite how stagnant things felt during the online experience, there has been significant change that I\u2019m excited for. I\u2019ll be leading Common Origins through our return to campus, and we\u2019ve begun tentative plans to host the fall show, Breaking Ground. I\u2019ll be guiding the Asian American Issues ASB, which will entail a lot of growth from my end. And I\u2019ll be continuing theater, excitedly watching how theater will re-emerge on campus. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Spring finally gives way to summer and I take my first break after seven consecutive quarters of classes. I look back on virtual Stanford and can\u2019t help but feel somewhat content. As difficult as it, good things have come from quarantine. Being back home allowed me to reconnect with old friends, rekindling friendships that I\u2019d nearly lost in the chaos of frosh year. Being online challenged me to engage more with the communities that matter to me, whether that be dance, a community I\u2019ve now been a part of for two years, or theater, a second family I grew close to just months ago. And despite how stagnant things felt during the online experience, there has been significant change that I\u2019m excited for. I\u2019ll be leading Common Origins through our return to campus, and we\u2019ve begun tentative plans to host the fall show, Breaking Ground. I\u2019ll be guiding the Asian American Issues ASB, which will entail a lot of growth from my end. And I\u2019ll be continuing theater, excitedly watching how theater will re-emerge on campus. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "How wonderful it is, to be in a new city, on the cusp of my Junior year. Already, the summer has been fulfilling, a prelude to the splendid chaos that will be this Fall. My internship is challenging but intriguing; my adventures through the city have been fruitful. I feel a sense of calm as I write, with two years to both past and future. I look forward to the experiences ahead.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "How wonderful it is, to be in a new city, on the cusp of my Junior year. Already, the summer has been fulfilling, a prelude to the splendid chaos that will be this Fall. My internship is challenging but intriguing; my adventures through the city have been fruitful. I feel a sense of calm as I write, with two years to both past and future. I look forward to the experiences ahead.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "A wave crashes on the rocks and a bit of spray lands by my feet. I look up from my writing. As the waters recede, the rocks dry near instantly, leaving behind a thin white crust of salt. The sun hangs lower in the sky. It\u2019s time to head home.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "A wave crashes on the rocks and a bit of spray lands by my feet. I look up from my writing. As the waters recede, the rocks dry near instantly, leaving behind a thin white crust of salt. The sun hangs lower in the sky. It\u2019s time to head home.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}