import Footer from '@components/Footer/Footer';
import Navbar from '@components/Navbar/Navbar';
import sortBy from '@consts/sortBy';
import tags from '@consts/tags';
import metadata from '@content/blog/metadata';
import { MobileContext } from '@contexts/MobileContext';
import useIsResizing from '@hooks/useIsResizing';
import { Box } from '@mui/material';
import commonStyles from '@styles/common.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import styles from './BlogPage.module.css';
import BlogList from './components/BlogList';
import FeaturedPosts from './components/FeaturedPosts';

const STARTING_DISPLAY_COUNT = 9;

const BlogPage = () => {
  const isResizing = useIsResizing();
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState([]);
  const [activeTags, setActiveTags] = useState([]);
  const [sort, setSort] = useState(sortBy.DATE_DESC);
  const [displayCount, setDisplayCount] = useState(STARTING_DISPLAY_COUNT);
  const [previousDisplayCount, setPreviousDisplayCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    const allTags = Object.values(metadata)
      .filter((post) => post.tags)
      .flatMap((post) => post.tags);
    const missingTags = allTags.filter((tag) => !tags.includes(tag));
    if (missingTags.length > 0) {
      throw new Error(`Missing tags: ${missingTags.join(', ')}.`);
    }
  }, []);

  useEffect(() => {
    const tagsParam = searchParams.get('tags');
    const sortParam = searchParams.get('sort');

    if (tagsParam) {
      const tags = tagsParam.split(',');
      setActiveTags(tags);
    }

    if (
      sortParam &&
      (sortParam === sortBy.DATE_ASC || sortParam === sortBy.DATE_DESC)
    ) {
      setSort(sortParam);
    }
  }, []);

  const getActivePosts = (sort = sortBy.DATE_DESC) => {
    let sortedPosts = Object.entries(metadata).sort((a, b) => {
      const dateA = new Date(a[1].date);
      const dateB = new Date(b[1].date);
      return sort === sortBy.DATE_DESC ? dateB - dateA : dateA - dateB;
    });

    if (activeTags.length > 0) {
      sortedPosts = sortedPosts.filter(
        ([_, post]) =>
          post.tags?.length > 0 &&
          activeTags.every((tag) => post.tags.includes(tag))
      );
    }
    return sortedPosts;
  };

  const updateActiveTags = (tag) => {
    setActiveTags((prev) => {
      const newTags = prev.includes(tag)
        ? prev.filter((t) => t !== tag)
        : [...prev, tag];

      const params = {};
      if (newTags.length > 0) {
        params.tags = newTags.join(',');
      }
      if (sort !== sortBy.DATE_DESC) {
        params.sort = sort;
      }
      setSearchParams(params);

      return newTags;
    });
  };

  const showPostsSequentially = async (startIndex, endIndex) => {
    for (let i = startIndex; i < endIndex; i++) {
      await new Promise((resolve) => setTimeout(resolve, 200));
      setVisiblePosts((prev) => [...prev, i]);
    }
  };

  useEffect(() => {
    const sortedPosts = getActivePosts(sort);
    setFilteredPosts(sortedPosts);

    const endIndex = Math.min(displayCount, sortedPosts.length);
    showPostsSequentially(previousDisplayCount, endIndex);
  }, [displayCount]);

  useEffect(() => {
    const sortedPosts = getActivePosts(sort);
    setFilteredPosts(sortedPosts);

    const endIndex = Math.min(displayCount, sortedPosts.length);
    setTimeout(() => {
      showPostsSequentially(0, endIndex);
    }, 50);
  }, [activeTags]);

  useEffect(() => {
    const sortedPosts = getActivePosts(sort);
    setFilteredPosts(sortedPosts);

    const endIndex = Math.min(STARTING_DISPLAY_COUNT, sortedPosts.length);
    setTimeout(() => {
      showPostsSequentially(0, endIndex);
    }, 50);

    const params = {};
    if (activeTags.length > 0) {
      params.tags = activeTags.join(',');
    }
    if (sort !== sortBy.DATE_DESC) {
      params.sort = sort;
    }
    setSearchParams(params);
  }, [sort]);

  const handleTagClick = (tag) => {
    setVisiblePosts([]);
    setFilteredPosts([]);
    updateActiveTags(tag);
    setDisplayCount(STARTING_DISPLAY_COUNT);
    setPreviousDisplayCount(0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleTagsSelect = (tags) => {
    setVisiblePosts([]);
    setFilteredPosts([]);
    setDisplayCount(STARTING_DISPLAY_COUNT);
    setPreviousDisplayCount(0);
    for (const tag of tags) {
      updateActiveTags(tag);
    }
  };

  const handleSortClick = (sort) => {
    setVisiblePosts([]);
    setFilteredPosts([]);
    setDisplayCount(STARTING_DISPLAY_COUNT);
    setPreviousDisplayCount(0);
    setSort(sort);
  };

  const handleLoadMore = () => {
    setPreviousDisplayCount(displayCount);
    setDisplayCount((prev) => prev + 9);
  };

  return (
    <>
      <Helmet>
        <title>Blog | EdenInc</title>
      </Helmet>
      <Box className={commonStyles.pageLayout}>
        <Navbar />
        <Box
          className={
            isMobile ? styles.mobilePageContainer : styles.pageContainer
          }
        >
          <FeaturedPosts
            isResizing={isResizing}
            handleTagClick={handleTagClick}
            activeTags={activeTags}
          />
          <BlogList
            isResizing={isResizing}
            visiblePosts={visiblePosts}
            displayCount={displayCount}
            handleLoadMore={handleLoadMore}
            sort={sort}
            handleSortClick={handleSortClick}
            activeTags={activeTags}
            handleTagsSelect={handleTagsSelect}
            handleTagClick={handleTagClick}
            filteredPosts={filteredPosts}
          />
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default BlogPage;
