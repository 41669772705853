export default {
  "metadata": {
    "num_words": 394
  },
  "content": [
    {
      "text": "Ego Death",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Ego Death",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "The world has flooded and there is no Ark. You are a castaway, adrift in endless sea, desperately holding onto anything you may find. You are afloat, but barely, and all around you are your belongings, the remnants of your past life.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The world has flooded and there is no Ark. You are a castaway, adrift in endless sea, desperately holding onto anything you may find. You are afloat, but barely, and all around you are your belongings, the remnants of your past life.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There, before you, floats a suitcase with your hopes and dreams, all the things you are so used to chasing back on solid ground. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There, before you, floats a suitcase with your hopes and dreams, all the things you are so used to chasing back on solid ground. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There, beside you, floats a duffel with your relationships, and packed within, the hundred masks you've grown so comfortable wearing. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There, beside you, floats a duffel with your relationships, and packed within, the hundred masks you've grown so comfortable wearing. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There, behind you, floats a bag with your grief and sorrow, those heavy chains that have wound themselves taut around your heart.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There, behind you, floats a bag with your grief and sorrow, those heavy chains that have wound themselves taut around your heart.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "But nothing matters here, at the end of the world, where sunless sky meets somber sea. You fight against the waves, you struggle to keep your bags about you, but all to no avail. The sea will have its due, and no matter how hard you try, you begin shedding bits and pieces of yourself. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "But nothing matters here, at the end of the world, where sunless sky meets somber sea. You fight against the waves, you struggle to keep your bags about you, but all to no avail. The sea will have its due, and no matter how hard you try, you begin shedding bits and pieces of yourself. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There, just out of reach, has drifted your pride and regard, the fire within you that you believed would burn until the end of time. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There, just out of reach, has drifted your pride and regard, the fire within you that you believed would burn until the end of time. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "There, nearly sunk beneath the waves, is your love of adventure, a love you thought was inseparable from your sense of self.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "There, nearly sunk beneath the waves, is your love of adventure, a love you thought was inseparable from your sense of self.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "And there, swept away by a grey-crested wave, is your fear and worry, and the walls you built over a lifetime to keep safe within. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "And there, swept away by a grey-crested wave, is your fear and worry, and the walls you built over a lifetime to keep safe within. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "You are becoming a shell of yourself. Who are you without your friendships, without your passions, without your hopes and dreams? Who are you without your scabs and scars, that brand your heart your own? ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "You are becoming a shell of yourself. Who are you without your friendships, without your passions, without your hopes and dreams? Who are you without your scabs and scars, that brand your heart your own? ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "You watch as it all drifts further and further away. Everything that made you you, now just specks upon the horizon. You still remember who you were. But now you are empty, and you cannot feel who you are. You are without a skin, just flesh and blood made form.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "You watch as it all drifts further and further away. Everything that made you ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "you",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": ", now just specks upon the horizon. You still remember who you were. But now you are empty, and you cannot ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": "feel",
          "bold": false,
          "italic": true,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        },
        {
          "text": " who you are. You are without a skin, just flesh and blood made form.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It is all gone now, and you are alone, treading against the tide. Your kicks slow, then stop. You are surprised, for your body does not sink. Your legs rise, and as you lean back, you fall into the darkness, kept afloat by your own weight. You gaze into the sky, and for the first time, you notice there is light yet.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It is all gone now, and you are alone, treading against the tide. Your kicks slow, then stop. You are surprised, for your body does not sink. Your legs rise, and as you lean back, you fall into the darkness, kept afloat by your own weight. You gaze into the sky, and for the first time, you notice there is light yet.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "For even in a sunless sky, there remains the stars. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "For even in a sunless sky, there remains the stars. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}