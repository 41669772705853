import Footer from '@components/Footer/Footer';
import Navbar from '@components/Navbar/Navbar';
import sunsetsMetadata from '@content/gallery/sunsets/metadata';
import { MobileContext } from '@contexts/MobileContext';
import useIsResizing from '@hooks/useIsResizing';
import { Box, Typography } from '@mui/material';
import commonStyles from '@styles/common.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import styles from './GalleryPage.module.css';
import GalleryCarousel from './components/GalleryCarousel';

const GalleryPage = () => {
  const isMobile = useContext(MobileContext);
  const isResizing = useIsResizing();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const renderHeaderContent = () => {
    return (
      <Box className={styles.headerContainer}>
        <Typography variant="h3" sx={{ textAlign: 'center', mb: 1 }}>
          ~ Gallery ~
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          ~ Stargazing, Daydreaming ~
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Gallery | EdenInc</title>
      </Helmet>
      <Box className={commonStyles.pageLayout}>
        <Navbar />
        <Box
          className={
            isMobile ? styles.mobilePageContainer : styles.pageContainer
          }
          sx={{
            opacity: !isVisible || isResizing ? 0 : 1,
            transition: 'opacity 0.3s ease-in-out',
          }}
        >
          {renderHeaderContent()}
          <GalleryCarousel images={sunsetsMetadata.source} />
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default GalleryPage;
