import sortBy from '@consts/sortBy';
import tags from '@consts/tags';
import metadata from '@content/blog/metadata';
import { MobileContext } from '@contexts/MobileContext';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';

import styles from '../BlogPage.module.css';
import BlogPost from './BlogPost';

const BlogList = ({
  isResizing,
  visiblePosts,
  displayCount,
  handleLoadMore,
  sort,
  handleSortClick,
  activeTags,
  handleTagsSelect,
  handleTagClick,
  filteredPosts,
}) => {
  const isMobile = useContext(MobileContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempTags, setTempTags] = useState([]);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTempTags(activeTags);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setTempTags([]);
  };

  const handleTagToggle = (tag) => {
    if (tempTags.includes(tag)) {
      setTempTags(tempTags.filter((t) => t !== tag));
    } else {
      setTempTags([...tempTags, tag]);
    }
  };

  const handleApplyTags = () => {
    handleTagsSelect(tempTags);
    setAnchorEl(null);
  };

  const getPostCountForTags = (tagsToCheck) => {
    return Object.values(metadata).filter(
      (post) => post.tags && tagsToCheck.every((tag) => post.tags.includes(tag))
    ).length;
  };

  const renderSort = () => {
    return (
      <Button
        onClick={() =>
          handleSortClick(
            sort === sortBy.DATE_DESC ? sortBy.DATE_ASC : sortBy.DATE_DESC
          )
        }
        variant="text"
        sx={{ color: 'inherit' }}
        className={styles.blogListHeaderButton}
      >
        {sort === sortBy.DATE_DESC ? (
          <>
            <Typography variant="body2" sx={{ mr: 0.5 }}>
              Newest
            </Typography>
            <ArrowDownwardIcon fontSize="small" />
          </>
        ) : (
          <>
            <Typography variant="body2" sx={{ mr: 0.5 }}>
              Oldest.
            </Typography>
            <ArrowUpwardIcon fontSize="small" />
          </>
        )}
      </Button>
    );
  };

  const renderFilter = () => {
    return (
      <>
        <Button
          variant="text"
          className={styles.blogListHeaderButton}
          sx={{ color: 'inherit' }}
          endIcon={<FilterAltIcon />}
          onClick={handleFilterClick}
        >
          <Typography variant="body2">Filter</Typography>
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleFilterClose}
        >
          {tags.map((tag) => {
            const postCount =
              tempTags.length > 0
                ? getPostCountForTags([...tempTags, tag])
                : getPostCountForTags([tag]);

            return (
              <MenuItem
                key={tag}
                onClick={() => handleTagToggle(tag)}
                disabled={postCount === 0}
                dense
              >
                <Checkbox checked={tempTags.includes(tag)} size="small" />
                <Typography variant="body2" sx={{ mr: 1 }}>
                  {tag}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ ml: 'auto', color: 'text.secondary' }}
                >
                  ({postCount})
                </Typography>
              </MenuItem>
            );
          })}
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
            <Button variant="contained" onClick={handleApplyTags} size="small">
              Apply Filters
            </Button>
          </Box>
        </Menu>
      </>
    );
  };

  const renderBlogListHeader = () => {
    return (
      <>
        <Box className={styles.blogListHeader}>
          {renderSort()}
          <Box className={styles.blogListHeaderMain}>
            <Typography variant="h3" sx={{ mb: 1 }}>
              ~ Explore ~
            </Typography>
          </Box>
          {renderFilter()}
        </Box>
        {activeTags.length > 0 ? (
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                flexWrap: 'wrap',
              }}
            >
              <Typography variant="h6">Filtering to Tags:</Typography>
              {activeTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => handleTagClick(tag)}
                  color="primary"
                  size="small"
                />
              ))}
            </Box>
          </Box>
        ) : (
          <Typography variant="h6" sx={{ mb: 2 }}>
            ~ Wander Through and Read Awhile ~
          </Typography>
        )}
      </>
    );
  };

  const renderBlogListPosts = () => {
    return (
      <Box
        className={styles.blogListPostsContainer}
        sx={{
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          },
        }}
      >
        {filteredPosts.slice(0, displayCount).map(([slug, post], index) => (
          <BlogPost
            key={index}
            shouldRender={visiblePosts.includes(index)}
            renderFullDate={activeTags.length > 0}
            slug={slug}
            post={post}
            index={index}
            handleTagClick={handleTagClick}
          />
        ))}
      </Box>
    );
  };

  const renderBlogListFooter = () => {
    if (displayCount < filteredPosts.length) {
      return (
        <Typography
          onClick={handleLoadMore}
          variant="h3"
          sx={{
            mt: 4,
            mb: 2,
            cursor: 'pointer',
            '&:hover': {
              color: 'grey',
            },
          }}
        >
          Load More
        </Typography>
      );
    } else {
      return (
        <Box className={styles.blogListFin}>
          <Typography sx={{ fontStyle: 'italic' }}>~ Fin. ~</Typography>
        </Box>
      );
    }
  };

  return (
    <Box
      className={styles.blogListContainer}
      sx={{
        opacity: isResizing ? 0 : 1,
      }}
    >
      {renderBlogListHeader()}
      {renderBlogListPosts()}
      {renderBlogListFooter()}
    </Box>
  );
};

export default BlogList;
