export default {
  "metadata": {
    "num_words": 199
  },
  "content": [
    {
      "text": "Under the Brooklyn Bridge",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Under the Brooklyn Bridge",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "It had not been your first time to the City, but it was our first time there together. You were bundled up in a long winter coat, your cheeks frosted red from the cold.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It had not been your first time to the City, but it was our first time there together. You were bundled up in a long winter coat, your cheeks frosted red from the cold.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We had spent the day in Brooklyn, under the shadow of the Bridge. I had been there before, but with you by my side, I experienced the city anew.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We had spent the day in Brooklyn, under the shadow of the Bridge. I had been there before, but with you by my side, I experienced the city anew.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "You had wanted to see the sunset, so in the hour before dusk, I drove us up to the Brooklyn Bridge. I parked on a crowded side street just as the clouds began to blush.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "You had wanted to see the sunset, so in the hour before dusk, I drove us up to the Brooklyn Bridge. I parked on a crowded side street just as the clouds began to blush.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Hand in hand, we ran, our scarves trailing behind us in the wind. It was a race against the sun, but we laughed with every step as we dashed through the crowd.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Hand in hand, we ran, our scarves trailing behind us in the wind. It was a race against the sun, but we laughed with every step as we dashed through the crowd.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "We arrived, just in time. The clouds were golden before us, the sky bled a dark magenta, and the sun hung crimson just above the horizon, its hues washing into the sea.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "We arrived, just in time. The clouds were golden before us, the sky bled a dark magenta, and the sun hung crimson just above the horizon, its hues washing into the sea.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It had been cold, but in that moment, it made no matter. We stood there, marveling at the view, until the night washed over us. It was breathtaking. It was special, with you.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It had been cold, but in that moment, it made no matter. We stood there, marveling at the view, until the night washed over us. It was breathtaking. It was special, with you.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}