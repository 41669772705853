import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import styles from '../GalleryPage.module.css';

const ModalCarousel = ({
  title,
  description,
  images,
  currentIndex,
  setCurrentIndex,
  isClosing,
  onClose,
}) => {
  const [fade, setFade] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const goToOffset = (offset) => {
    if (offset === 0) return;
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => {
        let newIndex = prevIndex + offset;
        if (newIndex < 0) newIndex += images.length;
        if (newIndex >= images.length) newIndex -= images.length;
        return newIndex;
      });
    }, 250);
    setTimeout(() => {
      setFade(false);
    }, 500);
  };

  const getImageIndex = (offset) => {
    let index = currentIndex + offset;
    if (index < 0) index += images.length;
    if (index >= images.length) index -= images.length;
    return index;
  };

  return (
    <Box
      className={`${styles.carouselOverlay} ${isClosing ? ' ' + styles.carouselOverlayClosing : ''}`}
    >
      <IconButton
        onClick={onClose}
        className={styles.carouselOverlayCloseButton}
        sx={{ position: 'absolute', color: 'white' }}
      >
        <CloseIcon sx={{ fontSize: 32 }} />
      </IconButton>
      <IconButton
        onClick={() => goToOffset(-1)}
        className={styles.carouselOverlayNavLeft}
        sx={{ position: 'absolute', color: 'white' }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <IconButton
        onClick={() => goToOffset(1)}
        className={styles.carouselOverlayNavRight}
        sx={{ position: 'absolute', color: 'white' }}
      >
        <ArrowForwardIosIcon />
      </IconButton>

      {title || description ? (
        <Box className={styles.carouselOverlayTitleContainer}>
          {title && (
            <Typography
              variant="h3"
              sx={{ textAlign: 'center', color: 'white' }}
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              variant="body1"
              sx={{ textAlign: 'center', mt: 1, color: 'white' }}
            >
              {description}
            </Typography>
          )}
        </Box>
      ) : null}

      <Box className={styles.carouselOverlayContainer}>
        <Box className={styles.carouselOverlayContentContainer}>
          {[-1, 0, 1].map((offset) => {
            const index = getImageIndex(offset);
            return (
              <Box
                key={index}
                className={styles.carouselOverlayContent}
                sx={{
                  width: offset === 0 ? '100%' : '20%',
                  transform: `translateX(${offset}vw) translateZ(${offset === 0 ? 0 : -10}vw)`,
                  opacity: fade ? 0 : offset === 0 ? 1 : 0.5,
                  cursor: offset !== 0 ? 'pointer' : 'default',
                }}
              >
                <img
                  src={require(
                    `@content/gallery/sunsets/${images[index].image}`
                  )}
                  alt={images[index].description}
                  onClick={() => goToOffset(offset)}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box className={styles.carouselOverlayImageInfoContainer}>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            color: 'white',
            mb: 1,
            opacity: fade ? 0 : 1,
            transition: 'opacity 0.3s ease-in-out',
          }}
        >
          {images[currentIndex].description}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            textAlign: 'center',
            color: 'white',
            opacity: fade ? 0 : 1,
            transition: 'opacity 0.3s ease-in-out',
          }}
        >
          {images[currentIndex].date}
        </Typography>
      </Box>
    </Box>
  );
};

export default ModalCarousel;
