export default {
  "metadata": {
    "num_words": 155
  },
  "content": [
    {
      "text": "On the Outskirts of Versailles",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "On the Outskirts of Versailles",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "I remember a moment from a lifetime ago, of a KFC on the outskirts of Versailles, not far from the RER station. We ate chicken by a rain-streaked window as the sky poured onto the busy street below. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I remember a moment from a lifetime ago, of a KFC on the outskirts of Versailles, not far from the RER station. We ate chicken by a rain-streaked window as the sky poured onto the busy street below. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It was a quiet moment, and it was just the two of us, with the early afternoon sun peering through a dense sea of silver clouds. The rain came down as beads of sunlit glass.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It was a quiet moment, and it was just the two of us, with the early afternoon sun peering through a dense sea of silver clouds. The rain came down as beads of sunlit glass.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It had drizzled earlier. Your hair was still windblown, and after taking photos of a furtive rainbow in the Gardens, you had an adventurous smile upon your face. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It had drizzled earlier. Your hair was still windblown, and after taking photos of a furtive rainbow in the Gardens, you had an adventurous smile upon your face. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It was that Spring that I learned how beautiful life could be, how sharing yourself with someone you loved could fill the world with a new wonder.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It was that Spring that I learned how beautiful life could be, how sharing yourself with someone you loved could fill the world with a new wonder.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "It was a lovely moment, but it was timeless because of you. I wouldn\u2019t have wanted to spend it with anybody else.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "It was a lovely moment, but it was timeless because of you. I wouldn\u2019t have wanted to spend it with anybody else.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}