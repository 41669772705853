export default {
  "metadata": {
    "num_words": 472
  },
  "content": [
    {
      "text": "Of Spring and Summergrass",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Of Spring and Summergrass",
          "bold": true,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "title"
    },
    {
      "text": "Take a look at the flowers. There are so many, here, in the meadow, and there, just beyond the hill. Before they were weeds, they were the wildflowers and summergrass that bathed in the sun and drank from its light, that called these lands their own. A patch of poppies is in bloom, their petals pure, golden, and supple, yet unburnt from the sun. Within their folds lay crowns of false saffron. Above, the clouds melt into each other, strands of pale milk that swirl and sink into an ocean of blue.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Take a look at the flowers. There are so many, here, in the meadow, and there, just beyond the hill. Before they were weeds, they were the wildflowers and summergrass that bathed in the sun and drank from its light, that called these lands their own. A patch of poppies is in bloom, their petals pure, golden, and supple, yet unburnt from the sun. Within their folds lay crowns of false saffron. Above, the clouds melt into each other, strands of pale milk that swirl and sink into an ocean of blue.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "I had always thought the clouds to be timeless beasts that blew in from one horizon and drifted to another, great herds of mist, steam, and smoke, nomads of an endless expanse. It is only now I realize that cloud-birth is ever-present. You can see it too: cast your eye up, squint if you need, and look, truly look. On days like this, you can watch the clouds extend out of a primordial blue, into tendrils that form and grow, then tumble and fade back into the sea from which they came. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "I had always thought the clouds to be timeless beasts that blew in from one horizon and drifted to another, great herds of mist, steam, and smoke, nomads of an endless expanse. It is only now I realize that cloud-birth is ever-present. You can see it too: cast your eye up, squint if you need, and look, truly look. On days like this, you can watch the clouds extend out of a primordial blue, into tendrils that form and grow, then tumble and fade back into the sea from which they came. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "To the West, storm clouds roll in from blue-grey peaks. Here, with only mellow cloud-drifts above us, we are safe. Laying here and looking up at the sky, I could almost imagine myself falling into Neptune, its storms the clouds of our deep blue. Could you? I could almost imagine myself stargazing upon a spaceship, with hills and trees planted under a false sun of our own. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "To the West, storm clouds roll in from blue-grey peaks. Here, with only mellow cloud-drifts above us, we are safe. Laying here and looking up at the sky, I could almost imagine myself falling into Neptune, its storms the clouds of our deep blue. Could you? I could almost imagine myself stargazing upon a spaceship, with hills and trees planted under a false sun of our own. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Could such a thing ever be built? A starship, to ferry humanity to new galaxies, with man-made land and sea under a thousand fleeting stars and suns, where entire generations will live and die on a journey through space. We\u2019ve built many wonders over the past thousand years. But could we build such a thing, as a race yearning for the stars, or would we destroy ourselves in the process?",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Could such a thing ever be built? A starship, to ferry humanity to new galaxies, with man-made land and sea under a thousand fleeting stars and suns, where entire generations will live and die on a journey through space. We\u2019ve built many wonders over the past thousand years. But could we build such a thing, as a race yearning for the stars, or would we destroy ourselves in the process?",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Now, we sit in the tree, in the shade, hiding away from the fierce mid-afternoon sun. The grass, made tall by the Winter rains, sways in the Spring wind. I am perched in an upper branch. My arm looped around one of the upper boughs, my feet tucked into the nook of a branch. You sit below me, dappled sunlight striking flames in your hair.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Now, we sit in the tree, in the shade, hiding away from the fierce mid-afternoon sun. The grass, made tall by the Winter rains, sways in the Spring wind. I am perched in an upper branch. My arm looped around one of the upper boughs, my feet tucked into the nook of a branch. You sit below me, dappled sunlight striking flames in your hair.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "The branches crawl with ants, their bellies gorged with sap and sugary nectar. Tiny fungi grow out of the cracks, lichen sprout from wherever they might find a hold. Splotches of gold and pale green, specks of orange. It is an insect's world and we are just guests. There are a hundred such trees in this park, each with their own colonies and forests within. A bounty of worlds we may never be privy to.",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "The branches crawl with ants, their bellies gorged with sap and sugary nectar. Tiny fungi grow out of the cracks, lichen sprout from wherever they might find a hold. Splotches of gold and pale green, specks of orange. It is an insect's world and we are just guests. There are a hundred such trees in this park, each with their own colonies and forests within. A bounty of worlds we may never be privy to.",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    },
    {
      "text": "Let us sit for a while longer. ",
      "alignment": null,
      "style": "Normal",
      "indentation_left": null,
      "indentation_right": null,
      "spacing_before": null,
      "spacing_after": null,
      "content": [
        {
          "text": "Let us sit for a while longer. ",
          "bold": false,
          "italic": false,
          "underline": false,
          "font_name": "Times New Roman",
          "font_size": 11.0,
          "color": null,
          "hyperlink": null
        }
      ],
      "type": "paragraph"
    }
  ]
}