import ThemeToggle from '@components/ThemeToggle/ThemeToggle';
import { Box, Button, Typography } from '@mui/material';
import commonStyles from '@styles/common.module.css';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import styles from './LandingPage.module.css';

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>EdenIncorporated</title>
      </Helmet>
      <Box className={commonStyles.pageLayout}>
        <Box className={styles.pageContainer}>
          <Box className={styles.pageContent}>
            <Typography variant="h1" sx={{ marginBottom: '0.75rem' }}>
              EdenIncorporated
            </Typography>
            <Box className={styles.pageSubtitleContainer}>
              <Typography
                variant="h4"
                sx={{ marginBottom: '0.75rem', fontWeight: 400 }}
              >
                explore, with wonder. a blog, by eden west.
              </Typography>
            </Box>
            <Box className={styles.buttonContainer}>
              <Button variant="mono" onClick={() => navigate('/blog')}>
                Blog
              </Button>
              <Button variant="mono" onClick={() => navigate('/gallery')}>
                Gallery
              </Button>
              {/* TODO: Add Timshel */}
              {/* <Button variant="mono" onClick={() => navigate('/timshel')}>
                Timshel
              </Button> */}
              <Button variant="mono" onClick={() => navigate('/about')}>
                About
              </Button>
              <ThemeToggle />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LandingPage;
